import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  BASE_URL,
  LOAD_USER_URL,
  ORDERS_URL,
  DASBOARD_STATISTICS,
  CUSTOMER_ORDERS_URL,
  DISTRICT_URL,
} from "../../constants/api";
import "./account.css";
import { findArrayElementByTitle } from "../../constants/Validate";
import { OrderStatus } from "../../constants/HomeData";
import moment from "moment";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ReactPaginate from 'react-paginate'

class AccountComponent extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone_no: "",
    email: "",
    address: "",
    notes: "",
    post_code: "",
    district: "",
    is_login: false,
    input: {},
    errors: {},
    order: [],
    isLoaded: false,
    isLogout: false,
    total_order: 0,
    total_order_completed: 0,
    total_order_pending: 0,
    total_processing: 0,
    uship_first_name: "",
    uship_last_name: "",
    uship_phone_no: "",
    uship_email: "",
    uship_shipping_address: "",
    uship_post_code: "",
    uship_district: "",
    ship_district: "",
    ubship_first_name: "",
    ubship_last_name: "",
    ubship_phone_no: "",
    ubship_email: "",
    ubship_billing_address: "",
    ubship_post_code: "",
    ubship_district: "",
    bship_district: "",
    districts: [],
    isShipping: false,
    isBilling: false,
    user_id: 0,
    point: 0,
    paginatePageCount:0,
    count:0,
    next:''
  };

  componentDidMount() {
    this.loadUser();
    this.loadDashboardInfo();
    this.orderList(CUSTOMER_ORDERS_URL);
    this.fetchData();
    var user = localStorage.getItem("currentUser");
    if (user !== null) {
      var currentUser = JSON.parse(user);
      this.setState({
        user_id:
          currentUser.user !== null &&
          currentUser.user !== undefined &&
          currentUser.user !== ""
            ? currentUser.user.id
            : "",
      });
      // alert(JSON.stringify(currentUser.user.id))
    }
  }

 

  orderList = async (url) => {
    //alert(url)
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
   const result = await axios(
       url,{ method: "GET", headers }
   );
  //  setProducts([]);
  //  setProducts(result.data.results);
  //  setNext(result.data.next);
  //  setCount(result.data.count)
   this.setState({
    order:[],
    order: result.data.results,
    next:result.data.next,
    count:result.data.count,
    paginatePageCount:Math.max((result.data.count / 10))
  });
//alert(result.data.count)
  
   const allParamsData = [];
   const para = 'page'
   // console.log(para)
   const allParams = para.split('&')
   allParams.forEach(eachItem => {
   allParamsData[eachItem.split('=')] = eachItem.split('=')
   })
  //  const x = Math.max((result.data.count / 12))
  //  // (allParamsData['size'] ?? sortLimitOptions[0].value)) - 1, 0
  //  setPaginatePageCount(x)
};


  // orderList = () => {
  //   var token = localStorage.getItem("token", "");
  //   let headers = {
  //     "Content-Type": "application/json",
  //   };

  //   if (token) {
  //     headers["Authorization"] = `Token ${token}`;
  //   }
    

  //   fetch(CUSTOMER_ORDERS_URL, { method: "GET", headers })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // alert(JSON.stringify(res))

  //       this.setState({
  //         // order:[],
  //         order: res.results,
  //         next:res.next,
  //         count:res.count,
  //         paginatePageCount:Math.max((res.count / 10))
  //       });
        
  //     });
  //     // const allParamsData = [];
  //     // const para = 'page'
  //     // console.log(para)
  //     // const allParams = para.split('&')
  //     // allParams.forEach(eachItem => {
  //     // allParamsData[eachItem.split('=')] = eachItem.split('=')
  //     // })
      
  //     // const x = Math.max((res.count / 12))
  //     // // (allParamsData['size'] ?? sortLimitOptions[0].value)) - 1, 0
  //     // this.setState({paginatePageCount: x})
  // };

  onShippingChange = () => {
    this.setState({
      isShipping: true,
      isBilling: false,
    });
  };

  onShippingChangeClose = () => {
    this.setState({
      isShipping: false,
      isBilling: false,
    });
  };

  onBillingChange = () => {
    this.setState({
      isShipping: false,
      isBilling: true,
    });
  };

  onBillingChangeClose = () => {
    this.setState({
      isShipping: false,
      isBilling: false,
    });
  };

  validate() {
    let input = this.state;
    let errors = {};
    let isValid = true;

    if (!input["first_name"]) {
      isValid = false;
      errors["first_name"] = "Please enter your first name.";
    }

    if (!input["last_name"]) {
      isValid = false;
      errors["last_name"] = "Please enter your last name.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if (!input["new_password"]) {
      // isValid = false;
      // errors["new_password"] = "Please enter your new password.";
    }

    if (!input["confirm_password"]) {
      // isValid = false;
      // errors["confirm_password"] = "Please enter your confirm password.";
    }

    if (
      typeof input["new_password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["new_password"] !== input["confirm_password"]) {
        isValid = false;
        errors["new_password"] = "Passwords don't match.";
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  fetchData = async () => {
    const result = await axios(DISTRICT_URL);
    // alert(JSON.stringify(result))
    this.setState({ districts: result.data.results });
    
  };

  loadDashboardInfo = () => {
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    fetch(DASBOARD_STATISTICS, { method: "GET", headers })
      .then((res) => res.json())
      .then((res) => {
        // 'total_order_pending': total_pending,
        // 'total_order_completed': total_completed,
        // 'total_warehouse': total_warehouse,
        // 'total_order':total_order

        this.setState({
          total_order: res.total_order,
          total_processing: res.total_processing,
          total_order_completed: res.total_order_completed,
          total_order_pending: res.total_order_pending,
        });
      });
  };

  loadUser = () => {
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;

      fetch(LOAD_USER_URL, { method: "GET", headers })
        .then((res) => res.json())
        .then((res) => {
          if (res.id !== undefined) {
            this.setState({
              is_login: true,
              user_id: res.id,
              point: res.point,
              first_name: res.first_name,
              last_name: res.last_name,
              new_password: res.new_password,
              address: res.address,
              phone_no: res.phone_no,
              email: res.email,
              uship_first_name:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.first_name
                  : "",
              uship_last_name:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.last_name
                  : "",
              uship_phone_no:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.phone_no
                  : "",
              uship_email:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.email
                  : "",
              uship_shipping_address:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.shipping_address
                  : "",
              uship_post_code:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.post_code
                  : "",
              ship_district:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.districts.name
                  : "",
              uship_district:
                res.user_shipping_address !== null
                  ? res.user_shipping_address.district
                  : "",
              ubship_first_name:
                res.user_billing_address !== null
                  ? res.user_billing_address.first_name
                  : "",
              ubship_last_name:
                res.user_billing_address !== null
                  ? res.user_billing_address.last_name
                  : "",
              ubship_phone_no:
                res.user_billing_address !== null
                  ? res.user_billing_address.phone_no
                  : "",
              ubship_email:
                res.user_billing_address !== null
                  ? res.user_billing_address.email
                  : "",
              ubship_billing_address:
                res.user_billing_address !== null
                  ? res.user_billing_address.billing_address
                  : "",
              ubship_post_code:
                res.user_billing_address !== null
                  ? res.user_billing_address.post_code
                  : "",
              bship_district:
                res.user_billing_address !== null
                  ? res.user_billing_address.districts.name
                  : "",
              ubship_district:
                res.user_billing_address !== null
                  ? res.user_billing_address.district
                  : "",
            });
          } else {
            console.log("Server Error!");
            throw res;
          }
        });
    }
  };

  saveuserInfo = () => {
    if (this.validate()) {
      let input = {};
      input["first_name"] = "";
      input["last_name"] = "";
      input["email"] = "";
      input["new_password"] = "";

      var data = {
        id: this.state.user_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_no: this.state.phone_no,
        email: this.state.email,
        password: this.state.new_password,
      };
      this.props.saveUser(data);
    }
  };

  saveShippingAddress = () => {
    var data = {
      uship_first_name: this.state.uship_first_name,
      uship_last_name: this.state.uship_last_name,
      uship_phone_no: this.state.uship_phone_no,
      uship_email: this.state.uship_email,
      uship_post_code: this.state.uship_post_code,
      uship_district: this.state.uship_district,
      uship_shipping_address: this.state.uship_shipping_address,
      user_id: this.state.user_id,
    };
    this.props.saveUserShippingAddress(data);
  };

  saveBillingAddress = () => {
    var data = {
      ubship_first_name: this.state.ubship_first_name,
      ubship_last_name: this.state.ubship_last_name,
      ubship_phone_no: this.state.ubship_phone_no,
      ubship_email: this.state.ubship_email,
      ubship_post_code: this.state.ubship_post_code,
      ubship_district: this.state.ubship_district,
      ubship_billing_address: this.state.ubship_billing_address,
      user_id: this.state.user_id,
    };
    this.props.saveUserBillingAddress(data);
  };

  onTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  logout = () => {
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(BASE_URL + "/auth/logout/", {
      headers,
      body: "",
      method: "POST",
    }).then((res) => {
      this.setState({ isLogout: true, is_login: false });
      window.location.href = "/home";
      if (res.status === 204) {
        return { status: res.status, data: {} };
      } else if (res.status < 500) {
        return res.json().then((data) => {
          return { status: res.status, data };
        });
      } else {
        console.log("Server Error!");
        throw res;
      }
    });
  };

  render() {
    const { order, districts, isShipping, isBilling } = this.state;
   const makeCurrentRoute = (key, value) => {
      const params = 'page'
      // alert(params)
      const allParams = params.split('?')
      // alert(allParams)
      const allParamsData = [];
      allParams.forEach(eachItem => {
        allParamsData[eachItem.split('=')] = eachItem.split('=')
      })
      allParamsData[key] = value
      if (key === 'page') {
        allParamsData['page'] = value;
      }
      let queryParams = '';
      console.log(queryParams)
      for (const key in allParamsData) {
        queryParams += (key + "=" + allParamsData[key])
        queryParams += '&'
      }
      queryParams = queryParams.substring(0, queryParams.length - 1);
      // history.push('/category?' + queryParams)
      // alert(queryParams)
      this.orderList(CUSTOMER_ORDERS_URL + "?" + queryParams);
    }
    return (
      <div>
        <main className="main account">
          <div
            className="page-header"
            style={{
              backgroundImage: `url(${"static/images/page-header.jpg"})`,
              backgroundColor: "#3C63A4",
            }}
          >
            <h1 className="page-title">My Account</h1>
            <ul className="breadcrumb">
              <li>
                <i className="d-icon-home" />
              </li>
              <li>My Account</li>
            </ul>
          </div>
          {/* <!-- End PageHeader --> */}
          <div className="page-content mt-10 mb-10">
            {!this.state.isLogout ? (
              <div className="container pt-1">
                <div className="tab tab-vertical">
                  <ul className="nav nav-tabs mb-4" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active pl-0" href="#dashboard">
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link pl-0" href="#orders">
                        Orders
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link pl-0" href="#downloads">
                        Downloads
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link pl-0" href="#pre-order">
                        Pre Orders
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a className="nav-link pl-0" href="#address">
                        Addresses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link pl-0" href="#account">
                        Account details
                      </a>
                    </li>
                    <li className="nav-item">
                      {this.state.is_login ? (
                        <a
                          className="nav-link pl-0"
                          href=""
                          onClick={this.logout}
                        >
                          Logout
                        </a>
                      ) : null}
                    </li>
                    {/* <Link className="cart-toggle " to="/account">
                                        <i className="d-icon-user"></i>
                                        <span>{this.state.userName!=='' && this.state.userName!==null ? this.state.userName : 'No Name'}</span>
                                    </Link> */}
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="dashboard">
                      <div className="row">
                        {/* <div className="col-md-12">
                          <div className="card d-flex">
                            <div className="card-body w-100 center-div text-white bg-primary">
                              You have{" "}
                              <span
                                class="font-weight-bold"
                                style={{ fontSize: "24px" }}
                              >
                                {" "}
                                &nbsp; {this.state.point} &nbsp;{" "}
                              </span>{" "}
                              point.
                            </div>
                            
                          </div>
                        </div> */}
                        <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-blue order-card">
                            <div className="card-block">
                              <h6 className="m-b-20 text-white">All Order</h6>
                              <h5 className="text-right text-white">
                                <i className="fa fa-cart-plus f-left"></i>
                                <span>{this.state.total_order}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-green order-card">
                            <div className="card-block">
                              <h6 className="m-b-20 text-white">Pending</h6>
                              <h5 className="text-right text-white">
                                <i className="fa fa-spinner f-left"></i>
                                <span>{this.state.total_order_pending}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-yellow order-card">
                            <div className="card-block">
                              <h6 className="m-b-20 text-white">Processing</h6>
                              <h5 className="text-right text-white">
                                <i className="fa fa-rocket f-left"></i>
                                <span>{this.state.total_processing}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                          <div className="card bg-c-pink order-card">
                            <div className="card-block">
                              <h6 className="m-b-20 text-white">Completed</h6>
                              <h5 className="text-right text-white">
                                <i className="fa fa-credit-card f-left"></i>
                                <span>{this.state.total_order_completed}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="tab-pane active" id="dashboard">
                                        <p className="mb-2">
                                            Hello <span>User</span> (not <span>User</span>? <a href="#" className="text-secondary">Log out</a>)
								        </p>
                                        <p>
                                            From your account dashboard you can view your <a href="#orders"
                                                className="link-to-tab text-secondary">recent orders</a>, manage your <a
                                                    href="#address" className="link-to-tab text-secondary">shipping and billing
										addresses</a>, and <a href="#account" className="link-to-tab text-secondary">edit
										your password and account details</a>.
								        </p>
                                    </div> */}
                    <div className="tab-pane" id="orders">
                      <div className="row gutter-lg">
                        <div className="col-lg-12 col-md-12">
                          <div className="table-responsive">
                            <table className="shop-table cart-table mt-2">
                              <thead>
                                <tr className="text-left">
                                  <th>Order</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Total</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.map((order) => (
                                  <tr style={{ lineHeight: "50px" }}>
                                    <td>#{order.order_no}</td>
                                    <td>
                                      {moment(order.order_date).format(
                                        "MMMM Do YYYY H:m A"
                                      )}
                                    </td>
                                    <td>
                                      {findArrayElementByTitle(
                                        OrderStatus,
                                        order.order_status
                                      )
                                        ? findArrayElementByTitle(
                                            OrderStatus,
                                            order.order_status
                                          ).status
                                        : null}
                                    </td>
                                    <td>
                                      {"৳ "+ (order.tax +
                                        order.total_order_price) +
                                        " "}{" "}
                                      FOR{" "}
                                      {
                                         order.total_order_quantity
                                        }{" "}
                                      ITEMS
                                    </td>
                                    <td className="text-right">
                                      <Link
                                        to={"orders?path=" + order?.id}
                                        className="my-1"
                                      >
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          className="view-block"
                                        >
                                          View
                                        </Button>{" "}
                                      </Link>
                                      <Link to={"orders-invoices?path=" + order?.id}>
                                        <Button
                                          variant="secondary"
                                          size="sm"
                                          className="view-block"
                                        >
                                          Invoice
                                        </Button>{" "}
                                      </Link>
                                      
                                    </td>
                                  </tr>
                                ))}

                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <nav className="toolbox toolbox-pagination mb-0 pb-3">
                            <p className="show-info">Showing <span>{order.length} of {this.state.count}</span> Orders</p>
                            {
                                this.state.paginatePageCount > 0 && <ReactPaginate
                                pageCount={this.state.paginatePageCount}
                                pageRangeDisplayed={2}
                                containerClassName="pagination"
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link-prev"
                                nextLinkClassName="page-link-prev"
                                previousLabel={"← Prev"}
                                nextLabel={"Next →"}
                                style={{cursor: 'pointer'}}
                                onPageChange={(value) =>makeCurrentRoute('page', value.selected + 1)}
                                />
                            }
                            </nav>
                      </div>
                    </div>
                    <div className="tab-pane" id="downloads">
                      <div className="row gutter-lg">
                        <div className="col-lg-12 col-md-12">
                          <table className="shop-table cart-table mt-2">
                            <thead>
                              <tr>
                                <th className="text-left">
                                  <span>Order</span>
                                </th>
                                <th className="text-left">
                                  <span>Date</span>
                                </th>
                                <th className="text-left">Status</th>
                                <th className="text-left">Total</th>
                                <th className="text-right">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.map((order) => (
                                <>
                                  <tr style={{lineHeight: "50px"}}>
                                    <td className="text-left">#{order.order_no}</td>
                                    <td>
                                      {moment(order.order_date).format(
                                        "MMMM Do YYYY H:m A"
                                      )}
                                    </td>
                                    <td>
                                      {findArrayElementByTitle(
                                        OrderStatus,
                                        order.order_status
                                      )
                                        ? findArrayElementByTitle(
                                            OrderStatus,
                                            order.order_status
                                          ).status
                                        : null}
                                    </td>
                                    <td>
                                      {"৳ " + order.total_order_price}
                                    </td>
                                    <td className="text-right">
                                      {/* <button
                                        type="submit"
                                        onClick={() =>
                                          alert("Wait, Under Construction!!!")
                                        }
                                        className="view-block btn btn-primary btn-sm mr-1"
                                      >
                                        View
                                      </button> */}
                                      <button
                                        type="submit"
                                        onClick={() =>
                                          alert("Wait, Under Construction!!!")
                                        }
                                        className="btn btn-sm"
                                      >
                                       <a download>Downloads</a> 
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                              
                            </tbody>
                          </table>
                        </div>
                        <nav className="toolbox toolbox-pagination mb-0 pb-3">
                            <p className="show-info">Showing <span>{order.length} of {this.state.count}</span> Orders</p>
                            {
                                this.state.paginatePageCount > 0 && <ReactPaginate
                                pageCount={this.state.paginatePageCount}
                                pageRangeDisplayed={2}
                                containerClassName="pagination"
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link-prev"
                                nextLinkClassName="page-link-prev"
                                previousLabel={"← Prev"}
                                nextLabel={"Next →"}
                                style={{cursor: 'pointer'}}
                                onPageChange={(value) =>makeCurrentRoute('page', value.selected + 1)}
                                />
                            }
                            </nav>
                      </div>
                    </div>
                    <div className="tab-pane" id="pre-order">
                      <div className="row gutter-lg">
                        <div className="col-lg-12 col-md-12">
                          <div className="table-responsive">
                            <table className="shop-table cart-table mt-2">
                              <thead>
                                <tr className="text-left">
                                  <th>Order</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Total</th>
                                  <th>Due</th>
                                  <th className="text-right">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.map((order) => (
                                  <tr style={{ lineHeight: "50px" }}>
                                    <td>#{order.order_no}</td>
                                    <td>
                                      {moment(order.order_date).format(
                                        "MMMM Do YYYY H:m A"
                                      )}
                                    </td>
                                    <td>
                                      {findArrayElementByTitle(
                                        OrderStatus,
                                        order.order_status
                                      )
                                        ? findArrayElementByTitle(
                                            OrderStatus,
                                            order.order_status
                                          ).status
                                        : null}
                                    </td>
                                    <td>
                                      {order.total_pre_order_product} 
                                       
                                    </td>
                                    <td>
                                      {order.total_pre_order_due} 
                                       
                                    </td>
                                    <td className="text-right">
                                      {/* <Link
                                        to={"orders/" + order?.id}
                                        className="my-1"
                                      >
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          className="view-block"
                                        >
                                          View
                                        </Button>{" "}
                                      </Link> */}
                                      <Link to={"pre-orders-invoices/" + order?.id}>
                                        <Button
                                          variant="secondary"
                                          size="sm"
                                          className="view-block"
                                        >
                                          Invoice
                                        </Button>{" "}
                                      </Link>
                                      {/*<Button variant="secondary" size="sm"*/}
                                      {/*        onClick={() => alert("Wait, Under Construction!!!")}>Invoice</Button>{' '}*/}
                                      {/*<button type="submit"*/}
                                      {/*        */}
                                      {/*        className="btn btn-sm pr-1">*/}
                                      {/*</button>*/}
                                      {/*<button type="submit"*/}
                                      {/*        onClick={() => alert("Wait, Under Construction!!!")}*/}
                                      {/*        className="btn btn-sm">*/}
                                      {/*</button>*/}
                                    </td>
                                  </tr>
                                ))}

                                {/* <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>#4613</td>
                                                                    <td>SEPTEMBER 5, 2020</td>
                                                                    <td>CANCELED</td>
                                                                    <td>$860.00 FOR 1 ITEMS</td>
                                                                    <td>
                                                                        <button type="submit" className="btn btn-sm">View</button>
                                                                        <button type="submit" className="btn btn-sm">Invoice</button>
                                                                    </td>
                                                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="address">
                      {/* <p className="mb-2">The following addresses will be used on the checkout page by default.
								        </p> */}
                      <div className="row">
                        <div className="col-lg-6 mb-4">
                          <div className="card card-address">
                            <div className="card-body">
                              <h5 className="card-title">Billing Address</h5>
                              <p>
                                {this.state.ubship_first_name +
                                  " " +
                                  this.state.ubship_last_name}
                                {(this.state.ubship_first_name.length > 0 ||
                                  this.state.ubship_last_name.length > 0) && (
                                  <br />
                                )}
                                {this.state.ubship_billing_address}
                                {this.state.ubship_billing_address.length >
                                  0 && <br />}
                                {this.state.ubship_phone_no}
                                {this.state.ubship_phone_no.length > 0 && (
                                  <br />
                                )}
                                {this.state.bship_district}
                                {this.state.bship_district.length > 0 && <br />}
                                {this.state.ubship_post_code}
                                {this.state.ubship_post_code.length > 0 && (
                                  <br />
                                )}
                                {this.state.ubship_email}
                              </p>
                              <button
                                onClick={this.onBillingChange}
                                className="btn btn-link btn-secondary btn-underline"
                              >
                                Edit <i className="far fa-edit" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                          <div className="card card-address">
                            <div className="card-body">
                              <h5 className="card-title">Shipping Address</h5>
                              {/* <p>You have not set up this type of address yet.</p> */}
                              <p>
                                {this.state.uship_first_name +
                                  " " +
                                  this.state.uship_last_name}
                                {(this.state.uship_first_name.length > 0 ||
                                  this.state.uship_last_name.length > 0) && (
                                  <br />
                                )}
                                {this.state.uship_shipping_address}
                                {this.state.uship_shipping_address.length >
                                  0 && <br />}
                                {this.state.uship_phone_no}
                                {this.state.uship_phone_no.length > 0 && <br />}
                                {this.state.ship_district}
                                {this.state.ship_district.length > 0 && <br />}
                                {this.state.uship_post_code}
                                {this.state.uship_post_code.length > 0 && (
                                  <br />
                                )}
                                {this.state.uship_email}
                              </p>
                              <button
                                onClick={this.onShippingChange}
                                className="btn btn-link btn-secondary btn-underline"
                              >
                                Edit <i className="far fa-edit" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="account">
                      <div className="form">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>First Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              value={this.state.first_name}
                              onChange={this.onTextChange}
                              required=""
                            />
                            <div className="text-danger">
                              {this.state.errors.first_name}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              value={this.state.last_name}
                              onChange={this.onTextChange}
                              required=""
                            />
                            <div className="text-danger">
                              {this.state.errors.last_name}
                            </div>
                          </div>
                        </div>

                        {/* <label>Display Name *</label>
                                            <input type="text" className="form-control mb-0" name="username" value={this.state.username} onChange={this.onTextChange} required="" />
                                            <small className="d-block form-text mb-4">This will be how your name will be displayed
										in the account section and in reviews</small> */}

                        <label>Email address *</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={this.state.email}
                          onChange={this.onTextChange}
                          required=""
                        />
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                        <label>
                          Current password (leave blank to leave unchanged)
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={this.state.current_password}
                          onChange={this.onTextChange}
                          name="current_password"
                        />
                        <div className="text-danger">
                          {this.state.errors.current_password}
                        </div>
                        <label>
                          New password (leave blank to leave unchanged)
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={this.state.new_password}
                          onChange={this.onTextChange}
                          name="new_password"
                        />
                        <div className="text-danger">
                          {this.state.errors.new_password}
                        </div>

                        <label>Confirm new password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={this.state.confirm_password}
                          onChange={this.onTextChange}
                          name="confirm_password"
                        />
                        <div className="text-danger">
                          {this.state.errors.confirm_password}
                        </div>
                        <button
                          onClick={this.saveuserInfo}
                          className="btn btn-primary btn-reveal-right"
                        >
                          SAVE CHANGES <i className="d-icon-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                    {isShipping ? (
                      <div className="page-content" id="addressedit">
                        <form action="#" className="form">
                          <div className="row gutter-lg">
                            <div className="col-sm-6">
                              <label>First Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="uship_first_name"
                                value={this.state.uship_first_name}
                                onChange={this.onTextChange}
                                required=""
                              />
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label>Last Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="uship_last_name"
                                value={this.state.uship_last_name}
                                onChange={this.onTextChange}
                                required=""
                              />
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            </div>
                          </div>

                          <label>Email address *</label>
                          <input
                            type="email"
                            className="form-control"
                            name="uship_email"
                            value={this.state.uship_email}
                            onChange={this.onTextChange}
                            required=""
                          />
                          <div className="text-danger">
                            {this.state.errors.email}
                          </div>
                          <label>Address *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="uship_shipping_address"
                            value={this.state.uship_shipping_address}
                            onChange={this.onTextChange}
                            required
                            placeholder="House number and Street name"
                          />
                          <div className="text-danger">
                            {this.state.errors.address}
                          </div>
                          <div className="col-xs-6">
                            <label>Phone *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="uship_phone_no"
                              value={this.state.uship_phone_no}
                              onChange={this.onTextChange}
                              required
                            />
                            <div className="text-danger">
                              {this.state.errors.phone_no}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-xs-6">
                                                <label>Division *</label>
                                                <input type="text" className="form-control" name="division" required="" />
                                            </div> */}
                            <div className="col-xs-6">
                              <label>District *</label>
                              <select
                                name="uship_district"
                                onChange={this.onTextChange}
                                className="form-control"
                                required
                              >
                                <option>Select Your District</option>

                                {districts.map((item) => (
                                  <option
                                    key={item.id}
                                    selected={
                                      item.id === this.state.uship_district
                                    }
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <div className="text-danger">
                                {this.state.errors.dist}
                              </div>

                              {/* <input type="text" className="form-control" name="district" value={this.state.district} onChange={this.onTextChange} required="" /> */}
                            </div>
                            <div className="col-xs-6">
                              <label>Postcode / ZIP *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="uship_post_code"
                                value={this.state.uship_post_code}
                                onChange={this.onTextChange}
                                required
                              />
                              <div className="text-danger">
                                {this.state.errors.post_code}
                              </div>
                            </div>
                          </div>

                          <button
                            type="button"
                            onClick={this.saveShippingAddress}
                            className="btn btn-primary btn-reveal-right"
                          >
                            SAVE CHANGES <i className="d-icon-arrow-right"></i>
                          </button>
                          <button
                            type="button"
                            onClick={this.onShippingChangeClose}
                            className="btn btn-info btn-reveal-right"
                          >
                            CLOSE <i className="d-icon-arrow-right"></i>
                          </button>
                        </form>
                      </div>
                    ) : null}

                    {isBilling ? (
                      <div className="page-content" id="addressedit">
                        <form action="#" className="form">
                          <div className="row gutter-lg">
                            <div className="col-sm-6">
                              <label>First Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="ubship_first_name"
                                value={this.state.ubship_first_name}
                                onChange={this.onTextChange}
                                required=""
                              />
                              <div className="text-danger">
                                {this.state.errors.first_name}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label>Last Name *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="ubship_last_name"
                                value={this.state.ubship_last_name}
                                onChange={this.onTextChange}
                                required=""
                              />
                              <div className="text-danger">
                                {this.state.errors.last_name}
                              </div>
                            </div>
                          </div>

                          <label>Email address *</label>
                          <input
                            type="email"
                            className="form-control"
                            name="ubship_email"
                            value={this.state.ubship_email}
                            onChange={this.onTextChange}
                            required=""
                          />
                          <div className="text-danger">
                            {this.state.errors.email}
                          </div>
                          <label>Address *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="ubship_billing_address"
                            value={this.state.ubship_billing_address}
                            onChange={this.onTextChange}
                            required
                            placeholder="House number and Street name"
                          />
                          <div className="text-danger">
                            {this.state.errors.address}
                          </div>
                          <div className="col-xs-6">
                            <label>Phone *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="ubship_phone_no"
                              value={this.state.ubship_phone_no}
                              onChange={this.onTextChange}
                              required
                            />
                            <div className="text-danger">
                              {this.state.errors.phone_no}
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-xs-6">
                                                <label>Division *</label>
                                                <input type="text" className="form-control" name="division" required="" />
                                            </div> */}
                            <div className="col-xs-6">
                              <label>District *</label>
                              <select
                                name="ubship_district"
                                onChange={this.onTextChange}
                                className="form-control"
                                required
                              >
                                <option>Select Your District</option>

                                {districts.map((item) => (
                                  <option
                                    key={item.id}
                                    selected={
                                      item.id === this.state.ubship_district
                                    }
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <div className="text-danger">
                                {this.state.errors.dist}
                              </div>

                              {/* <input type="text" className="form-control" name="district" value={this.state.district} onChange={this.onTextChange} required="" /> */}
                            </div>
                            <div className="col-xs-6">
                              <label>Postcode / ZIP *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="ubship_post_code"
                                value={this.state.ubship_post_code}
                                onChange={this.onTextChange}
                                required
                              />
                              <div className="text-danger">
                                {this.state.errors.post_code}
                              </div>
                            </div>
                          </div>

                          <button
                            type="button"
                            onClick={this.saveBillingAddress}
                            className="btn btn-primary btn-reveal-right"
                          >
                            SAVE CHANGES <i className="d-icon-arrow-right"></i>
                          </button>
                          <button
                            type="button"
                            onClick={this.onBillingChangeClose}
                            className="btn btn-info btn-reveal-right"
                          >
                            CLOSE <i className="d-icon-arrow-right"></i>
                          </button>
                        </form>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <Redirect to="/home" />
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default AccountComponent;
