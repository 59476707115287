import member from './member';
import recipes from './recipes';
import cart from './cartReducers';
import product from './productReducers';
import auth from './authReducers'
const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  member,
  recipes,
  cart,
  product,
  auth,
};

