import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import {
    LOAD_USER_URL,
    DISTRICT_URL,
    PAYMENT_METHOD_URL,
    NO_TOKEN_SHIPPING_CLASS,
    COUPON_CODE_URL
} from '../../constants/api';
import axios from 'axios';
import {calculateCouponDiscount} from '../../constants/customMethod'
import {ToastContainer, toast} from 'react-toastify';
import { emptyCart  } from '../../actions/cartActions';
class CheckoutComponent extends Component {

    state = {
        
        districts:[],
        payments:[],
        payment:"",
        taxAmount:"",
        is_login:false,
        isShipping:false,
        istermcond:false,
        ship_first_name:'',
        ship_last_name:'',
        shipping_address:'',
        ship_phone_no:'',
        ship_email:'',
        ship_post_code:'',
        ship_dist:'',
        first_name:'',
        last_name:'',
        reference_name:'',
        reference_phone_no:'',
        phone_no:'',
        reward_point:0,
        email:'',
        address:'',
        notes:'',
        id:'',
        post_code:'',
        delivery_charge:0,
        discount_amount:0,
        dist:'',
        errors:{},
        error:'',
        coupon_code:'',
        redeem_point:0,
        coupon_id:'',
        coupon_type:'',
        discount_type:'',
        foundRegular:true,
        isCoupon:false,
        emailError:false,

    };

    validate() {
        let pattern;
        let input = this.state;

        let errors = {};

        let isValid = true;

        if (!input["first_name"]) {
            isValid = false;
            errors["first_name"] = "Please enter your first name.";
        }

        // if (!input["last_name"]) {
        //     isValid = false;
        //     errors["last_name"] = "Please enter your last name.";
        // }

        // if (!input["email"]) {
        //     isValid = false;
        //     errors["email"] = "Please enter your email Address.";
        // }

        // if (typeof input["email"] !== "undefined") {
        //     pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        //     if (!pattern.test(input["email"])) {
        //         isValid = false;
        //         errors["email"] = "Please enter valid email address.";
        //     }
        // }

        if (!input["phone_no"]) {
            isValid = false;
            errors["phone_no"] = "Please enter your phone no.";
        }

        if (typeof input["phone_no"] !== "" && typeof input["phone_no"] !== null && typeof input["phone_no"] !== undefined) {
            pattern = !/^(\0|0)1[13456789][0-9]{8}$/i.test;

            if (!(input["phone_no"])) {
                isValid = false;
                errors["phone_no"] = "Invalid phone number, must be 11 digits.";
            }
        }

        if (!input["address"]) {
            isValid = false;
            errors["address"] = "Please enter your address.";
        }

        // if (!input["post_code"]) {
        //     isValid = false;
        //     errors["post_code"] = "Please enter your post code.";
        // }

        if (!input['dist']) {
            isValid = false;
            errors["dist"] = "Please select your district.";
        }

        if (!input["payment"]) {
            isValid = false;
            errors["payment"] = "Please enter your payment method.";
        }

        if (this.state.isShipping) {
            if (this.state.ship_dist === '' || this.state.ship_dist === undefined) {
                isValid = false;
                errors["ship_dist"] = "Please select your shipping district.";
            }

            if (!input["ship_first_name"]) {
                isValid = false;
                errors["ship_first_name"] = "Please enter your shipping first name.";
            }

            // if (!input["ship_last_name"]) {
            //     isValid = false;
            //     errors["ship_last_name"] = "Please enter your last name.";
            // }

            // if (!input["ship_email"]) {
            //     isValid = false;
            //     errors["ship_email"] = "Please enter your email Address.";
            // }

            // if (typeof input["ship_email"] !== "undefined") {
            //     pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            //     if (!pattern.test(input["ship_email"])) {
            //         isValid = false;
            //         errors["ship_email"] = "Please enter valid shipping email address.";
            //     }
            // }
            

            if (!input["ship_phone_no"]) {
                isValid = false;
                errors["ship_phone_no"] = "Please enter your shipping phone no.";
            }

            if (typeof input["ship_phone_no"] !== "" && typeof input["ship_phone_no"] !== null && typeof input["ship_phone_no"] !== undefined) {
                pattern = !/^(\0|0)1[13456789][0-9]{8}$/i.test;

                if (!(input["ship_phone_no"])) {
                    isValid = false;
                    errors["ship_phone_no"] = "Invalid shipping phone number, must be 11 digits.";
                }
            }

            if (!input["shipping_address"]) {
                isValid = false;
                errors["shipping_address"] = "Please enter your shipping address.";
            }

            // if (!input["ship_post_code"]) {
            //     isValid = false;
            //     errors["ship_post_code"] = "Please enter your shipping post code.";
            // }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }
    validateEmail(email){
        // const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const pattern =/[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g
        // const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        
        
        const result = pattern.test(email);
        if(result===true){
          this.setState({
            emailError:false,
            email:email
            
            
          });
          
        }else{
            this.setState({
                emailError:true
            })
        }
        
        // else{
        //   this.setState({
        //     email:alert("Please enter Valid Email")
        //   })
        // }
      }

      validateShipEmail(ship_email){
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(ship_email);
        if(result===true){
          this.setState({
            emailError:false,
            ship_email:ship_email
          })
        }else{
            this.setState({
                emailError:true
            })
        } 
        // else{
        //   this.setState({
        //     email:alert("Please enter Valid Email")
        //   })
        // }
      }

    componentDidMount() {
        // alert(JSON.stringify(this.props.items.product_name))
        const {items} = this.props
        let taxSum = 0.0;
        let foundRegular=false;
        if (items.length > 0) {
           
            for (let i = 0; i < items.length; i++) {
                if(!items[i].is_pre_order){
                    foundRegular=true
                }
                for (let j = 0; j < items[i].taxs.length; j++) {
                    taxSum += parseFloat(items[i].taxs[j].rate) * items[i].quantity
                }
            }
        }
       
        if(!foundRegular){
           
               this.setState({foundRegular:false})
          
           
        }
        
        this.setState({taxAmount: taxSum})
        this.loadUser()
        this.fetchData().then(() => {
        })
        this.fetchPaymentData().then(() => {
        })

        // setTimeout(
        //     function () {
        //         this.getDeleveryCharge().then(() => {
        //         })
        //     }.bind(this),
        //     2000
        // );
        this.getCouponDiscount()
    }



    fetchData = async () => {
        const result = await axios(
            DISTRICT_URL
        );
        this.setState({districts: result.data.results});
    };

    fetchPaymentData = async () => {
        const result = await axios(
            PAYMENT_METHOD_URL
        );
        var data=[]
        for (var i=0;i<result.data.results.length;i++){
            data.push(result.data.results[i])
            if(i===0){
                data[i]["selected"]=true
            }else{
                data[i]["selected"]=false 
            }
            
        }

        this.setState({payments: data});
    };

    // getDeleveryCharge = async () => {
    //     const {dist, ship_dist, isShipping} = this.state
    //     if (this.props.items.length > 0  && this.props.total<490) {
    //         this.setState({delivery_charge: 10});
    //     }else{
    //         this.setState({delivery_charge:0})
    //       }

    // }

    isRedeemChange = (e) => {
        if (e.target.checked) {
            const total = (this.props.total + this.state.taxAmount + this.state.delivery_charge) - this.state.discount_amount;
            if (this.state.reward_point >= total) {
                this.setState({redeem_point: total, payment: 3})
            } else {
                toast.warn("You have not sufficinent points")
                this.setState({redeem_point: 0, payment: ""})
            }
        } else {
            this.setState({redeem_point: 0, payment: ""})
        }
    }

    istermcondChange =(e)=>{ 

        

        this.setState({istermcond:e.target.checked})
      
     
       
    }

    isShippingChange = (e) => {
        this.setState({isShipping: e.target.checked})
        // setTimeout(
        //     function () {
        //         this.getDeleveryCharge().then(() => {
        //         })
        //     }.bind(this),
        //     500
        // );
    }

    isCouponChange = (e) => {
        // alert(JSON.stringify(e.target.value))
        this.setState({isCoupon:e.target.value,[e.target.name]:e.target.value})
        // setTimeout(
        //     function () {
        //         this.getDeleveryCharge().then(() => {
        //         })
        //     }.bind(this),
        //     500
        // );
    }

    placeOrder = () => {
if(this.state.istermcond){
   

    if (this.validate()){
         
        
       var data = {
            
            customer:this.state.id,
            seller: this.props.id,
            is_shipping:this.state.isShipping,
            first_name:this.state.first_name,
            last_name:this.state.last_name,
            reference_name:this.state.reference_name,
            reference_phone_no:this.state.reference_phone_no,
            address:this.state.address,
            phone_no:this.state.phone_no,
            email: this.state.email,
            post_code:this.state.post_code,
            district:this.state.dist,
            note:this.state.notes,
            tax:this.state.taxAmount,
            shipping_charge:this.state.delivery_charge,	
            sp_first_name:this.state.ship_first_name,
            sp_last_name:this.state.ship_last_name,
            sp_shipping_address:this.state.shipping_address,
            sp_phone_no:this.state.ship_phone_no,
            discount_amount:this.state.discount_amount,
            sp_email: this.state.ship_email,
            sp_post_code:this.state.ship_post_code,
            sp_district:this.state.ship_dist,
            reward_points:this.state.redeem_point,
            payment_method:this.state.payment

        };

        this.props.placeOrder(data)
    }
}else{
    toast.warn("Please cheked terms and codiction")
}      
       

       
    }

    loadUser = () => {
        const token = localStorage.getItem('token');
        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;

            fetch(LOAD_USER_URL, {"method": "GET", headers,})
                .then(res => res.json())
                .then(res => {
                    if (res.id !== undefined) {
                        this.setState({
                            isShipping: res.user_shipping_address !== null,
                            ship_first_name: res.user_shipping_address !== null ? res.user_shipping_address.first_name : '',
                            ship_last_name: res.user_shipping_address !== null ? res.user_shipping_address.last_name : '',
                            shipping_address: res.user_shipping_address !== null ? res.user_shipping_address.shipping_address : '',
                            ship_phone_no: res.user_shipping_address !== null ? res.user_shipping_address.phone_no : '',
                            ship_email: res.user_shipping_address !== null ? res.user_shipping_address.email : '',
                            ship_post_code: res.user_shipping_address !== null ? res.user_shipping_address.post_code : '',
                            ship_dist: res.user_shipping_address !== null ? res.user_shipping_address.district : '',
                            is_login: true,
                            reward_point: res.point,
                            first_name: res.first_name,
                            last_name: res.last_name,
                            reference_name:res.reference_name,
                            reference_phone_no:res.reference_phone_no,
                            id: res.id,
                            address: res.address,
                            dist: res.district,
                            phone_no: res.phone_no,
                            email: res.email,
                            post_code: res.post_code,
                            payment_method: res.payment
                        })
                    } else {
                        throw res;
                    }
                })
        }
    }

    onChangeText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onTextChange = (e) => {
        var keyArry = ["post_code", "ship_post_code", "dist", 'ship_dist'];
// alert(JSON.stringify(e.target.value))
        this.setState({
            [e.target.name]: e.target.value
        })
        if(e.target.name==='email'){
            this.validateEmail(e.target.value);
           }
           
        if(e.target.name==='ship_email'){
            this.validateShipEmail(e.target.value);
        }

        // if (keyArry.indexOf(e.target.name) > -1) {
        //     setTimeout(
        //         function () {
        //             this.getDeleveryCharge().then(() => {
        //             })
        //         }.bind(this),
        //         500
        //     );
        // }
    }

    onRadioChange = (index) => {
        var payment_id = this.state.payments[index].id
        var data=[]
        for (var i=0;i<this.state.payments.length;i++){
            data.push(this.state.payments[i])
            if(i===index){
                data[i]["selected"]=true
            }else{
                data[i]["selected"]=false
            }
            // if(i===0){
            //     data[i]["selected"]=true
            // }else{
            //     data[i]["selected"]=false 
            // }
            
        }
        this.setState({
            payment: payment_id,
            payments:data
        })
    }

    removeCouponDiscount = () => {
 
        localStorage.removeItem('@coupon')
        localStorage.removeItem('@coupon_id')
        this.setState({discount_amount: 0, coupon_type: 2,discount_type:""})
        toast.success("Coupon has been removed")
      
      }

    getCouponDiscount = async () => {
        const token = localStorage.getItem('token');
        let headers = {
            "Content-Type": "application/json",
            
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
        var coupon_code = await localStorage.getItem('@coupon')


        if (coupon_code !== null && coupon_code !== '') {
            // alert(COUPON_CODE_URL + "?coupon_code=" + coupon_code,)
            fetch(COUPON_CODE_URL + "?coupon_code=" + coupon_code+"&coupon_type=2", { "method": "GET",headers })
                .then(res => res.json())
                .then(result => {
                    // alert(JSON.stringify(result))
                    if (result.error) {
                        this.setState({discount_amount: 0, coupon_type: 2,discount_type:""})

                        // this.props.emptyCart()
                        localStorage.removeItem('@coupon')
                        localStorage.removeItem('@coupon_id')

                    } else {
                        if (result.amount <= this.props.total) {
                            let totalAmount = this.props.total 
                            let totaldiscount = calculateCouponDiscount(result.coupon_type,result.discount_type, result.discount_amount, totalAmount)
                            //this.setState({discount_amount: totaldiscount, coupon_type: result.coupon_type})
                            // alert(totaldiscount)
                            localStorage.setItem('@coupon', result.coupon_code)
                            localStorage.setItem('@coupon_id', String(result.id))
                            // toast.success("Token has been applied successfully")
                            
                            this.setState({discount_amount: totaldiscount, coupon_type: 2,discount_type:result.discount_type})

                        }

                    }
                })
        }
    
    }

    checkCouponDiscount = async () => {
        const token = localStorage.getItem('token');
        // alert(token)
        let headers = {
            "Content-Type": "application/json",
            
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }else{
            toast.warn("Please Login to apply coupon")
        }

        if (this.state.coupon_code !== '') {
            fetch(COUPON_CODE_URL + "?coupon_code=" + this.state.coupon_code+"&coupon_type=2", {"method": "GET",headers})
                .then(res => res.json())
                .then(result => {
                    // alert(JSON.stringify(result))
                
                    if (result.error) {
                        this.setState({discount_amount: 0, coupon_type: 2,discount_type:""})
                        // localStorage.setItem('@coupon_code',result.coupon_code);
                        // localStorage.setItem('@coupon_id',result.id);
                        toast.warn("Invalid Coupon Code")
                    } else {
                        if (result.amount <= this.props.total){
                            let totalAmount = this.props.total 
                            let totaldiscount = calculateCouponDiscount(result.discount_type,result.coupon_type, result.discount_amount, totalAmount)
                            
                            localStorage.setItem('@coupon',result.coupon_code);
                            localStorage.setItem('@coupon_id',String(result.id));
                            this.setState({discount_amount: totaldiscount, coupon_type: result.coupon_type,discount_type:result.discount_type})
                            toast.success("Coupon Code has been applied successfully")

                        }else{
                            toast.warn('You have to purchase more than ' +
                            result.amount +
                            ' amount to apply coupon',)
                        }
                    }
                    
                })
            
        } else {
            toast.warn("Please enter coupon Code")
        }
    
        this.setState({coupon_code: ''})

    }

    render() {
        const {districts, isShipping, payments} = this.state
        
        return (
            <div>
                <ToastContainer/>
                <main className="main checkout container">
                    <div className="page-header bg-dark "
                         style={{
                             backgroundImage: `url(${'static/images/shop/page-header-back.jpg'})`,
                         }}>
                        <h1 className="page-title">Checkout</h1>
                        <ul className="breadcrumb">
                            <li><Link to={"/"}><i className="d-icon-home"/></Link></li>
                            <li>Checkout</li>
                        </ul>
                    </div>
                    {/* <!-- End PageHeader --> */}
                    <div className="page-content pt-8 pb-8">
                        <div className="container mt-4">
                            {/* <div className="card"> */}
                                {/* <div className="card-header">
                                    <a href="#collapse1-1" className="expand">Coupon code</a>
                                </div> */}
                                        {/* <div className="col-xs-6">
                                            <div className="form-group mb-2">

                                                <label>Coupon Type </label>
                                                <select name='coupon_type' onChange={this.isCouponChange}   className="form-control"
                                                        required>
                                                    <option value="">Select Coupon Type</option>
                                                    <option value="1">Food</option>
                                                    <option value="2">Shop</option>


                                                </select>
                                                
                                            </div>
                                        </div> */}
                                {/* {this.state.isCoupon &&  */}
                                {/* <div id="collapse1-1" className="collapsed">
                                    <div className="card-body input-wrapper input-wrapper-inline input-coupon">
                                        <input type="text" onChange={this.onChangeText} name="coupon_code"
                                               className="input-text form-control"
                                               id="coupon_code" placeholder="Coupon code"/>
                                        <button onClick={this.checkCouponDiscount}
                                                className="btn btn-primary btn-order apply-coupon">Apply Coupon
                                        </button>

                                    </div>
                                </div> */}
                                {/* } */}
                            {/* </div> */}
                            <div className="row gutter-lg">
                                <div className="col-lg-8">
                                    <h3 className="title title-simple text-left">Billing Details</h3>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group mb-2">
                                                <label>Full Name *</label>
                                                <input type="text" className="form-control" value={this.state.first_name}
                                                       placeholder="Full name"
                                                       onChange={this.onTextChange} name="first_name" required/>
                                                <div className="text-danger">{this.state.errors.first_name}</div>
                                            </div>

                                        </div>
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">
                                                <label>Reference name(optional) </label>
                                                <input type="text" className="form-control" value={this.state.reference_name}
                                                        placeholder='Reference Name'
                                                       onChange={this.onTextChange} name="reference_name" required/>
                                                {/* <div className="text-danger">{this.state.errors.reference_name}</div> */}
                                            </div>
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">
                                                <label>Reference Phone No(optional)</label>
                                                <input type="text" className="form-control" value={this.state.reference_phone_no}
                                                        placeholder='Reference Phone No'
                                                       onChange={this.onTextChange} name="reference_phone_no" required/>
                                                {/* <div className="text-danger">{this.state.errors.reference_phone_no}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">

                                        <label>Address (Pickup Point))*</label>
                                        <input type="text" className="form-control" name="address"
                                               value={this.state.address} onChange={this.onTextChange} required
                                               placeholder="House number and Street name"/>
                                        <div className="text-danger">{this.state.errors.address}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">

                                                <label>Phone *</label>
                                                <input type="text" className="form-control" name="phone_no"
                                                       placeholder="Phone no"
                                                       value={this.state.phone_no} onChange={this.onTextChange} required/>
                                                <div className="text-danger">{this.state.errors.phone_no}</div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">

                                                <label>Email address (optional)</label>
                                                <input type="text" className="form-control" name="email"
                                                       placeholder="Email address"
                                                       value={this.state.email} onChange={this.onTextChange} />
                                                       
                                                {/* <div className="text-danger">{this.state.error}</div> */}
                                                {this.state.emailError ? <span style={{color: "red"}}>Please Enter valid email address</span> : ''}
                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">

                                                <label>District *</label>
                                                <select name='dist' onChange={this.onTextChange} className="form-control"
                                                        required>
                                                    <option value="">Select Your District</option>
                                                    {districts.map(item => (
                                                        <option key={item.id} selected={item.id === this.state.dist}
                                                                value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                <div className="text-danger">{this.state.errors.dist}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xs-6">
                                            <div className="form-group mb-2">

                                                <label>Postcode / ZIP *</label>
                                                <input type="text" className="form-control" name="post_code"
                                                       value={this.state.post_code} onChange={this.onTextChange} required/>
                                                <div className="text-danger">{this.state.errors.post_code}</div>
                                            </div>
                                        </div> */}
                                    </div>
                                    
                                    <div className="form-group mb-2">

                                        <label>Order Notes (optional)</label>
                                        <textarea className="form-control" value={this.state.notes}
                                                  onChange={this.onTextChange} name="notes" cols="30" rows="6"
                                              placeholder="Notes about your order, e.g. special notes for delivery"/>
                                    </div>
                                </div>

                                <aside className="col-lg-4 sticky-sidebar-wrapper">
                                    <div className="sticky-sidebar" data-sticky-options="{'bottom': 50}">
                                        <h3 className="title title-simple text-left">Your Order</h3>
                                        <div className="summary mb-5">
                                            <table className="order-table mb-4">
                                                <thead>
                                                <tr>
                                                    <th className="text-left">Product</th>
                                                    <th className="text-left">Quantity</th>
                                                    <th className="text-left">Unit</th>
                                                    <th className="text-right">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                {this.props.items.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="product-name text-left">{item.product_name.slice(0, 30)}...</td>
                                                        <td className="product-name text-left">{item.quantity}</td>
                                                        <td className="product-name text-left">{ (item.product_units !==undefined && item.product_units !==null && item.product_units !=='' ?item.product_units.name:'') }</td>
                                                        <td className="product-total text-right">৳{item.is_whole_sell && (item.quantity >= item.purchase_quantity) ? item.quantity * item.whole_sell_price : item.quantity * item.new_price}</td>
                                                    </tr>
                                                ))}
                                                {this.props.bundleItems.map((item, bindex) => (
                                                    <tr key={bindex}>
                                                        <td className="product-name text-left">{item.title}</td>
                                                        <td className="product-total text-right">৳{item.total_price * item.quantity}</td>
                                                    </tr>
                                                ))}
                                                {this.props.items.length > 0 && <>
                                                {
                                                    this.state.taxAmount>0?
                                                    <tr>
                                                        <td className="product-name text-left">Tax</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="product-total text-right">৳{this.state.taxAmount}</td>
                                                    </tr>
                                                    :''
                                                }
                                                    
                                                    {/* <tr>
                                                        <td className="product-name text-left">Delivery Charge</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="product-total text-right">৳ {this.state.delivery_charge}</td>
                                                    </tr> */}
                                                    {
                                                        this.state.discount_amount>0?
                                                        <tr>
                                                        <td className="product-name text-left">Discount</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="product-total text-right">(-)৳ {(this.state.discount_amount).toFixed(2)}</td>
                                                    </tr>
                                                    :''
                                                    }
                                                    
                                                    {/* <tr>
                                                        <td className="product-name text-left">
                                                            <input onChange={this.isRedeemChange} type="checkbox"
                                                                   className="custom-checkbox" id="redeem-point"
                                                                   name="redeem-point"/>
                                                            <label className="form-control-label"
                                                                   htmlFor="redeem-point">Redeem Point</label>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="product-total text-right">৳ {this.state.redeem_point}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="product-name text-left"><strong>Grand Total</strong>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="product-total text-right">
                                                            <strong> ৳{this.state.redeem_point <= 0 ? (this.props.total + this.state.taxAmount + this.state.delivery_charge) - this.state.discount_amount : 0}</strong>
                                                        </td>
                                                    </tr>
                                                </>}
                                                </tbody>
                                            </table>

                                            <label><strong>Payment Method *</strong></label>
                                                {payments.map((item, index) => (
                                                    <div className="payment accordion radio-type">
                                                    
                                                        {this.state.foundRegular === true
                                                        ?
                                                        <>
                                                            <input onChange={() => this.onRadioChange(index)}
                                                                value={item.id} type="radio" className="custom-checkbox"
                                                                id={'payment' + index}
                                                                name="payment"/>
                                                                <label htmlFor={'payment' + index}>{item.payment_type===1? item.payment_types:item.mobile_banking_types}</label>
                                                            {
                                                             item.selected ? 
                                                                <div >
                                                                    {item.payment_type===1 ?
                                                                    <>
                                                                    {/* <b>{item.description}</b> */}
                                                                    <p style={{margin:0}}>
                                                                    {Object.keys(item).length > 0 ? <div
                                                                    dangerouslySetInnerHTML={{__html: item.description}}/>:null}
                                                                    </p>
                                                                    
                                                                    {/* <> Bank Name:{item.bank_name}</><br/>
                                                                    <> Branch Name:{item.branch_name}</><br/>   
                                                                    <> Account Name:{item.account_name}</><br/>   
                                                                    <> Account No:{item.account_number}</><br/>   
                                                                    <> Routing Number:{item.routing_number}</> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                    {/* Wallet Number: {item.wallet_number} */}
                                                                    <p style={{margin:0}}>
                                                                    {Object.keys(item).length > 0 ? <div
                                                                    dangerouslySetInnerHTML={{__html: item.description}}/>:null}
                                                                    </p>
                                                                    
                                                                    </>
                                                                    }
                                                                </div>
                                                                :null
                                                            }
                                                        </>
                                                        :
                                                        null
                                                        } 
                                                    </div>
                                                ))}
                                                    <div className="text-danger">{this.state.errors.payment}</div>
                                                    
                                            </div>
                                            
                                              
                                                
                                            <div className="form-checkbox mb-8">
                                            <input onChange={this.istermcondChange} checked={this.state.istermcond ? true:false} type="checkbox" className="custom-checkbox" id="terms-con"
                                                name="terms-con" />
                                                <label className="form-control-label" for="terms-con"><strong>I agree with terms and condition
										        </strong></label>
                                        </div>

                                            
                                            <button  onClick={()=>this.placeOrder()}  className="btn btn-primary btn-order">Confirm Order</button>
                                            
                                            {/* <div className="text-danger">{this.state.errors.payment}</div> */}
                                        </div>

                                        
                                    
                                </aside>
                                <div className="form-checkbox mt-6 mb-6">
                                    <input onChange={this.isShippingChange} checked={isShipping}
                                           type="checkbox" className="custom-checkbox" id="different-address"
                                           name="different-address"/>
                                    <label className="form-control-label" for="different-address">Ship to a different
                                        address?</label>
                                </div>
                                {isShipping &&
                                <div className="col-lg-8">
                                    <h3 className="title title-simple text-left">Shipping Details</h3>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group mb-2">
                                                <label>Full Name *</label>
                                                <input type="text" className="form-control"
                                                       value={this.state.ship_first_name}
                                                       onChange={this.onTextChange} name="ship_first_name"
                                                       placeholder="Full name"
                                                       required/>
                                                <div className="text-danger">{this.state.errors.ship_first_name}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-xs-6">
                                            <div className="form-group mb-2">
                                                <label>Last Name *</label>
                                                <input type="text" className="form-control"
                                                       value={this.state.ship_last_name}
                                                       onChange={this.onTextChange} name="ship_last_name" required/>
                                                <div className="text-danger">{this.state.errors.ship_last_name}</div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <label>Company Name(Optional)</label>
                                        <input type="text" className="form-control" name="company-name" required="" />
                                        <label>Country *</label>
                                        <input type="text" className="form-control" name="country" required="" /> */}
                                    <div className="form-group mb-2">
                                        <label>Address *</label>
                                        <input type="text" className="form-control" name="shipping_address"
                                               value={this.state.shipping_address} onChange={this.onTextChange}
                                               required
                                               placeholder="House number and Street name"/>
                                        <div className="text-danger">{this.state.errors.shipping_address}</div>
                                    </div>
                                    {/* <input type="text" className="form-control" name="address2" required=""
                                            placeholder="Appartments, suite, unit etc ..." /> */}
                                    <div className="row">

                                    <div className="col-xs-6">
                                        <div className="form-group mb-2">
                                            <label>Phone *</label>
                                            <input type="text" className="form-control" name="ship_phone_no"
                                                value={this.state.ship_phone_no} onChange={this.onTextChange}
                                                placeholder="Phone no"
                                                required/>
                                            <div className="text-danger">{this.state.errors.ship_phone_no}</div>
                                        </div>
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="form-group mb-2">
                                            <label>Email address (optional)</label>
                                            <input type="text" className="form-control" name="ship_email"
                                            placeholder="Email address"
                                                value={this.state.ship_email} onChange={this.onTextChange}
                                                />
                                            {/* <div className="text-danger">{this.state.errors.ship_email}</div> */}
                                            {this.state.emailError ? <span style={{color: "red"}}>Please Enter valid email address</span> : ''}

                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-xs-6">
                                                <label>Division *</label>
                                                <input type="text" className="form-control" name="division" required="" />
                                            </div> */}
                                        <div className="col-xs-6">
                                            <div className="form-group mb-2">
                                                <label>District *</label>
                                                <select name='ship_dist' onChange={this.onTextChange}
                                                        className="form-control" required>
                                                    <option value="">Select Your District</option>

                                                    {districts.map(item => (
                                                        <option key={item.id}
                                                                selected={item.id === this.state.ship_dist}
                                                                value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                <div className="text-danger">{this.state.errors.ship_dist}</div>
                                            </div>

                                            {/* <input type="text" className="form-control" name="district" value={this.state.district} onChange={this.onTextChange} required="" /> */}
                                        </div>
                                        {/* <div className="col-xs-6">
                                            <div className="form-group mb-2">
                                                <label>Postcode / ZIP *</label>
                                                <input type="text" className="form-control" name="ship_post_code"
                                                       value={this.state.ship_post_code}
                                                       onChange={this.onTextChange} required/>
                                                <div className="text-danger">{this.state.errors.ship_post_code}</div>
                                            </div>
                                        </div> */}
                                    </div>
                                    
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}


export default CheckoutComponent
