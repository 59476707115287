// import React, { Component } from "react";
// import moment from "moment";
// import { findArrayElementByTitle } from "../../constants/Validate";
// import { OrderStatus } from "../../constants/HomeData";
// import { Link } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import {
 
//   CUSTOMER_ORDERS_URL,
  
// } from "../../constants/api";
// class PreOrderInvoiceDetails extends Component {
//   componentDidMount() {


//   }


//   orderList = () => {
//     var token = localStorage.getItem("token", "");
//     let headers = {
//       "Content-Type": "application/json",
//     };

//     if (token) {
//       headers["Authorization"] = `Token ${token}`;
//     }

//     fetch(CUSTOMER_ORDERS_URL, { method: "GET", headers })
//       .then((res) => res.json())
//       .then((res) => {
//         // alert(JSON.stringify(res))

//         this.setState({
//           order: res,
//         });
//       });
//   };
//   render() {
//     return (
//       <div>
//         <main className="main account">
//           <div
//             className="page-header"
//             style={{
//               backgroundImage: `url(${"static/images/page-header.jpg"})`,
//             }}
//           >
//             <h1 className="page-title">My Account</h1>
//             <ul className="breadcrumb">
//               <li>
//                 <i className="d-icon-home" />
//               </li>
//               <li>My Account</li>
//             </ul>
//           </div>
//           <div className="page-content mt-10 mb-10">
//             <div className="container pt-1">
//               <div className="page-content-inner-order">
//                 <div className="row mb-10 mb-10">
//                   <div className="col-sm-4 col-xs-12 single-page-content-one">
//                     <h3>Customer Info</h3>
//                     <span>Customer Name: Rashed</span>
//                     <br />
//                     <span>Customer Phone: Rashed</span>
//                     <br />
//                     <span>Customer Email: Rashed</span>
//                     <br />
//                     <span>Customer Default Address: Rashed</span>
//                     <br />
//                   </div>
//                   <div className="col-sm-4 col-xs-12 single-page-content-two">
//                     <h3>Invoice</h3>
//                     <b>BD#001-001</b>
//                     <p>Delivered Date: 10-10-2021</p>
//                   </div>
//                   <div className="col-sm-4 col-xs-12 single-page-content-three">
//                     <h3>Store Info</h3>
//                     <span>Customer Name: Rashed</span>
//                     <br />
//                     <span>Customer Phone: Rashed</span>
//                     <br />
//                     <span>Customer Email: Rashed</span>
//                     <br />
//                     <span>Customer Default Address: Rashed</span>
//                     <br />
//                   </div>
//                 </div>
//                 <div className="table-responsive">
//                   <table className="shop-table cart-table mt-2 table table-bordered table-striped">
//                     <thead>
//                       <tr>
//                         <th>SL</th>
//                         <th>Name</th>
//                         <th>Unit Price</th>
//                         <th>Quantity</th>
//                         <th>Total</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr style={{ lineHeight: "30px", textAlign: "center" }}>
//                         <td>1</td>
//                         <td>Sugar</td>
//                         <td>70</td>
//                         <td>1KG</td>
//                         <td>70</td>
//                       </tr>
//                       <tr style={{ lineHeight: "50px", textAlign: "center" }}>
//                         <td>2</td>
//                         <td>Tea Bag</td>
//                         <td>1</td>
//                         <td>1PACK</td>
//                         <td>69</td>
//                       </tr>
//                       <tr style={{ lineHeight: "50px", textAlign: "center" }}>
//                         <td>3</td>
//                         <td>Tea spoon</td>
//                         <td>30</td>
//                         <td>6 PIECES</td>
//                         <td>180</td>
//                       </tr>
//                       <tr style={{ lineHeight: "30px", textAlign: "center" }}>
//                         <td />
//                         <td />
//                         <td />
//                         <td style={{ paddingTop: "30px" }}>
//                           <b>Subtotal</b>
//                         </td>
//                         <td style={{ paddingTop: "30px" }}>319</td>
//                       </tr>
//                       <tr style={{ lineHeight: "30px", textAlign: "center" }}>
//                         <td />
//                         <td />
//                         <td />
//                         <td>
//                           <b>Discount</b>
//                         </td>
//                         <td>-25</td>
//                       </tr>
//                       <tr style={{ lineHeight: "30px", textAlign: "center" }}>
//                         <td />
//                         <td />
//                         <td />
//                         <td>
//                           <b>Delivery Charge</b>
//                         </td>
//                         <td>+25</td>
//                       </tr>
//                       <tr style={{ lineHeight: "30px", textAlign: "center" }}>
//                         <td />
//                         <td />
//                         <td />
//                         <td>
//                           <b>Total</b>
//                         </td>
//                         <td>319</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </main>
//       </div>
//     );
//   }
// }

// export default PreOrderInvoiceDetails;


import React, {Component} from "react";
import moment from "moment";
import {withRouter} from "react-router-dom";
import {
  CUSTOMER_ORDERS_URL,
} from "../../constants/api";
// import {withRouter} from "react-router";


import BannerBreadcrumb from "../../containers/banners/BannerBreadcrumb";

class PreOrderInvoiceDetails extends Component {
  state = {
    orderDetails: ''
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.orderDetails(id)
  }

  orderDetails = (id) => {
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(CUSTOMER_ORDERS_URL + id  +'/', {method: "GET", headers})
      .then((res) => res.json())
      .then((res) => {
        //  alert(JSON.stringify(res))

        this.setState({
          orderDetails: res,
        });
      });
  };

  render() {
    const {orderDetails} = this.state
    return (
      <div>
        <main className="main">
          <BannerBreadcrumb title={"My Account"} imageLink={'static/images/page-header.jpg'}/>
          <div className="container">
            <div className="page-content mb-10">
              <div className="container pt-1">
                <div className="page-content-inner-order">
                  <div className="row mb-10 mb-10">
                    <div className="col-sm-4 col-xs-12 single-page-content-one">
                      <h3>Customer Info</h3>
                      <span>Customer Name: {orderDetails.customer ? orderDetails.customer.first_name + ' ' + orderDetails.customer.last_name : null}</span>
                      <br/>
                      <span>Customer Phone: {orderDetails.customer ? orderDetails.customer.phone_no : null}</span>
                      <br/>
                      <span>Customer Email: : {orderDetails.customer ? orderDetails.customer.email : null}</span>
                      <br/>
                      <span>Customer Default Address: {orderDetails.customer ? orderDetails.customer.address : null}</span>
                      <br/>
                    </div>
                    <div className="col-sm-4 col-xs-12 single-page-content-two">
                      <h3>Invoice</h3>
                      <b>#{orderDetails ? orderDetails.order_no : null}</b>
                      {orderDetails.delivery_date ?
                        <p>Delivered Date: {moment(orderDetails?.delivery_date_time).format('Do MMMM YYYY')}</p>
                        : null
                      }
                    </div>
                    {/* <div className="col-sm-4 col-xs-12 single-page-content-three">
                      <h3>Store Info</h3>
                      <span>Customer Name: {orderDetails.customer ? orderDetails.customer.first_name + ' ' + orderDetails.customer.last_name : null}</span>
                      <br/>
                      <span>Customer Phone: {orderDetails.customer ? orderDetails.customer.phone_no : null}</span>
                      <br/>
                      <span>Customer Email: : {orderDetails.customer ? orderDetails.customer.email : null}</span>
                      <br/>
                      <span>Customer Default Address: {orderDetails.customer ? orderDetails.customer.address : null}</span>
                      <br/>
                    </div> */}
                  </div>
                  <div className="table-responsive">
                    <table className="shop-table cart-table mt-2 table table-bordered table-striped">
                      <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Name</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      {orderDetails.order_product !== undefined && orderDetails.order_product.map((orderproduct, index) => (

                        <>
                          {orderproduct.is_pre_order ?

                            <tr style={{lineHeight: "30px", textAlign: "center"}}>
                              <td>{index + 1}</td>
                              <td>
                                {orderproduct.product.id}
                              </td>
                              <td>{orderproduct.price}</td>
                              <td>{orderproduct.quantity}</td>
                              <td>{orderproduct.quantity * orderproduct.price}</td>
                            </tr>
                            : null
                          }
                        </>
                      ))}

                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{paddingTop: "30px"}}>
                          <b>Subtotal</b>
                        </td>
                        <td style={{paddingTop: "30px"}}>{(orderDetails.total_pre_order)}</td>
                      </tr>


                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Due</b>
                        </td>
                        <td>-{orderDetails.total_pre_order_due}</td>
                      </tr>
                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>{orderDetails.total_pre_order - orderDetails.total_pre_order_due}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(PreOrderInvoiceDetails) ;
