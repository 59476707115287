import {

    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
} from './action-types/authTypes';

import {
    BASE_URL
} from '../constants/api'
import axios from 'axios';

import { toast } from 'react-toastify'





export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post( BASE_URL +`auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        })
    }
};

export const reset_password = (email) => async dispatch => {
    // alert(JSON.stringify(email))

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({ email });

    try {
        await axios.post( BASE_URL +"auth/djoser/users/reset_password/", body, config);
        toast.success('Please Check your email for changing password')
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        toast.warn('Please try again!!!')
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
    }
};

export const reset_password_confirm = (uid, token, new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
// alert(JSON.stringify(new_password))
    const body = JSON.stringify({ uid, token, new_password });

    try {
        await axios.post(BASE_URL +"auth/djoser/users/reset_password_confirm/", body, config);
        toast.success('Your password has been successfully changed!')
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        toast.error("Please try again!")
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};
