import {
    PRODUCT_FETCH_FAILED,
    PRODUCT_FETCH_BEGINS,
    PRODUCT_FETCH_SUCCESS
} from '../actions/action-types/productTypes';

const initialState = {
    product: {},
    count: null,
    next: null,
    is_loading: false,
    error: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PRODUCT_FETCH_BEGINS:
            return {
                ...state,
                is_loading: true
            };
        case PRODUCT_FETCH_SUCCESS:
            return {
                ...state,
                product: action.payload,
                is_loading: false
            };
        case PRODUCT_FETCH_FAILED:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
