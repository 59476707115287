import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FOOTER_SOCIAL_MEDIA_URL,COPY_RIGHT_URL } from '../../constants/api'
import axios from 'axios';


const Footers = () => {
    const [data, setData] = useState([]);
    const [copyText, setCopyText]= useState([])
    // alert(JSON.stringify(data))
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                FOOTER_SOCIAL_MEDIA_URL,
            );
            // alert(JSON.stringify(result))
            setData(result.data.results);
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                COPY_RIGHT_URL,
            );
            // alert(JSON.stringify(result))
            setCopyText(result.data.results);
        };

        fetchData();
    }, []);

    const regex = /(<([^>]+)>)/ig;

    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-lg-3">
                                <a href="demo1.html" className="logo-footer">
                                    <img src="static/images/logo-footer.png" alt="logo-footer" width="163" height="39" />
                                </a>
                                {/* <!-- End FooterLogo --> */}
                            </div>
                            <div className="col-lg-9">
                                <div className="widget widget-newsletter form-wrapper form-wrapper-inline">
                                    <div className="newsletter-info mx-auto mr-lg-2 ml-lg-4">
                                        <h4 className="widget-title">Subscribe to our Newsletter</h4>
                                        <p>Get all the latest information on Events, Sales and Offers.</p>
                                    </div>
                                    <form action="#" className="input-wrapper input-wrapper-inline">
                                        <input type="email" className="form-control" name="email" id="email"
                                            placeholder="Email address here..." required />
                                        <button className="btn btn-primary btn-md ml-2" type="submit">subscribe<i
                                            className="d-icon-arrow-right"></i></button>
                                    </form>
                                </div>
                                {/* <!-- End Newsletter --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End FooterTop --> */}
                    <div className="footer-middle">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="widget">
                                    <h4 className="widget-title">Contact Info</h4>
                                    <ul className="widget-body">
                                        <li>
                                            <label>Phone:</label>
                                            <a href="#">Toll Free (123) 456-7890</a>
                                        </li>
                                        <li>
                                            <label>Email:</label>
                                            <a href="#">mail@donald.com</a>
                                        </li>
                                        <li>
                                            <label>Address:</label>
                                            <a href="#">123 Street Name, City, England</a>
                                        </li>
                                        <li>
                                            <label>WORKING DAYS/HOURS</label>
                                        </li>
                                        <li>
                                            <a href="#">Mon - Sun / 9:00 AM - 8:00 PM</a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <!-- End Widget --> */}
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="widget ml-lg-4">
                                    <h4 className="widget-title">My Account</h4>
                                    <ul className="widget-body">
                                        <li>
                                            <a href="#">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Order History</a>
                                        </li>
                                        <li>
                                            <a href="#">Returns</a>
                                        </li>
                                        <li>
                                            <a href="#">Custom Service</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms &amp; Condition</a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <!-- End Widget --> */}
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="widget ml-lg-4">
                                    <h4 className="widget-title">Contact Info</h4>
                                    <ul className="widget-body">
                                        <li>
                                            <a href="#">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Order History</a>
                                        </li>
                                        <li>
                                            <a href="#">Returns</a>
                                        </li>
                                        <li>
                                            <a href="#">Custom Service</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms &amp; Condition</a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <!-- End Widget --> */}
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="widget widget-instagram">
                                    <h4 className="widget-title">Instagram</h4>
                                    <figure className="widget-body row">
                                        <div className="col-3">
                                            <img src="static/images/instagram/01.jpg" alt="instagram 1" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/02.jpg" alt="instagram 2" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/03.jpg" alt="instagram 3" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/04.jpg" alt="instagram 4" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/05.jpg" alt="instagram 5" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/06.jpg" alt="instagram 6" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/07.jpg" alt="instagram 7" width="64" height="64" />
                                        </div>
                                        <div className="col-3">
                                            <img src="static/images/instagram/08.jpg" alt="instagram 8" width="64" height="64" />
                                        </div>
                                    </figure>
                                </div>
                                {/* <!-- End Instagram --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End FooterMiddle --> */}
                    <div className="footer-bottom">
                        <div className="footer-left">
                            <figure className="payment">
                                <img src="static/images/payment.png" alt="payment" width="159" height="29" />
                            </figure>
                        </div>
                        <div className="footer-center">
                            {copyText.map(cpytext=>(
                                
                            // <p className="copyright">
                            <>
                                {cpytext.copy_right.replace(regex,'')}
                                {/* </p> */}
                                </>


                            ))}
                            {/* Donald eCommerce &copy; 2020. All Rights Reserved */}
                        </div>
                        <div className="footer-right">
                            <div className="social-links">
                                {data.map(item => (
                                    <a href={item.social_url} title={item.title}>

                                        <img src={item.social_icon} alt="" width="30" height="30" />
                                    </a>


                                ))}
                                {/* <a href="#" className="social-link social-twitter fab fa-twitter"></a>
                                <a href="#" className="social-link social-linkedin fab fa-linkedin-in"></a> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End FooterBottom --> */}
                </div>
            </footer>
            <div className="sticky-footer sticky-content fix-bottom">
                <a href="/" className="sticky-link">
                    <i className="d-icon-home"></i>
                    <span>Home</span>
                </a>
                <a href="/category" className="sticky-link">
                    <i className="d-icon-volume"></i>
                    <span>Categories</span>
                </a>
                <a href="/wishlist" className="sticky-link">
                    <i className="d-icon-heart"></i>
                    <span>Wishlist</span>
                </a>
                <a href="/account" className="sticky-link active">
                    <i className="d-icon-user"></i>
                    <span>Account</span>
                </a>
                <div className="dropdown cart-dropdown dir-up">
                    <a href="/cart" className="sticky-link cart-toggle">
                        <i className="d-icon-bag"></i>
                        <span>Cart</span>
                    </a>
                    {/* <!-- End of Cart Toggle --> */}
                    <div className="dropdown-box">
                        <div className="product product-cart-header">
                            <span className="product-cart-counts">2 items</span>
                            <span><a href="/cart">View cart</a></span>
                        </div>
                        <div className="products scrollable">
                            <div className="product product-cart">
                                <div className="product-detail">
                                    <a href="product.html" className="product-name">Solid Pattern In Fashion Summer Dress</a>
                                    <div className="price-box">
                                        <span className="product-quantity">1</span>
                                        <span className="product-price">$129.00</span>
                                    </div>
                                </div>
                                <figure className="product-media">
                                    <a href="#">
                                        <img src="static/images/cart/product-1.jpg" alt="product" width="90"
                                            height="90" />
                                    </a>
                                    <button className="btn btn-link btn-close">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </figure>
                            </div>
                            {/* <!-- End of Cart Product --> */}
                            <div className="product product-cart">
                                <div className="product-detail">
                                    <a href="product.html" className="product-name">Mackintosh Poket Backpack</a>
                                    <div className="price-box">
                                        <span className="product-quantity">1</span>
                                        <span className="product-price">$98.00</span>
                                    </div>
                                </div>
                                <figure className="product-media">
                                    <a href="#">
                                        <img src="static/images/cart/product-2.jpg" alt="product" width="90"
                                            height="90" />
                                    </a>
                                    <button className="btn btn-link btn-close">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </figure>
                            </div>
                            {/* <!-- End of Cart Product --> */}
                        </div>
                        {/* <!-- End of Products  --> */}
                        <div className="cart-total">
                            <label>Subtotal:</label>
                            <span className="price">$42.00</span>
                        </div>
                        {/* <!-- End of Cart Total --> */}
                        <div className="cart-action">
                            <a href="checkout.html" className="btn btn-dark"><span>Checkout</span></a>
                        </div>
                        {/* <!-- End of Cart Action --> */}
                    </div>
                    {/* <!-- End of Dropdown Box --> */}
                </div>
            </div>
            <a id="scroll-top" href="#top" title="Top" role="button" className="scroll-top"><i className="d-icon-angle-up"></i></a>

        </div>
    )
}

export default Footers

