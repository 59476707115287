import React, {Component} from 'react'
import FadeIn from 'react-fade-in'
import PropTypes from 'prop-types'
import OfferComponent from '../../components/homepage/OfferComponent'
import NewArrivalComponent from '../../components/homepage/NewArrivalComponent'
import MidSeasonSaleComponent from '../../components/homepage/MidSeasonSaleComponent'
import OnSaleProductComponent from '../../components/homepage/OnSaleProductComponent'
import BundleProductComponent from '../../components/homepage/BundleProductComponent'
import CategoryProductComponent from '../../components/homepage/CategoryProductComponent'
import ProductComponent from '../../components/homepage/ProductComponent'
import SpecialOfferComponent from '../../components/homepage/SpecialOfferComponent'
import LatestBlogComponent from '../../components/homepage/LatestBlogComponent'
import OurClientComponent from '../../components/homepage/OurClientComponent'
import MessengerCustomerChat from 'react-messenger-customer-chat';
class HomeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
    }

    static propTypes = {
        Layout: PropTypes.func,
    }

    render() {
        return (
            <div>
                <FadeIn delay={500} transitionDuration={1000}>
                    <OfferComponent addItemToCart={this.props.addItemToCart}/>
                    <NewArrivalComponent addItemToCart={this.props.addItemToCart}
                                         addItemToWishList={this.props.addItemToWishList}/>
                    <MidSeasonSaleComponent addItemToCart={this.props.addItemToCart}/>
                    {/* <OnSaleProductComponent addItemToCart={this.props.addItemToCart}
                                            addItemToWishList={this.props.addItemToWishList}/> */}
                    {/* <BundleProductComponent addItemToCart={this.props.bundleaddItemToCart}
                                            addItemToWishList={this.props.addItemToWishList}/>
                    <CategoryProductComponent addItemToCart={this.props.addItemToCart}
                                              addItemToWishList={this.props.addItemToWishList}/> */}
                    <ProductComponent addItemToCart={this.props.addItemToCart}
                                      addItemToWishList={this.props.addItemToWishList}/>
                    <SpecialOfferComponent addItemToCart={this.props.addItemToCart}/>
                    {/* <LatestBlogComponent addItemToCart={this.props.addItemToCart}/> */}
                    {/* <OurClientComponent addItemToCart={this.props.addItemToCart}/> */}

                    
                </FadeIn>
                {/* <MessengerCustomerChat
                    pageId="103184994792689"
                    appId="266277631317922"
                    themeColor="#00667f"
                    
                    /> */}
                
            </div>
        )
    }
}

export default HomeComponent
