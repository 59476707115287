import React, { Component } from "react";
import { SUBSCRIBE_URL } from "../../constants/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SpecialOfferComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      // error: {}
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onCreateEmail = () => {
    let data = {
      email: this.state.email,
      // error: this.state.error
    };

    let url = SUBSCRIBE_URL;
    fetch(url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // alert(JSON.stringify(data))

        if (data.email.error) {
          toast.warning("Email already subscribed");
        } else {
          toast.success("Email has been subscribed");
        }
      })
      .catch((error) => {
        // alert(JSON.stringify(error.email))
        toast.warning("Error:", error);
      });
  };

  handleSubmit = (event) => {
    // alert(JSON.stringify(this.state))
    event.preventDefault();
    this.onCreateEmail();
  };
  render() {
    return (
      <>
        <ToastContainer />
        <main className="main pt-45px pb-25px get-special-offer-res">
          <div className="page-content">
            <div className="container">
              <section className="mt-10">
                <div
                  className="banner banner-newsletter pl-lg-6 pr-lg-6"
                  style={{ backgroundColor: "#f4f4f4" }}
                >
                  <div className="banner-content row align-items-center">
                    <div className="col-xl-5 col-lg-6">
                      <div className="icon-box icon-box-side mb-4 mb-lg-0 appear-animate-visible">
                        <div className="icon-box-icon text-primary">
                          <i className="far fa-envelope"></i>
                        </div>
                        <div className="icon-box-content">
                          <h4 className="icon-box-title">
                            Get Special Offers and Savings
                          </h4>
                          <p className="text-dark">
                            Get all the latest information on Events, Sales and
                            Offers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 d-lg-block d-flex justify-content-center">
                      <form
                        onSubmit={this.handleSubmit}
                        action="#"
                        method="get"
                        className="input-wrapper input-wrapper-round input-wrapper-inline ml-lg-auto appear-animate-visible"
                        data-animation-options="{'name': 'fadeInRightShorter'}"
                      >
                        <input
                          type="email"
                          className="form-control font-primary font-italic form-solid"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          id="email"
                          placeholder="Enter Your E-mail Address..."
                          required
                        />
                        <button className="btn btn-dark" type="submit">
                          subscribe
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default SpecialOfferComponent;
