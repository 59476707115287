import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

// Templates


import AppTemplate from '../components/templates/AppTemplate';
// import ShopTemplate from "../components/templates/ShopTemplate";
// import BrandTemplate from "../components/templates/BrandTemplate";
// import AccountTemplate from "../components/templates/AccountTemplate";
// import OrderTemplate from '../components/templates/OrderTemplate';
// import CheckoutTemplate from '../components/templates/CheckoutTemplate';
// import HeaderTopComponent from '../components/UI/HeaderTop';


// Routes
// import Home from '../components/Home';
import Home from '../containers/home/HomeContainer';
import HomeComponent from '../components/homepage/HomeComponent';
// import Offer from '../containers/homepage/OfferContainer'
// import NewArrival from '../containers/homepage/NewArrivalContainer';
// import NewArrivalComponent from '../components/homepage/NewArrivalComponent';
// import MidSeasonSale from '../containers/homepage/MidSeasonSaleContainer';
// import MidSeasonSaleComponent from '../components/homepage/MidSeasonSaleComponent';
// import OnSaleProduct from '../containers/homepage/OnSaleProductContainer';
// import OnSaleProductComponent from '../components/homepage/OnSaleProductComponent';
// import BundleProduct from '../containers/homepage/BundleProductContainer';
// import BundleProductComponent from '../components/homepage/BundleProductComponent';
// import CategoryProduct from '../containers/homepage/CategoryProductContainer';
// import CategoryProductComponent from '../components/homepage/CategoryProductComponent';
// import Product from '../containers/homepage/ProductContainer';
// import ProductComponent from '../components/homepage/ProductComponent';
// import SpecialOffer from '../containers/homepage/SpecialOfferContainer';
// import SpecialOfferComponent from '../components/homepage/SpecialOfferComponent';
// import LatestBlog from '../containers/homepage/LatestBlogContainer';
// import LatestBlogComponent from '../components/homepage/LatestBlogComponent';
// import OurClient from '../containers/homepage/OurClientContainer';
// import OurClientComponent from '../components/homepage/OurClientComponent';
// import Shop from '../containers/shop/ShopContainer';
// import ShopComponent from '../components/shop/ShopComponent';
import Brands from '../containers/brand/BrandsContainer';
import BrandsComponent from '../components/brand/BrandsComponent';
import Account from '../containers/account/AccountContainer';
import AccountComponent from '../components/account/AccountComponent';
import Order from '../containers/order/OrderContainer';
import OrderComponent from '../components/order/OrderComponent';
import Cart from '../containers/cart/CartContainer';
import CartComponent from '../components/cart/CartComponent';
import CartTemplate from '../components/templates/OrderTemplate';
import Checkout from '../containers/checkout/CheckoutContainer';
import CheckoutComponent from '../components/checkout/CheckoutComponent'
// import AboutUsTemplate from '../components/templates/AboutUsTemplate';
import AboutUs from '../containers/aboutus/AboutUsContainer';
import AboutUsComponent from '../components/aboutus/AboutUsComponent';
// import BlogTemplate from '../components/templates/BlogTemplate';
// import Blog from '../containers/blog/BlogContainer';
// import BlogComponent from '../components/blog/BlogComponent';

// import BlogSingle from '../containers/blog/BlogSingleContainer';
// import BlogSingleComponent from '../components/blog/BlogSingleComponent';
// import CategoryTemplate from '../components/templates/CategoryTemplate';
// import Category from '../containers/category/CategoryContainer';
// import CategoryComponent from '../components/category/CategoryComponent';
import ComingSoon from '../ComingSoon';
// import ContactUsTemplate from '../components/templates/ContactUsTemplate';
import ContactUs from '../containers/contactus/ContactUsContainer';
import ContactUsComponent from '../components/contactus/ContactUsComponent';
import Error404Component from '../Error404';
// import CommonTemplate from '../components/templates/CommonTemplate';
import PageDesignComponent from '../PageDesign';
import ProductRightSidebar from '../containers/productrightsidebar/ProductRightSidebarContainer';
import ProductRightSidebarComponent from '../components/productrightsidebar/ProductRightSidebarComponent';
import SearchPage from '../containers/search/SearchPageContainer';
import SearchPageComponent from '../components/search/SearchPageComponent';
import SellerPageContainer from '../containers/seller/SellerPageContainer';
import SellerPageComponent from '../components/seller/SellerPageComponent';
import WishList from '../containers/wishlist/WishListContainer';
import WishListComponent from '../components/wishlist/WishListComponent';

// import OnSale from '../containers/onsale/OnSaleContainer';
// import OnSaleComponent from '../components/onsale/OnSaleComponent';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword.js'
// import BrandProductContainer from '../containers/brand/BrandProductContainer';
// import BrandProductComponent from '../components/brand/BrandProductComponent';
import BundleProductDetailsComponent from '../components/bundleproduct/BundleProductDetailsComponent';
import BundleProductDetailsContainer from '../containers/bundleproduct/BundleProductDetailsContainer';
// import QuickViewContainer from '../containers/productrightsidebar/QuickViewContainer';
// import QuickViewComponent from '../components/productrightsidebar/QuickViewComponent';
import ForgotPasswordConfirm from '../ForgotPasswordConfirm';
// import CampaignDetailsContainer from '../containers/campaign/CampaignDetailsContainer';
// import CampaignDetailsComponent from '../components/campaign/CampaignDetailsComponent';
// import CampaignPage from '../containers/campaign/CampaignPageContainer';
// import CampaignPageComponent from '../components/campaign/CampaignPageComponent';
import TermsConditionContainer from '../containers/policy/TermsConditionContainer';
import TermsConditionComponent from '../components/policy/TermsCondition';
import ShippingPolicyContainer from '../containers/policy/ShippingPolicyContainer';
import ShippingPolicyComponent from '../components/policy/ShippingPolicy';
import PrivacyPolicyContainer from '../containers/policy/PrivacyPolicyContainer';
import PrivacyPolicyComponent from '../components/policy/PrivacyPolicy';
import ReturnRefundPolicyContainer from '../containers/policy/ReturnRefundPolicyContainer';
import ReturnRefundPolicyComponent from '../components/policy/ReturnRefundPolicy';
import FaqContainer from '../containers/policy/FaqContainer';
import FaqComponent from '../components/policy/Faq';
import SupportContainer from '../containers/policy/SupportContainer';
import SupportTicketComponent from '../components/policy/Support';
import OrderDetails from "../components/order/OrderDetails";
import OrderInvoiceDetails from "../components/order/OrderInvoiceDetails";
import PreOrderInvoiceDetails from "../components/order/PreOrderInvoiceDetails";
import BrandProductComponent from '../components/brand/BrandProductComponent';
import BrandProductContainer from '../containers/brand/BrandProductContainer';

// import CartComponent from '../components/cart/CartComponent';
// import Checkout from '../containers/checkout/checkoutContainer';
// import CheckoutComponent from '../components/checkout/checkoutComponent';
// import Product from '../containers/product/productDetailsContainer';
// import ProductComponent from '../components/product/productDetailsComponent';
// import SingleProductComponent from '../components/product/productDetail';
// import SingleProduct from '../containers/product/productDetails';

const Index = () => (
    <Switch>
        <Route
            exact
            path="/login"
            render={props => (
                <AppTemplate pageTitle="Hola">
                    <Login/>
                </AppTemplate>
            )}
        />
        <Route
            exact
            path="/forgot-password"
            render={props => (
                <AppTemplate pageTitle="Hola">
                    <ForgotPassword/>
                </AppTemplate>
            )}
        />
        <Route
            exact
            path="/password/reset/confirm/:uid/:token"
            render={props => (
                <ForgotPasswordConfirm/>

            )}
        />

        <Route
            exact
            path="/home"
            render={props => (
                <AppTemplate pageTitle="Hola">
                    <Home {...props} Layout={HomeComponent}/>
                </AppTemplate>

            )}
        />
        <Route
            exact
            path="/"
            render={props => (
                <Redirect to="/home">

                </Redirect>
            )}
        />
        {/* <Route

            path="/all-shop"
            render={props => (
                <AppTemplate pageTitle="Shop">
                    <Shop {...props} Layout={ShopComponent}/>
                </AppTemplate>
            )}
        />
        <Route

            path="/all-shop/test"
            render={props => (

                <HeaderTopComponent/>

            )}
        /> */}
        <Route
            exact
            path="/all-brand"
            render={props => (
                <AppTemplate pageTitle="Brand">
                    <Brands {...props} Layout={BrandsComponent}/>

                </AppTemplate>
            )}
        />
        {/* <Route
            exact
            path="/brand"
            render={props => (
                <AppTemplate pageTitle="Brand">
                    <BrandProductContainer {...props} Layout={BrandProductComponent}/>
                </AppTemplate>
            )}
        /> */}

        <Route
            exact
            path="/account"
            render={props => (
                <AppTemplate pageTitle="Account">
                    <Account {...props} Layout={AccountComponent}/>
                </AppTemplate>
            )}
        />

        <Route
            exact
            path="/orders"
            render={props => (
                <AppTemplate pageTitle="Account Order">
                    <Account {...props} Layout={OrderDetails}/>
                </AppTemplate>
            )}
        />

        <Route
            exact
            path="/orders-invoices"
            render={props => (
                <AppTemplate pageTitle="Account Order Invoice">
                    <Account {...props} Layout={OrderInvoiceDetails}/>
                </AppTemplate>
            )}
        />
         <Route
            exact
            path="/pre-orders-invoices/:id"
            render={props => (
                <AppTemplate pageTitle="Account Pre Order Invoice">
                    <Account {...props} Layout={PreOrderInvoiceDetails}/>
                </AppTemplate>
            )}
        />

        <Route
            exact
            path="/order"
            render={
                props => (
                    <AppTemplate>
                        <Order {...props} Layout={OrderComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/cart"
            render={
                props => (
                    <AppTemplate>

                        <Cart {...props} Layout={CartComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/checkout"
            render={
                props => (
                    <AppTemplate>
                        <Checkout {...props} Layout={CheckoutComponent}/>
                    </AppTemplate>
                )
            }/>
        <Route
            exact
            path="/about-us"
            render={
                props => (
                    <AppTemplate>
                        <AboutUs {...props} Layout={AboutUsComponent}/>
                    </AppTemplate>
                )
            }
        />


        {/* <Route
            exact
            path="/blog"
            render={
                props => (
                    <AppTemplate>
                        <Blog {...props} Layout={BlogComponent}/>
                    </AppTemplate>
                )
            }
        />
        <Route
            exact
            path="/blog-single"
            render={
                props => (
                    <AppTemplate>
                        <BlogSingle {...props} Layout={BlogSingleComponent}/>
                    </AppTemplate>
                )
            }
        />
        <Route
            exact
            path="/campaign"
            render={
                props => (
                    <AppTemplate>

                        <CampaignPage {...props} Layout={CampaignPageComponent}/>
                    </AppTemplate>

                )
            }
        /> */}
        {/* <Route
      exact
      path="/test/:id"
      render={
        props => (
          <AppTemplate>

            <CampaignPage {...props} Layout={CampaignPageComponent} />
          </AppTemplate>

        )
      }
    /> */}
        {/* <Route
            exact
            path="/category"
            render={
                props => (
                    <AppTemplate>
                        <Category {...props} Layout={CategoryComponent}/>
                    </AppTemplate>
                )
            }
        /> */}
        <Route
            exact
            path="/coming-soon"
            render={
                props => (
                    <ComingSoon/>
                )
            }
        />
        <Route
            exact
            path="/contact-us"
            render={
                props => (
                    <AppTemplate>
                        <ContactUs {...props} Layout={ContactUsComponent}/>
                    </AppTemplate>
                )
            }
        />


        <Route
            exact
            path="/page-design"
            render={
                props => (
                    <PageDesignComponent/>
                )
            }
        />
        <Route
            exact
            path='/product-details'
            render={
                props => (
                    <AppTemplate>
                        <ProductRightSidebar {...props} Layout={ProductRightSidebarComponent}/>
                    </AppTemplate>
                )
            }
        />
        <Route
            exact
            path='/brand'
            render={
                props => (
                    <AppTemplate>
                        <BrandProductContainer {...props} Layout={BrandProductComponent}/>
                    </AppTemplate>
                )
            }
        />
        <Route
            exact
            path='/bundle-product-details'
            render={
                props => (
                    <AppTemplate>

                        <BundleProductDetailsContainer {...props} Layout={BundleProductDetailsComponent}/>
                    </AppTemplate>
                )
            }
        />
        {/* <Route
            exact
            path='/quick-view'
            render={props => (
                <QuickViewContainer {...props} Layout={QuickViewComponent}/>

            )}
        /> */}
        <Route
            exact
            path='/search-results'
            render={
                props => (
                    // <CommonTemplate>
                    <SearchPage {...props} Layout={SearchPageComponent}/>
                    // </CommonTemplate>
                )
            }
        />
        <Route
            exact
            path='/seller'
            render={
                props => (
                    <AppTemplate>
                        <SellerPageContainer {...props} Layout={SellerPageComponent}/>
                    </AppTemplate>
                )
            }
        />
        <Route
            exact
            path="/wishlist"
            render={
                props => (
                    <AppTemplate>
                        <WishList {...props} Layout={WishListComponent}/>
                    </AppTemplate>
                )
            }
        />

        {/* <Route
            exact
            path="/on-sale"
            render={
                props => (
                    <AppTemplate>
                        <OnSale {...props} Layout={OnSaleComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/campaign-details"
            render={
                props => (
                    <AppTemplate>
                        <CampaignDetailsContainer {...props} Layout={CampaignDetailsComponent}/>
                    </AppTemplate>
                )
            }
        /> */}

        <Route
            exact
            path="/terms-condition"
            render={
                props => (
                    <AppTemplate>
                        <TermsConditionContainer {...props} Layout={TermsConditionComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/shipping-policy"
            render={
                props => (
                    <AppTemplate>
                        <ShippingPolicyContainer {...props} Layout={ShippingPolicyComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/privacy-policy"
            render={
                props => (
                    <AppTemplate>
                        <PrivacyPolicyContainer {...props} Layout={PrivacyPolicyComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/return-refund-policy"
            render={
                props => (
                    <AppTemplate>
                        <ReturnRefundPolicyContainer {...props} Layout={ReturnRefundPolicyComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/faq"
            render={
                props => (
                    <AppTemplate>
                        <FaqContainer {...props} Layout={FaqComponent}/>
                    </AppTemplate>
                )
            }
        />

        <Route
            exact
            path="/support"
            render={
                props => (
                    <AppTemplate>
                        <SupportContainer {...props} Layout={SupportTicketComponent}/>
                    </AppTemplate>
                )
            }
        />


        <AppTemplate>
            <Route component={Error404Component}/>
        </AppTemplate>

    </Switch>
);

export default Index;
