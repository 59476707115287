import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BASE_URL, USER_STAT_URL,ABOUT_US_URL, CUSTOMER_URL } from '../../constants/api'
import axios from 'axios';
const AboutUsComponent = (props) => {
    const [data, setData] = useState([]);
    const [aboutus,setAboutus] = useState([])

    // alert(JSON.stringify(data))

    useEffect(() => {
        
        var token = localStorage.getItem('token', '');
        // var token = "aldjak";

       
        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            // alert(JSON.stringify(token))
            headers["Authorization"] = `Token ${token}`;
        }

        const fetchData = async () => {
            const result = await axios(
                USER_STAT_URL,{headers,"method":"GET"}
            );

            setData(result.data)
        };
        fetchData()
        fetchAboutUs(ABOUT_US_URL)
    }, [])

    const fetchAboutUs = async (url) => {
        const result = await axios(
            url,
        );
        setAboutus(aboutus => [...aboutus, ...result.data.results]);
        // setFeatureNext(result.data.next);
    };


    return (
        <div>
            < main className="main" >
                <div className="page-header" style={{ backgroundImage: `url(${'static/images/page-header.jpg'})` }}>
                    <h1 className="page-title">About Us</h1>
                </div>
                <div className="page-content mt-10 pt-7">


                    <section className="about-section">
                        <div className="container">
                            <h2 className="title mb-lg-9">About Us</h2>

                            <div className="row mb-10">
                                <div className="col-md-6">
                                    <img className="w-100 mb-4 appear-animate-visible"
                                        data-animation-options="{'name':'fadeInLeftShorter'}"
                                        src="static/images/subpages/about.jpg" alt="Donald Store" width="587" height="517"
                                        style={{ position: "sticky", top: "2rem" }} />
                                </div>
                                <div className="col-md-6 order-md-first pt-md-5">
                                {aboutus.map(about=>(
                                   
                                            <p>{Object.keys(about).length>0 ? <div dangerouslySetInnerHTML={{__html: about.about_us}} /> : ''}</p>
                                    
                                    // <p className="text-uppercase text-grey mb-0">Who we are</p>
                                    // <h5 className="lh-1 ls-m">Has long and glorious history</h5>
                                    // <p className="font-primary font-weight-semi-bold">Lorem ipsum dolor sit amet, consectetur
                                    // adipiscing elit. Morbi ut tellus ante. Nam suscipit urna risus, fermentum commodo
                                    // ipsum porta id.</p>
                                    // <p className="mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet
                                    // libero id nisi euismod, sed porta est consectetur. Vestibulum auctor felis eget orci
                                    // semper vestibulum. Pellentesque ultricies nibh gravida, accumsan libero luctus,
                                    // molestie nunc.</p>
                                        ))}
                                    {/* {data.total_seller} */}
                                    {/* {Object.keys((data) => ( */}
                                        <br/><br/><br/><br/>
                                    <div className="row pl-xl-6 pr-xl-8 mb-6 m-10">
                                        <div className="counter text-primary text-center col-sm-4 col-md-6 col-lg-4">
                                            <span className="count-to font-weight-bold" style={{fontSize: "25px"}} data-to={data.total_seller} data-refresh-interval="50">{data.total_seller ? data.total_seller : 0}</span>
                                            <h3 className="count-title">Sellers</h3>
                                        </div>
                                        <div className="counter text-primary text-center col-sm-4 col-md-6 col-lg-4">
                                            <span className="count-to font-weight-bold" data-to="50" style={{fontSize: "25px"}} data-refresh-interval="50">{data.total_brand ? data.total_brand : 0}</span>
                                            <h3 className="count-title">Brands</h3>
                                        </div>
                                        <div className="counter text-primary text-center col-sm-4 col-md-12 col-lg-4">
                                            <span className="count-to font-weight-bold" data-to="130" style={{fontSize: "25px"}} data-refresh-interval="50">{data.total_product ? data.total_product : 0}</span>
                                            <h3 className="count-title">Products</h3>
                                        </div>
                                    </div>
                                    {/* ))} */}
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    {/* <!-- End About Section--> */}

                    {/* <section className="reviews-section grey-section pt-10 pb-10">
                        <div className="container appear-animate" data-animation-options="{'name':'fadeInRightShorter'}">
                            <h2 className="title mt-2">Customers Reviews</h2>
                            <div className="owl-carousel owl-shadow-carousel owl-same-height owl-theme row cols-lg-3 cols-sm-2 cols-1 pb-4"
                                data-owl-options="{
                            'items': 3,
                            'nav': false,
                            'dots': true,
                            'loop': false,
                            'margin': 20,
                            'responsive': {
                                '0': {
                                    'items': 1
                                },
                                '576': {
                                    'items': 2
                                },
                                '992': {
                                    'items': 3,
                                    'dots': false
                                }
                            }
                        }">
                                <div className="testimonial testimonial-centered bg-white">
                                    <div className="testimonial-info">
                                        <h3 className="testimonial-title">Highly Recommend</h3>
                                        <figure className="testimonial-author-thumbnail">
                                            <img src="static/images/subpages/reviewer-1.png" alt="user" width="70" height="70" />
                                        </figure>
                                        <blockquote>“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                                        hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque ”</blockquote>
                                        <cite>
                                            Herman Beck
                                        <span>Customer</span>
                                        </cite>
                                    </div>
                                </div>
                                <div className="testimonial testimonial-centered bg-white">
                                    <div className="testimonial-info">
                                        <h3 className="testimonial-title">Very Helpful</h3>
                                        <figure className="testimonial-author-thumbnail">
                                            <img src="static/images/subpages/reviewer-2.png" alt="user" width="70" height="70" />
                                        </figure>
                                        <blockquote>“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                                        hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque ”</blockquote>
                                        <cite>
                                            Mary Adams
                                        <span>Customer</span>
                                        </cite>
                                    </div>
                                </div>
                                <div className="testimonial testimonial-centered bg-white">
                                    <div className="testimonial-info">
                                        <h3 className="testimonial-title">Great theme and Support</h3>
                                        <figure className="testimonial-author-thumbnail">
                                            <img src="static/images/subpages/reviewer-3.png" alt="user" width="70" height="70" />
                                        </figure>
                                        <blockquote>“ Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                                        hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque ”</blockquote>
                                        <cite>
                                            Owen Hunt
                                        <span>Customer</span>
                                        </cite>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            </main >
        </div>
    )
}

export default AboutUsComponent
