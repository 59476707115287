import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {addToCart, addItemToWishList} from '../../actions/cartActions'

class CategoryContainer extends Component {
    constructor(props) {
        super(props)
        this.addItemToCart = this.addItemToCart.bind(this);
        this.addItemToWishList = this.addItemToWishList.bind(this);
    }

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }

    addItemToCart(data) {
        this.props.addToCart(data)
    }

    addItemToWishList(data) {
        this.props.addItemToWishList(data)
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        var id = query.get('id')
        console.log(id)
    }

    render = () => {
        const {Layout, location} = this.props;
        console.log(location)
        return (
            <div>
                <Layout
                    addItemToCart={this.addItemToCart}
                    query={this.props.location.search}
                    addItemToWishList={this.addItemToWishList}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        addToCart: (data) => {
            dispatch(addToCart(data))
        },
        addItemToWishList: (data) => {
            dispatch(addItemToWishList(data))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CategoryContainer))

