import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToCart,addItemToWishList } from '../../actions/cartActions'
import { PRODUCT_URL } from '../../constants/api'



class BrandProductContainer extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            shop_info:'',
            products:[],
            total_showing:0,
            count:0
        }
        this.addItemToCart = this.addItemToCart.bind(this);
        this.addItemToWishList = this.addItemToWishList.bind(this);
        
    
        // this.addItemToCart=this.addItemToCart.bind(this)
      }

      componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var brand_id = query.get('brand__id')
        this.fetchBrandRelatedproduct(PRODUCT_URL+"&brand="+brand_id)
      
    }

    fetchBrandRelatedproduct = (url) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          
        };
       
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(response => {
                
                this.setState({products:response.results,count:response.count,total_showing:response.results.length})
              
            });
    }
    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }
    addItemToCart (data)  {    
          this.props.addToCart(data)
    }
    addItemToWishList (data)  {
          this.props.addItemToWishList(data)
    
    }


    render = () => {
        const { Layout } = this.props;

        // const { error } = this.state;

        return (
            <div>
                <Layout
                state={this.state}
                addItemToCart={this.addItemToCart} 
                addItemToWishList={this.addItemToWishList}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        addToCart: (data)=>{dispatch(addToCart(data))},
        addItemToWishList: (data)=>{dispatch(addItemToWishList(data))},
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandProductContainer)

