import React, { Component } from "react";
import moment from "moment";
import {
  CUSTOMER_ORDERS_URL,
} from "../../constants/api";
import { Link, withRouter } from 'react-router-dom';
class OrderDetails extends Component {


  state = {
    orderDetails: ''
  }

  componentDidMount() {
    // let id = this.props.match.params.id
    // let id = this.props.match !==undefined && this.props.match !==null ?this.props.match.params.id:''
    const query = new URLSearchParams(this.props.location.search);
    var id = query.get('path')
    this.orderDetails(id)
  }

  orderDetails = (id) => {
    var token = localStorage.getItem("token", "");
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    fetch(CUSTOMER_ORDERS_URL + id, {method: "GET", headers})
      .then((res) => res.json())
      .then((res) => {
        //  alert(JSON.stringify(res))

        this.setState({
          orderDetails: res,
        });
      });
  };
  render() {
    const {orderDetails} = this.state
    return (
      <div>
        <main className="main account">
          <div
            className="page-header"
            style={{
              backgroundImage: `url(${"static/images/page-header.jpg"})`,
            }}
          >
            <h1 className="page-title">My Account</h1>
            <ul className="breadcrumb">
              <li>
                <i className="d-icon-home" />
              </li>
              <li>My Account</li>
            </ul>
          </div>
          <div className="page-content mt-10 mb-10">
            <div className="container pt-1">
              <div className="page-content-inner-order">
                <div className="row mb-10 mb-10">
                  <div className="col-4">
                    <Link to='/account'><b>Back</b></Link>
                  </div>
                  <div className="col-4 text-center">
                    <h3>Order Details</h3>
                    <b>#{orderDetails ? orderDetails.order_no : null}</b>
                      {orderDetails.delivery_date ?
                        <p>Delivered Date: {moment(orderDetails.delivery_date_time).format('Do MMMM YYYY')}</p>
                        : null
                      }
                    {/* <b>BD#001-001</b>
                    <p>Expected Delivery Date: 10-10-2021</p> */}
                  </div>
                  <div className="col-4"></div>
                </div>
                <div className="table-responsive">
                  <table className="shop-table cart-table mt-2 table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>Name</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr style={{ lineHeight: "50px", textAlign: "center" }}>
                        <td>#1</td>
                        <td>Sugar</td>
                        <td>70</td>
                        <td>1KG</td>
                        <td>70</td>
                      </tr>
                      <tr style={{ lineHeight: "50px", textAlign: "center" }}>
                        <td>#2</td>
                        <td>Tea Bag</td>
                        <td>1</td>
                        <td>1PACK</td>
                        <td>69</td>
                      </tr>
                      <tr style={{ lineHeight: "50px", textAlign: "center" }}>
                        <td>#3</td>
                        <td>Tea spoon</td>
                        <td>30</td>
                        <td>6 PIECES</td>
                        <td>180</td>
                      </tr>
                      <tr style={{ lineHeight: "30px", textAlign: "center" }}>
                        <td />
                        <td />
                        <td />
                        <td style={{ paddingTop: "30px" }}>
                          <b>Subtotal</b>
                        </td>
                        <td style={{ paddingTop: "30px" }}>319</td>
                      </tr>
                      <tr style={{ lineHeight: "30px", textAlign: "center" }}>
                        <td />
                        <td />
                        <td />
                        <td>
                          <b>Discount</b>
                        </td>
                        <td>-25</td>
                      </tr>
                      <tr style={{ lineHeight: "30px", textAlign: "center" }}>
                        <td />
                        <td />
                        <td />
                        <td>
                          <b>Delivery Charge</b>
                        </td>
                        <td>+25</td>
                      </tr>
                      <tr style={{ lineHeight: "30px", textAlign: "center" }}>
                        <td />
                        <td />
                        <td />
                        <td>
                          <b>Total</b>
                        </td>
                        <td>319</td>
                      </tr> */}
                      {orderDetails.order_product !== undefined && orderDetails.order_product.map((orderproduct, index) => (
                        <tr style={{lineHeight: "30px", textAlign: "center"}}>
                          <td>{index + 1}</td>
                          <td>
                            {orderproduct.product.product_name}
                          </td>
                          <td>৳ {orderproduct.price}</td>
                          <td>{orderproduct.quantity}</td>
                          <td>৳ {orderproduct.quantity * orderproduct.price}</td>
                        </tr>
                      ))}
                      {orderDetails.bundle_list !== undefined ?
                        <>
                          {orderDetails.bundle_list && orderDetails.bundle_list.map((orderproduct, ind) => (
                            <tr style={{lineHeight: "30px", textAlign: "center"}}>
                              <td>{orderDetails.order_product.length + ind}</td>
                              <td>
                                {orderproduct.title}
                              </td>
                              <td>৳ {orderproduct.total_price}</td>
                              <td>{orderproduct.quantity}</td>
                              <td>৳ {orderproduct.quantity * orderproduct.total_price}</td>
                            </tr>

                          ))}
                        </>
                        : null
                      }
                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{paddingTop: "30px"}}>
                          <b>Subtotal</b>
                        </td>
                        <td style={{paddingTop: "30px"}}>৳ {(orderDetails.total_order_price + orderDetails.total_due).toFixed(2)}</td>
                      </tr>
                      {
                        orderDetails.discount_amount>0?
                        <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Discount</b>
                        </td>
                        <td>(-) ৳ {orderDetails.discount_amount}</td>
                      </tr>
                      :null
                      }
                      {
                        orderDetails.total_due>0?
                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Due</b>
                        </td>
                        <td>(-) ৳ {orderDetails.total_due}</td>
                      </tr>
                      :null
                      }
                      
                      {
                        orderDetails.tax>0?
                        <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Tax</b>
                        </td>
                        <td>(+) ৳ {orderDetails.tax}</td>
                      </tr>
                      :null

                      }
                      
                      {
                        orderDetails.shipping_charge>0?
                        <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Delivery Charge</b>
                        </td>
                        <td>(+) ৳ {orderDetails.shipping_charge}</td>
                      </tr>
                      :null

                      }
                      
                      <tr style={{lineHeight: "30px", textAlign: "center"}}>
                        <td/>
                        <td/>
                        <td/>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>৳ {orderDetails.tax + orderDetails.total_order_price}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(OrderDetails);
