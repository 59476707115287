export const ADD_TO_CART = 'ADD_TO_CART';
export const ONCHANGE_ADD_TO_CART = 'ONCHANGE_ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const BUNDLE_ADD_TO_CART = 'BUNDLE_ADD_TO_CART';
export const BUNDLE_REMOVE_ITEM = 'BUNDLE_REMOVE_ITEM';
export const BUNDLE_SUB_QUANTITY = 'BUNDLE_SUB_QUANTITY';
export const BUNDLE_ADD_QUANTITY = 'BUNDLE_ADD_QUANTITY';
export const EMPTY_ITEM = 'EMPTY_ITEM';
export const ADD_SHIPPING = 'ADD_SHIPPING';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const ADD_TO_CART_FROM_WISHLIST = 'ADD_TO_CART_FROM_WISHLIST';


