import {

    ADD_TO_CART,ONCHANGE_ADD_TO_CART, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY, ADD_SHIPPING, EMPTY_ITEM, ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST,
    BUNDLE_ADD_QUANTITY, BUNDLE_REMOVE_ITEM, BUNDLE_SUB_QUANTITY, BUNDLE_ADD_TO_CART, ADD_TO_CART_FROM_WISHLIST

} from '../actions/action-types/cart-actions'

import {PRE_ORDER_ADVANCE_PRECHANTAGE} from '../../src/constants/api'

import {  toast } from 'react-toastify';

const inRange=(data,quantity=0)=>{
    var price=0
    var found=0
    for(let i=0;i<data.length;i++){
        if(data[i].minimum <= quantity &&  quantity <= data[i].maximum){
            price = data[i].price;
            found=1
            break;
        }
    }
    if(price===0 && data.length>0 && found===0 ){
        // alert(data.length)
        
        price= data[data.length-1].price
        // alert(price)
    }
    //  alert(data.quantity)
    // alert(price)
     return price
  } 

const totalAmountCalculate = (dataArray=[])=>{
    var totalPrice=0
    for (var i=0;i<dataArray.length;i++){
        // alert(JSON.stringify(dataArray[i].price_range))
        // console.log(JSON.stringify(dataArray[i].price_range))
        if(dataArray[i].is_whole_sell && (dataArray[i].purchase_quantity <= dataArray[i].quantity)){
    
            totalPrice +=  (parseFloat(dataArray[i].whole_sell_price))*dataArray[i].quantity

        }else if(dataArray[i].is_price_range){
            let price =inRange(dataArray[i].price_range,dataArray[i].quantity)
            totalPrice +=  (parseFloat(price))*dataArray[i].quantity
        }
        else{
            totalPrice +=  (parseFloat(dataArray[i].new_price))*dataArray[i].quantity

        }


        if(dataArray[i].is_pre_order){
            let advance=parseFloat(dataArray[i].new_price*dataArray[i].quantity)*(PRE_ORDER_ADVANCE_PRECHANTAGE/100)
            totalPrice =  totalPrice-advance
          
        }
    
    }
    return totalPrice
}


const totalQuantityCalculate = (dataArray=[])=>{
    var totalQuantity=0
    for (var i=0;i<dataArray.length;i++){
        // alert(JSON.stringify(dataArray[i].price_range))
        // console.log(JSON.stringify(dataArray[i].price_range))
        totalQuantity += dataArray[i].quantity
    
    }
    return totalQuantity
}

const initState = {
    // items:[],
    addedItems: [],
    wishlist: [],
    bundleItems: [],
    total: 0,
    ProductTotal:0,
    totalQuantity: 0,
    bundleTotal:0,
    product_weight:0,

}
const cartReducer = (state = initState, action) => {

    //INSIDE HOME COMPONENT
    if (action.type === ADD_TO_CART) {
    
        toast.success("Product has been added into cart")
        let addedItem = state.addedItems.find(item => item.id === action.payload.data.id)
        //check if the action id exists in the addedItems
       
        let existed_item = state.addedItems.find(item => action.payload.data.id === item.id)
        if (existed_item) {
            var  newTotal,totalWeight,weight=0,price=0;
           
            var totalQuantity = parseInt(state.totalQuantity) + 1
            if(action.payload.data.added_quantity){
                addedItem.quantity += parseInt(action.payload.data.added_quantity)
              
                //price =(price*parseInt(action.payload.data.added_quantity))
                totalQuantity = parseInt(state.totalQuantity) + parseInt(action.payload.data.added_quantity)

                
                // totalWeight = state.product_weight + (parseFloat(weight)*parseInt(action.payload.data.quantity))
               
                // if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                //     weight=action.payload.data.unit_amount
                // }
             
            }else{
                addedItem.quantity += 1
               // price =(price*action.payload.data.added_quantity)
            }
            if(action.payload.data.is_price_range){
                
                let price = inRange(action.payload.data.price_range, addedItem.quantity,action.payload.data.new_price)
              
                if(price>0){

                    action.payload.data.new_price=price
                }
              
            }

            if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                weight=action.payload.data.unit_amount
            }
            totalWeight = state.product_weight + parseFloat(weight)
            
           

            var price=totalAmountCalculate(state.addedItems)
            return {
                ...state,
                total: price +state.bundleTotal,
                ProductTotal:price,
                totalQuantity: totalQuantity,
                product_weight:totalWeight
            }
        }
        else {
            action.payload.data.quantity = 1;
            if (action.payload.data.added_quantity !==undefined && action.payload.data.added_quantity>1){
                action.payload.data.quantity = parseInt(action.payload.data.added_quantity);
            }
            if(action.payload.data.is_price_range){
               
                let price = inRange(action.payload.data.price_range,action.payload.data.quantity)
                // alert()
                
                if(price>0){
                    action.payload.data.new_price=price
                }
                // alert(price)
            }
            // alert(JSON.stringify(action.payload.data.is))
            let addedItem = action.payload.data
            if(addedItem.added_quantity){
                action.payload.data.quantity = parseInt(addedItem.added_quantity);
            }
        
            var  newTotal,totalWeight,weight = action.payload.data.unit_amount;
            if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                weight=action.payload.data.unit_amount
            }
            // if(inRange()
            totalWeight = state.product_weight + (parseFloat(weight)*parseInt(action.payload.data.quantity))
            // alert(action.payload.data.new_price)
            var data = [...state.addedItems, action.payload.data]
            newTotal=totalAmountCalculate(data)
            return {
                ...state,
                addedItems:data,
                total: newTotal+state.bundleTotal,
                ProductTotal:newTotal,
                totalQuantity: parseInt(state.totalQuantity) + action.payload.data.quantity,
                product_weight:  totalWeight,
            }

        }

    }if(action.type===ONCHANGE_ADD_TO_CART){
        let addedItem = state.addedItems.find(item => item.id === action.payload.data.id)
        //check if the action id exists in the addedItems
        let existed_item = state.addedItems.find(item => action.payload.data.id === item.id)
        
        if (existed_item) {
          
            var  newTotal,totalWeight,weight=0,price=0;
           
            var totalQuantity =  parseInt(action.payload.data.added_quantity)
            if (action.payload.data.added_quantity !==undefined && action.payload.data.added_quantity>1){
                action.payload.data.quantity = parseInt(action.payload.data.added_quantity);
            }
            if(action.payload.data.added_quantity){
                
                addedItem.quantity =  parseInt(action.payload.data.added_quantity);
              
                //price =(price*parseInt(action.payload.data.added_quantity))
                totalQuantity = parseInt(state.totalQuantity) + parseInt(action.payload.data.added_quantity)

                
                // totalWeight = state.product_weight + (parseFloat(weight)*parseInt(action.payload.data.quantity))
               
                // if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                //     weight=action.payload.data.unit_amount
                // }
             
            }else{
                addedItem.quantity = parseInt(action.payload.data.added_quantity)
               // price =(price*action.payload.data.added_quantity)
            }
            if(action.payload.data.is_price_range){
                let price = inRange(action.payload.data.price_range, addedItem.quantity,action.payload.data.new_price)
              
                if(price>0){

                    action.payload.data.new_price=price
                }
              
            }

            if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                weight=action.payload.data.unit_amount
            }
            totalWeight = state.product_weight + parseFloat(weight)
            var totalQuantity = totalQuantityCalculate(state.addedItems)
            var price=totalAmountCalculate(state.addedItems)
            return {
                ...state,
                total: price +state.bundleTotal,
                ProductTotal:price,
                totalQuantity: totalQuantity,
                product_weight:totalWeight
            }
        }
        else {
            // action.payload.data.quantity = 1;
            if (action.payload.data.added_quantity !==undefined && action.payload.data.added_quantity>1){
                action.payload.data.quantity = parseInt(action.payload.data.added_quantity);
            }
            if(action.payload.data.is_price_range){
               
                let price = inRange(action.payload.data.price_range,action.payload.data.quantity)
                // alert()
                
                if(price>0){
                    action.payload.data.new_price=price
                }
                // alert(price)
            }
            // alert(JSON.stringify(action.payload.data.is))
            let addedItem = action.payload.data
            if(addedItem.added_quantity){
                action.payload.data.quantity = addedItem.added_quantity;
            }
        
            var  newTotal,totalWeight,weight = action.payload.data.unit_amount;
            if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                weight=action.payload.data.unit_amount
            }
            // if(inRange()
            totalWeight = state.product_weight + (parseFloat(weight)*parseInt(action.payload.data.quantity))
            // alert(action.payload.data.new_price)
            var data = [...state.addedItems, action.payload.data]
            newTotal=totalAmountCalculate(data)
            return {
                ...state,
                addedItems:data,
                total: newTotal+state.bundleTotal,
                ProductTotal:newTotal,
                totalQuantity: parseInt(action.payload.data.added_quantity),
                product_weight:  totalWeight,
            }

        }

    }if (action.type === ADD_TO_CART_FROM_WISHLIST) {
        toast.success("Product has been added into cart")

        let addedItem = state.addedItems.find(item => item.id === action.payload.data.id)
        let new_items = state.wishlist.filter(item => action.payload.data.id !== item.id)
        //check if the action id exists in the addedItems
        let existed_item = state.addedItems.find(item => action.payload.data.id === item.id)
        if (existed_item) {
            addedItem.quantity += 1

            // let totalQuantity = parseInt(state.totalQuantity) + 1
           var totalWeight=0
            if(action.payload.data.unit_amount !== null && action.payload.data.unit_amount !== undefined){
                weight=action.payload.data.unit_amount
            }
            totalWeight = state.product_weight + parseFloat(weight)
            
            if(action.payload.data.is_price_range){
                let price = inRange(action.payload.data.price_range,addedItem.quantity,action.payload.data.new_price)
                if(price>0){
                    action.payload.data.new_price=price
                }
            }
          

            var price=totalAmountCalculate(state.addedItems)
            return {
                ...state,
                // total: state.total + parseFloat(addedItem.new_price),
                total: price +state.bundleTotal,
                ProductTotal:price,
                product_weight:totalWeight,
                wishlist: new_items,
                totalQuantity: parseInt(state.totalQuantity) + 1
            }
        }
        else {
            action.payload.data.quantity = 1;


            let addedItem = action.payload.data
           
            if(action.payload.data.is_price_range){
                let price = inRange(action.payload.data.price_range, action.payload.data.quantity,action.payload.data.new_price)
                if(price>0){
                    action.payload.data.new_price=price
                }
            }

            let newTotal = state.total + parseFloat(addedItem.new_price)

            return {
                ...state,
                addedItems: [...state.addedItems, action.payload.data],
                total: newTotal,
                wishlist: new_items,
                totalQuantity: parseInt(state.totalQuantity) + action.payload.data.quantity
            }

        }

    } if (action.type === ADD_TO_WISHLIST) {
        toast.success("Product has been added into wishlist")

        let existed_item = state.wishlist.find(item => action.payload.data.id === item.id)
        if (!existed_item) {
            return {
                ...state,
                wishlist: [...state.wishlist, action.payload.data],
            }
        }


    } if (action.type === REMOVE_FROM_WISHLIST) {
        let new_items = state.wishlist.filter(item => action.payload.data.id !== item.id)

        return {
            ...state,
            wishlist: new_items,
        }


    }
    if (action.type === BUNDLE_ADD_TO_CART) {
        toast.success("Product has been added into cart")

      
        let addedItem = state.bundleItems.find(item => item.id === action.payload.data.id)
      
        //check if the action id exists in the addedItems
        let existed_item = state.bundleItems.find(item => action.payload.data.id === item.id)
        if (existed_item) {
            addedItem.quantity += 1
            let totalQuantity = parseInt(state.totalQuantity) + 1
            let total =state.bundleTotal+parseFloat(addedItem.total_price)
            return {
                ...state,
                total: state.ProductTotal + total,
                bundleTotal:total,
                totalQuantity: parseInt(state.totalQuantity) + 1
            }
        }
        else {
            action.payload.data.quantity = 1;

            //calculating the total
            let newTotal = state.bundleTotal + parseFloat(action.payload.data.total_price)
            return {
                ...state,
                bundleItems: [...state.bundleItems, action.payload.data],
                total: state.ProductTotal+newTotal,
                bundleTotal:state.bundleTotal+newTotal,
                totalQuantity: parseInt(state.totalQuantity) + action.payload.data.quantity
            }

        }

    }
    if (action.type === BUNDLE_REMOVE_ITEM) {
        let itemToRemove = state.bundleItems.find(item => action.payload.data.id === item.id)
        let new_items = state.bundleItems.filter(item => action.payload.data.id !== item.id)

        //calculating the total
        let newTotal = state.bundleTotal - (parseFloat(itemToRemove.total_price) * parseInt(itemToRemove.quantity))
        
        let totalQuantity = state.totalQuantity - parseInt(itemToRemove.quantity)
        // console.log(itemToRemove)
        return {
            ...state,
            bundleItems: new_items,
            total: state.ProductTotal + newTotal,
            bundleTotal:newTotal,
            totalQuantity: totalQuantity
        }
    }
    if (action.type === REMOVE_ITEM) {
        let itemToRemove = state.addedItems.find(item => action.payload.data.id === item.id)
        let new_items = state.addedItems.filter(item => action.payload.data.id !== item.id)


        //calculating the total
        let newTotal = totalAmountCalculate(new_items) 
        let totalQuantity = state.totalQuantity - parseInt(itemToRemove.quantity)
        var weight=0
        if(itemToRemove.unit_amount !== null && itemToRemove.unit_amount !== undefined){
            weight=itemToRemove.unit_amount
        }
        var totalWeight=state.product_weight-(parseFloat(weight)*parseInt(itemToRemove.quantity))
        return {
            ...state,
            addedItems: new_items,
            total:state.bundleTotal+newTotal,
            ProductTotal: newTotal,
            totalQuantity: totalQuantity,
            product_weight:totalWeight
        }
    }
    if (action.type === EMPTY_ITEM) {

        // console.log(itemToRemove)
        return {
            ...state,
            addedItems: [],
            bundleItems: [],
            total: 0,
            bundleTotal:0,
            totalQuantity: 0,
            ProductTotal:0,
            product_weight:0
        }
    }
    //INSIDE CART COMPONENT
    if (action.type === ADD_QUANTITY) {
        let addedItem = state.addedItems.find(item => item.id === action.payload.data.id)
        addedItem.quantity += 1
        if(action.payload.data.is_price_range){
            let price = inRange(action.payload.data.price_range, addedItem.quantity,action.payload.data.new_price)
            if(price>0){
                addedItem.new_price=price
            }
        }
        let newTotal = totalAmountCalculate(state.addedItems) 
        let totalQuantity = parseInt(state.totalQuantity) + 1
        var totalWeight=0
        totalWeight = state.product_weight + parseInt(addedItem.unit_amount)
        return {
            ...state,
            total: state.bundleTotal+newTotal,
            totalQuantity: totalQuantity,
            product_weight:totalWeight
        }
    }
    if (action.type === BUNDLE_ADD_QUANTITY) {
        let addedItem = state.bundleItems.find(item => item.id === action.payload.data.id)
        addedItem.quantity += 1
        let newTotal = state.bundleTotal + parseFloat(addedItem.total_price)
        let totalQuantity = parseInt(state.totalQuantity) + 1
        return {
            ...state,
            total: newTotal+state.ProductTotal,
            bundleTotal:newTotal,
            totalQuantity: totalQuantity
        }
    }
    if (action.type === BUNDLE_SUB_QUANTITY) {
        let addedItem = state.bundleItems.find(item => item.id === action.payload.data.id)
        //if the qt == 0 then it should be removed
        if (addedItem.quantity === 1) {
            let new_items = state.bundleItems.filter(item => item.id !== action.payload.data.id)
            let newTotal = state.bundleTotal - parseFloat(addedItem.total_price)
            let totalQuantity = parseInt(state.totalQuantity) - 1
            return {
                ...state,
                bundleItems: new_items,
                total: newTotal+state.ProductTotal,
                bundleTotal:newTotal,
                totalQuantity: totalQuantity,
            }
        }
        else {
            addedItem.quantity -= 1
            let newTotal = state.bundleTotal - parseFloat(addedItem.total_price)
            let totalQuantity = state.totalQuantity - 1
            return {
                ...state,
                total: newTotal+state.ProductTotal,
                bundleTotal:newTotal,
                totalQuantity: totalQuantity
            }
        }

    }
    if (action.type === SUB_QUANTITY) {
        let addedItem = state.addedItems.find(item => item.id === action.payload.data.id)
        //if the qt == 0 then it should be removed
        if (addedItem.quantity === 1) {
            let new_items = state.addedItems.filter(item => item.id !== action.payload.data.id)

            let newTotal = totalAmountCalculate(new_items)
            let totalQuantity = parseInt(state.totalQuantity) - 1
            var totalWeight=0
            totalWeight = state.product_weight - parseInt(addedItem.unit_amount)
            return {
                ...state,
                addedItems: new_items,
                total: newTotal+state.bundleTotal,
                ProductTotal:newTotal,
                totalQuantity: totalQuantity,
                product_weight:totalWeight

            }
        }
        else {
            addedItem.quantity -= 1
            var totalWeight=0
            totalWeight = state.product_weight - parseInt(addedItem.unit_amount)
            if(action.payload.data.is_price_range){
                let price = inRange(action.payload.data.price_range, addedItem.quantity,action.payload.data.new_price)
                if(price>0){
                    addedItem.new_price=price
                }
            }
            let newTotal = totalAmountCalculate(state.addedItems)
            let totalQuantity = state.totalQuantity - 1
            var totalWeight=0
            totalWeight = state.product_weight - parseInt(addedItem.unit_amount)
           
            return {
                ...state,
                total: newTotal+state.bundleTotal,
                ProductTotal:newTotal,
                totalQuantity: totalQuantity,
                product_weight:totalWeight

            }
        }

    }

    if (action.type === ADD_SHIPPING) {
        return {
            ...state,
            total: state.total + 6
        }
    }

    if (action.type === 'SUB_SHIPPING') {
        return {
            ...state,
            total: state.total - 6
        }
    }

    else {
        return state
    }

}

export default cartReducer