import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';



class SearchPageContainer extends Component {

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }


   



    render = () => {
        const { Layout,location } = this.props;
       

        return (
            <div>
                <Layout 
                    state={location.state}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {

    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPageContainer)

