import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeItemToWishList,addToCartFromWishList  } from '../../actions/cartActions'



class WishListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
          isLoading: false,
        }
        this.addToCartFromWishList=this.addToCartFromWishList.bind(this)
        this.removeItemToWishList=this.removeItemToWishList.bind(this)
        
      }
      
    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }

    async addToCartFromWishList(data){
        // const { addToast } = useToasts()
         this.props.addToCartFromWishList(data)
          // addToast('Saved Successfully', { appearance: 'success' })
         
    }

    async removeItemToWishList(data){
        this.props.removeItemToWishList(data)
    }


    render = () => {
        const { Layout,wishlist } = this.props;

        // const { error } = this.state;

        return (
            <div>
                <Layout 
                    wishlist={wishlist}
                    addToCartFromWishList={this.addToCartFromWishList}
                    removeItemToWishList={this.removeItemToWishList}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        wishlist: state.cart.wishlist,

    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        addToCartFromWishList: (data) => { dispatch(addToCartFromWishList(data)) },
        removeItemToWishList: (data) => { dispatch(removeItemToWishList(data)) },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WishListContainer)

