import React, { Component } from 'react'
import HeaderTop from '../UI/HeaderTop'
import Footer from '../UI/Footer'
import axios from 'axios';
import {  PRODUCT_SEARCH,NO_TOKEN_FEATURE_PRODUCT_URL } from '../../constants/api'
import LoadingBar from 'react-top-loading-bar'
import {RatingValueToPerchantage} from '../../constants/customMethod'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'


class SearchPageComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            products: [],
            found: 0,
            searchItem:'',
            bar:0,
            featured:[],
            showModal:false,
            modalData:'',
            paginatePageCount:0
           
        }
      
    }

    sortLimitOptions = [
        {value: '12', label: '12'},
        {value: '24', label: '24'},
        {value: '36', label: '36'},
      ]
    fetchData = async (url) => {
        // alert(url)
       const result = await axios(
           url,
       );

      
       const allParamsData = [];
       const para = 'page'
       // console.log(para)
       const allParams = para.split('&')
       allParams.forEach(eachItem => {
       allParamsData[eachItem.split('=')] = eachItem.split('=')
       })
       const x = Math.max((result.data.count / 12))
       // (allParamsData['size'] ?? sortLimitOptions[0].value)) - 1, 0
       this.state.paginatePageCount=x
   };

     makeCurrentRoute = (key, value) => {
        const params = 'page'
        const allParams = params.split('&')
        const allParamsData = [];
        allParams.forEach(eachItem => {
          allParamsData[eachItem.split('=')] = eachItem.split('=')
        })
        allParamsData[key] = value
        if (key === 'size') {
          allParamsData['page'] = 1;
        }
        let queryParams = '';
        // console.log(queryParams)
        for (const key in allParamsData) {
          queryParams += (key + "=" + allParamsData[key])
          queryParams += '&'
        }
        queryParams = queryParams.substring(0, queryParams.length - 1);
        // history.push('/category?' + queryParams)
        this.fetchData(PRODUCT_SEARCH + "?" + queryParams).then();
      }
 
    onchangeSearchText = (product_name)=> {
        alert(product_name)
        // this.setState({
        //     searchItem:product_name
        // })
        //this.searchProdcut(PRODUCT_SEARCH+"?search="+product_name)
    }

    searchProdcut = (url) => {
        this.setState({bar:0})
        axios.get(url)
       
        .then((response) => this.setState({ 
            products: response.data.results,
            found: response.data.count,
            bar:100
        }))
        .catch(error => {
            this.setState({ 
              
                bar:100
            })
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });

    }

    toggleModal = () =>{

        this.setState({showModal:false});

    }

    showDataModal = (data) =>{
        this.setState({showModal:true,modalData:data})
        

    }



    componentDidMount(){
        
        const { state } = this.props;
        var product_search=""
        if (state!==undefined){
            product_search=state.product.product_name
            if(state.product.category!==''&& state.product.category!==null){
            product_search=state.product.category
            }
        }
        this.searchProdcut(PRODUCT_SEARCH+"?search="+product_search)
        this.getFeatured()
        this.fetchData(PRODUCT_SEARCH)
    }
    
    getFeatured=()=>{
        
        fetch(NO_TOKEN_FEATURE_PRODUCT_URL)
        .then(res=>res.json())
        
        .then(featured=>{
            this.setState({featured:featured.results})
        })
    }



    render() {
        const { state, } = this.props;
        const {products,found,searchItem,featured,paginatePageCount}=this.state;
        return (
           
           
            <div>
                <LoadingBar color="#ed711b" progress={this.state.bar}  height={5}/>

            <HeaderTop state={state}
            onchangeSearchText={this.onchangeSearchText}
            />
                < main className="main container" >
               
                    <div className="page-header bg-dark"
                        style={{ backgroundImage: `url(${'static/images/shop/bgbanner.png'})`, backgroundColor: "#3C63A4" }}>
                        <h3 className="page-subtitle">{found} items found </h3>
                    </div>
                    {/* <!--End PageHeader-- > */}
                    <div className="page-content mb-10">
                        <div className="container">
                            <div className="row main-content-wrap gutter-lg">
                                <aside
                                    className="col-lg-3 sidebar sidebar-fixed sidebar-toggle-remain shop-sidebar sticky-sidebar-wrapper">
                                    {/* <div className="sidebar-overlay">
                                        <a className="sidebar-close" href="#"><i className="d-icon-times"></i></a>
                                    </div> */}
                                    {/* <div className="sidebar-content"> */}
                                        {/* <div className="sticky-sidebar" data-sticky-options="{'top': 10}">
                                            <div className="filter-actions">
                                                <a href="#"
                                                    className="sidebar-toggle-btn toggle-remain btn btn-sm btn-outline btn-primary">Filters<i
                                                        className="d-icon-arrow-left"></i></a>
                                                <a href="#" className="filter-clean text-primary">Clean All</a>
                                            </div> */}
                                            {/* <div className="widget widget-collapsible">
                                                <h3 className="widget-title">All Categories</h3>
                                                <ul className="widget-body filter-items search-ul">
                                                    <li><a href="#">Bags</a></li>
                                                    <li><a href="#">Sport Shorts</a></li>
                                                    <li className="with-ul show">
                                                        <a href="#">Clothing</a>
                                                        <ul style={{ display: "block" }}>
                                                            <li><a href="#">Summer sale</a></li>
                                                            <li><a href="#">Shirts</a></li>
                                                            <li><a href="#">Trunks</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><a href="#">Shoes</a></li>
                                                    <li className="with-ul">
                                                        <a href="#">Sweaters</a>
                                                        <ul>
                                                            <li><a href="#">T-Shirts</a></li>
                                                            <li><a href="#">Dress</a></li>
                                                            <li><a href="#">Blouse</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="with-ul">
                                                        <a href="#">Uncategorized</a>
                                                        <ul>
                                                            <li><a href="#">Trousers</a></li>
                                                            <li><a href="#">Jacket</a></li>
                                                            <li><a href="#">Caps</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="with-ul">
                                                        <a href="#">Women</a>
                                                        <ul>
                                                            <li><a href="#">Summer sales</a></li>
                                                            <li><a href="#">Shirts</a></li>
                                                            <li><a href="#">Trunks</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title">Price</h3>
                                                <div className="widget-body">
                                                    <form action="#">
                                                        <div className="filter-price-slider"></div>

                                                        <div className="filter-actions">
                                                            <button type="submit" className="btn btn-sm btn-primary">Filter</button>

                                                            <div className="filter-price-text">Price:
														<span className="filter-price-range"></span>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    
                                                </div>
                                            </div>
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title">Size</h3>
                                                <ul className="widget-body filter-items">
                                                    <li><a href="#">Small<span>(2)</span></a></li>
                                                    <li><a href="#">Medium<span>(1)</span></a></li>
                                                    <li><a href="#">Large<span>(9)</span></a></li>
                                                    <li><a href="#">Extra Large<span>(1)</span></a></li>
                                                </ul>
                                            </div>
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title">Color</h3>
                                                <ul className="widget-body filter-items">
                                                    <li><a href="#">Black<span>(2)</span></a></li>
                                                    <li><a href="#">Blue<span>(1)</span></a></li>
                                                    <li><a href="#">Green<span>(9)</span></a></li>
                                                </ul>
                                            </div>
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title">Brands</h3>
                                                <ul className="widget-body filter-items">
                                                    <li><a href="#">Black<span>(2)</span></a></li>
                                                    <li><a href="#">Blue<span>(1)</span></a></li>
                                                    <li><a href="#">Green<span>(9)</span></a></li>
                                                </ul>
                                            </div> */}
                                            {products.length>0?
                                            <div className="widget widget-products">
                                                <h4 className="widget-title">Featured Products</h4>
                                                <div className="widget-body">
                                                    <div className="owl-carousel owl-nav-top row cols-1" data-owl-options="{
												'items': 1,
												'loop': true,
												'nav': true,
												'dots': false,
												'margin': 0
												}">
                                                        <div className="products-col">
                                                            {featured.map((product, index) => (

                                                            <div className="product product-list-sm">
                                                                <figure className="product-media"
                                                                            style={{height: "auto"}}>
                                                                    <a href={'product-details?id='+product.id}>

                                                                        <img src={product.feature_image} alt="product"
                                                                             width="100" height="100"/>
                                                                    </a>
                                                                    </figure>
                                                                    <div className="product-details">
                                                                        <h3 className="product-name">
                                                                        <a href={'product-details?id='+product.id}>

                                                                            {product.product_name}
                                                                        </a>
                                                                        </h3>
                                                                        <div className="product-price">
                                                                            {
                                                                                product.discount_type === 1 || featured.discount_type === 2 ? (
                                                                                        <span
                                                                                            className="price">৳ {product.new_price}</span>
                                                                                    ) :
                                                                                    <span
                                                                                        className="new-price">৳ {product.regular_price}</span>
                                                                            }
                                                                        </div>
                                                                        <div className="ratings-container">
                                                                            <div className="ratings-full">
                                                                                    <span className="ratings"
                                                                                          style={{width: RatingValueToPerchantage(product.avg_rating) + '%'}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            ))}
                                                            
                                                        </div>
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            :null}
                                            
                                        {/* </div> */}
                                    {/* </div> */}
                                </aside>
                                <div className="col-lg-9 main-content">
                                    <nav className="toolbox sticky-toolbox sticky-content fix-top">
                                        <div className="toolbox-left">
                                            <a href="#"
                                                className="toolbox-item left-sidebar-toggle btn btn-sm btn-outline btn-primary d-lg-none">Filters<i
                                                    className="d-icon-arrow-right"></i></a>
                                            <div className="toolbox-item toolbox-sort select-box">
                                                <label>Sort By :</label>
                                                <select name="orderby" className="form-control">
                                                    <option value="default">Default</option>
                                                    <option value="popularity" selected="selected">Most Popular</option>
                                                    <option value="rating">Average rating</option>
                                                    <option value="date">Latest</option>
                                                    <option value="price-low">Sort forward price low</option>
                                                    <option value="price-high">Sort forward price high</option>
                                                    <option value="">Clear custom sort</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="toolbox-right">
                                            <div className="toolbox-item toolbox-show select-box">
                                                <label>Show :</label>
                                                <div style={{width: "100px"}}>
                                            <Select options={this.sortLimitOptions} defaultValue={this.sortLimitOptions[0]} onChange={e => this.makeCurrentRoute('size', e.value)}/>
                                        </div>
                                                {/* <select name="count" className="form-control">
                                                    <option value="12">12</option>
                                                    <option value="24">24</option>
                                                    <option value="36">36</option>
                                                </select> */}
                                            </div>
                                            <div className="toolbox-item toolbox-layout">
                                                <a href="#" className="d-icon-mode-list btn-layout"></a>
                                                <a href="#" className="d-icon-mode-grid btn-layout active"></a>
                                            </div>
                                        </div>
                                    </nav>
                                    <div className="row cols-2 cols-sm-3 cols-md-4 product-wrapper">
                                    {products.map((product, index) => (
                                        <>                                       
                                        <div className="product-wrap">
                                            <div className="product shadow-media">
                                                <figure className="product-media">
                                                    <a href={'product-details?id='+product.id}>
                                                        {product.feature_image !==null && product.feature_image!=="" && product.feature_image !==undefined ? 
                                                        <img src={product.feature_image} alt="product" width="280" height="315" />
                                                        : <img src='/static/images/no-image.png' alt="product" style={{width:280,height:180}}  />
                                    }
                                                    </a>
                                                    <div className="product-action-vertical">
                                                        <a href="#" className="btn-product-icon btn-cart" data-toggle="modal"
                                                            data-target="#addCartModal" title="Add to cart"><i
                                                                className="d-icon-bag"></i></a>
                                                    </div>
                                                    <div className="product-action">
                                                        <a style={{cursor:'pointer'}}    onClick={()=>this.showDataModal(product)} className="btn-product btn-quickview" title="Quick View">Quick
													View</a>
                                                    </div>
                                                </figure>
                                                <div className="search-product-details">
                                                    <a href="#" className="btn-wishlist" title="Add to wishlist"><i
                                                        className="d-icon-heart"></i></a>
                                                    {/* <div className="product-cat">
                                                        <a href="shop-grid-3col.html">categories</a>
                                                    </div> */}
                                                    <h3 className="product-name">
                                                        <a href={'product-details?id='+product.id}>{product.product_name}</a>
                                                    </h3>
                                                    <div className="product-price">
                                                        {/* <span className="price">BDT 35.00</span> */}
                                                        {product.discount_type === 1  || product.discount_type === 2 ? (
                                                    <ins className="new-price">BDT {product.new_price}</ins>

                                                ):                                                 
                                                    <ins className="new-price">BDT {product.new_price}</ins>
                                                    
                                                } 
                                                {
                                                product.discount_type === 1  || product.discount_type === 2 ? (
                                                    <del
                                                        className="old-price">BDT {product.regular_price}</del>
                                                ) : null

                                                }
                                                    </div>
                                                    <div className="ratings-container">
                                                        <div className="ratings-full">
                                                            <span className="ratings" style={{ width: RatingValueToPerchantage(product.avg_rating)+  "%" }}></span>
                                                            {/* <span className="tooltiptext tooltip-top"></span> */}
                                                        </div>
                                                        <a href="#" className="rating-reviews">( {product.total_reviews} reviews )</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        </>
                                    ))
                                }
                                        
                                        
                                        
                                        
                                    </div>
                                    <nav className="toolbox toolbox-pagination">
                                        <p className="show-info">Showing <span>{products.length} of {found}</span> Products</p>
                                        {/* <ul className="pagination">
                                            <li className="page-item disabled">
                                                <a className="page-link page-link-prev" href="#" aria-label="Previous" tabindex="-1"
                                                    aria-disabled="true">
                                                    <i className="d-icon-arrow-left"></i>Prev
										    </a>
                                            </li>
                                            <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item page-item-dots"><a className="page-link" href="#">6</a></li>
                                            <li className="page-item">
                                                <a className="page-link page-link-next" href="#" aria-label="Next">
                                                    Next<i className="d-icon-arrow-right"></i>
                                                </a>
                                            </li>
                                        </ul> */}
                                        {
                                paginatePageCount > 0 && <ReactPaginate
                                pageCount={paginatePageCount}
                                pageRangeDisplayed={2}
                                containerClassName="pagination"
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link-prev"
                                nextLinkClassName="page-link-prev"
                                previousLabel={"← Prev"}
                                nextLabel={"Next →"}
                                style={{cursor: 'pointer'}}
                                onPageChange={(value) => this.makeCurrentRoute('page', value.selected + 1)}
                                />
                            }
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Modal canShow={this.state.showModal} updateModalState={this.toggleModal}
             data={this.state.modalData}
            //  portalClassName="mymodal"
            //  style={customStyles}
             addItemToCart={()=>this.props.addItemToCart(this.state.modalData)}
             addItemToWishList={()=>this.props.addItemToWishList(this.state.modalData)}
             showOldPrice={false}
             /> */}
                </main >
                <Footer/>
            </div>
        )
    }
}
export default SearchPageComponent
