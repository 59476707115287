import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToCart,addItemToWishList,subtractQuantity,addQuantity,onChangeaddToCart } from '../../actions/cartActions'



class ProductRightSidebarContainer extends Component {
   

   
       
   

    constructor(props) {
        super(props)
        this.state = { 
         product_id:0
        }
        this.addItemToCart = this.addItemToCart.bind(this);
        this.addItemToWishList = this.addItemToWishList.bind(this);
        this.addQuantityToCart=this.addQuantityToCart.bind(this)
        this.subtractQuantityFromCart=this.subtractQuantityFromCart.bind(this)
        this.onChangeAddToCart=this.onChangeAddToCart.bind(this)
        
     
    
        // this.addItemToCart=this.addItemToCart.bind(this)
      }
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var id = query.get('id')
        this.setState({product_id:id})
    }

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }
    
    addItemToCart (data)  {
        this.props.addToCart(data)
    }
    addItemToWishList (data)  {    
      this.props.addItemToWishList(data)
    }
    onChangeAddToCart (data)  {    
        this.props.onChangeaddToCart(data)
    }
    
    async addQuantityToCart(data){
         this.props.addQuantity(data)       
    }     
    async subtractQuantityFromCart(data){
        this.props.subtractQuantity(data)
    }

    render = () => {
        const { Layout } = this.props;

        const query = new URLSearchParams(this.props.location.search);
        var id = query.get('id')

        return (
            <div>
                <Layout 
                    id={id}
                    onChangeaddToCart={this.onChangeAddToCart}
                    addItemToWishList={this.addItemToWishList}
                    addItemToCart={this.addItemToCart}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items:state.cart.items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (data)=>{dispatch(addToCart(data))},
        addItemToWishList: (data)=>{dispatch(addItemToWishList(data))},
        subtractQuantity: (data) => { dispatch(subtractQuantity(data)) },
        addQuantity: (data) => { dispatch(addQuantity(data)) },
        onChangeaddToCart: (data) => { dispatch(onChangeaddToCart(data)) },

        

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductRightSidebarContainer)

