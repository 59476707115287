import React, {Component} from 'react'
import {SUPPORT_TICKET_URL,SUPPORT_MESSAGES_URL} from '../../constants/api'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SupportTicketComponent extends Component {
    state = {
        subject: "",
        description: "",
        priority: "",
        order_id:"",
        name:"",
        email:"",
        image: null,
    }

    // handleSubmit = (evt) => {
    //     evt.preventDefault();
    //     const token = localStorage.getItem('token');
    //     if (token !== null && token !== undefined && token !== '') {
    //         const data = {
    //             'subject': this.state.subject,
    //             'description': this.state.description,
    //             'priority': this.state.priority,
    //             'user': this.props.user
    //         };
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
    //             body: JSON.stringify(
    //                 data
    //             )
    //         };
    //         fetch(SUPPORT_TICKET_URL, requestOptions)
    //             .then(res => res.json())
    //             .then(result => {
    //                 if (result.subject !== null) {
    //                     toast.success("Your has been reviewed successfully !!!")
    //                 } else {
    //                     toast.warn("Something wrong here.please try again!!!")
    //                 }
    //             });
    //     }
    // }

    handleSubmit = (evt) => {
        evt.preventDefault();
        const token = localStorage.getItem('token');
        if (token !== null && token !== undefined && token !== '') {
            const data = {
                'name': this.state.name,
                'email': this.state.email,
                'priority': this.state.priority,
                'order_id': this.state.order_id,
                'description':this.state.description
            };
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
                body: JSON.stringify(
                    data
                )
            };
            fetch(SUPPORT_MESSAGES_URL, requestOptions)
                .then(res => res.json())
                .then(result => {
                    alert(JSON.stringify(result))
                    if (result.email !== null) {
                        toast.success("Your reviewed has been successfully !!!")
                    } else {
                        toast.warn("Something wrong here.please try again!!!")
                    }
                });
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const {subject, description, priority,name,email,order_id} = this.state
        return (
            <div>
                < main className="main container">
                    <div className="page-header" style={{backgroundImage: `url('static/images/page-header.jpg')`}}>
                        <h1 className="page-title">Support</h1>
                    </div>
                    {/* <div className="page-content mt-10 mb-10 pt-4 pb-4">
                        <section className="contact-section">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-7">
                                    <form className="pt-8 pb-10 pl-4 pr-4 pl-lg-6 pr-lg-6 grey-section" action="#"
                                          onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="subject">Subject:</label>
                                                <input name="subject" value={subject} className="form-control"
                                                       type="text" placeholder="Subject *" onChange={this.onTextChange}
                                                       required/>
                                            </div>
                                            <div className="col-xs-6">
                                                <label htmlFor="priority">Choose your priority:</label>
                                                <select name="priority" value={priority} onChange={this.onTextChange}
                                                        id="priority" className="form-control" required>
                                                    <option value="">Select your priority</option>
                                                    <option value="1">Low</option>
                                                    <option value="2">Medium</option>
                                                    <option value="3">High</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="message">Message:</label>
                                                <textarea name="description" value={description}
                                                          onChange={this.onTextChange} className="form-control" required
                                                          placeholder="Your Message *"/>
                                            </div>
                                        </div>
                                        <button className="btn btn-md btn-primary mb-2">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div> */}
                    <div className="col-lg-12 col-md-9 col-xs-8">
                        <form className="ml-lg-2 pt-8 pb-10 pl-4 pr-4 pl-lg-6 pr-lg-6 grey-section" action="#"
                        onSubmit={this.handleSubmit}
                        >
                            <h3 className="ls-m mb-1">Let’s Connect</h3>
                            <p className="text-grey">Your email addres will not be published. Required fields are
                        marked *</p>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <input className="form-control" type="text" name="name" value={name} onChange={this.onTextChange} placeholder="Name *" required />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <input className="form-control" type="email" name="email" value={email} onChange={this.onTextChange} placeholder="Email *" required />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <input className="form-control" type="text" name="order_id" value={order_id} onChange={this.onTextChange} placeholder="Order No. *" required />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="custom-radio">
                                                <input type="radio" id="order-cancel" name="priority" value="1" onChange={this.onTextChange}  className="custom-control-input" />
                                                <label className="custom-control-label" for="order-cancel">I want to cancel an order</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="custom-radio">
                                                <input type="radio" id="return-order" name="priority" value="2" onChange={this.onTextChange} className="custom-control-input" />
                                                <label className="custom-control-label" for="return-order">I want to return an item</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="custom-radio">
                                                <input type="radio" id="others" name="priority" value="3" onChange={this.onTextChange} className="custom-control-input" />
                                                <label className="custom-control-label" for="others">Others</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <textarea className="form-control" name="description" value={description} onChange={this.onTextChange} required placeholder="Your Message *"></textarea>
                                </div>
                            </div>
                            <button className="btn btn-md btn-primary mb-2">Send Message</button>
                        </form>
                    </div>
                </main>
            </div>
        )
    }
}

export default SupportTicketComponent

