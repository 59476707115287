// noinspection DuplicatedCode

import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import {
    BASE_URL,
    VIRTUAL_PRODUCT_URL,
    PRODUCT_URL,
    NO_TOKEN_FEATURE_PRODUCT_URL,
    PRODUCT_REVIEW_URL
} from '../../constants/api'
import axios from 'axios';
import ReactStars from "react-rating-stars-component";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RatingValueToPerchantage} from '../../constants/customMethod'
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {addItemToWishList} from "../../actions/cartActions";
import {connect} from "react-redux";

const ProductRightSidebarComponent = (props) => {
    const [data, setData] = useState({});
    const [attr, setAttr] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [featured, setFeatured] = useState([])
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [rating, setRating] = useState(5);
    const [comment, setComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [productType, setProductType] = useState(1)
    const [seletctedAttr, setSelectedAttr] = useState([]);
    const [images, setImages] = useState([]);

    const ratingChanged = (newRating) => {
        setRating(newRating)
    };

    const OnChangeaddTocart=(e,data) => {
        // alert(JSON.stringify(data))
        let quantity=e.target.value
        if (quantity>0 && quantity !==null){
            
            if (seletctedAttr.length === attr.length) {
                data["selected_attr"] = setSelectedAttr
                data["added_quantity"] = parseInt(quantity)
                setQuantity(quantity)
                props.onChangeaddToCart(data)
            } else {
                alert("Please Select your product type !!")
            }
        }else{
            toast.warning("Please select your quantity more than Zero")
        }
        
    }

    const addTocart = (data) => {
        if (seletctedAttr.length === attr.length) {
            data["selected_attr"] = setSelectedAttr
            data["added_quantity"] = parseInt(quantity)
            props.onChangeaddToCart(data)
        } else {
            alert("Please Select your product type !!")
        }
    }

    const addQuantity = () => {
        const newquantity = parseInt(quantity) + 1;
        setQuantity(newquantity)
    }

    const subQuantity = () => {
        if (quantity > 1) {
            const newquantity = parseInt(quantity) - 1;
            setQuantity(newquantity)
        }

    }

    const fetchFeatureProduct = async (url) => {
        const result = await axios(url);
        setFeatured(featured => [...featured, ...result.data.results]);
    };

    const fetchData = async (url) => {
        let options_obj;
        const result = await axios(url);
        setData(result.data.results[0]);
        const allImages = [];
        if (result.data.results[0]?.feature_image) {
            allImages.push({
                original: result.data.results[0]?.feature_image,
                thumbnail: result.data.results[0]?.feature_image,
            })
        }
        if (result.data.results[0]?.gallery_images && result.data.results[0]?.gallery_images.length > 0) {
            result.data.results[0]?.gallery_images.map(eachImage => {
                allImages.push({
                    original: BASE_URL + eachImage.image,
                    thumbnail: BASE_URL + eachImage.image,
                })
            })
        }
        setImages(allImages)

        const details = result.data.results[0].product_details_info;
        const attr_data = [];
        if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                for (let j = 0; j < details[i].attributeoption.length; j++) {
                    const check = attr_data.findIndex(x => x.attrbute_id === details[i].attribute);
                    if (check === -1) {
                        const options = [];
                        options_obj = {
                            'attr_option_id': details[i].attributeoption[j].id,
                            'attr_option_name': details[i].attributeoption[j].name,
                            'attr_color_code': details[i].attributeoption[j].color_code,
                            'active': false
                        };
                        options.push(options_obj)
                        const attr = {
                            'attrbute_id': details[i].attribute,
                            'attribute_name': details[i].attributeoption[j].attribute,
                            'attrbute_option': options
                        };
                        attr_data.push(attr)
                    } else {
                        options_obj = {
                            'attr_option_id': details[i].attributeoption[j].id,
                            'attr_option_name': details[i].attributeoption[j].name,
                            'attr_color_code': details[i].attributeoption[j].color_code,
                            'active': false
                        };
                        const attrbute_options = attr_data.find(x => x.attrbute_id === details[i].attribute).attrbute_option;
                        attrbute_options.push(options_obj)
                        attr_data[check].attrbute_option = attrbute_options
                    }
                }
            }
            setAttr(attr_data)
        }
    };

    const selecctAttr = (index, attrindex) => {
        const selected_attr = seletctedAttr;
        const attr_id = attr[index].attrbute_id;
        const attr_option = attr[index].attrbute_option[attrindex].attr_option_id;
        const check = selected_attr.findIndex(x => x.attrbute_id === attr_id);
        if (check === -1) {
            const options_obj = {
                'attrbute_id': attr_id,
                'attr_option_id': attr_option,
            };
            selected_attr.push(options_obj)
        } else {
            selected_attr[check].attr_option_id = attr_option
        }
        attr[index].attrbute_option.map(a => a.active = false);
        attr[index].attrbute_option[attrindex].active = true
        setAttr(attr)
        setSelectedAttr(selected_attr)
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const data = {
            'email': email,
            'rating': rating,
            'name': name,
            'comment': comment,
            'product_id': props.id,
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
        fetch(PRODUCT_REVIEW_URL, requestOptions)
            .then(res => res.json())
            .then(result => {
                if (result.name) {
                    toast.success("Your has been reviewed successfully!!!")
                    setTimeout(() => {
                        window.location.reload();
                    }, 500)
                } else {
                    toast.warn("Something wrong here.please try again!!!")
                }
            });

    }

    useEffect(() => {
        let id = props.id
        
        fetchData(productType === 1 ? PRODUCT_URL + `&id=${id}` : VIRTUAL_PRODUCT_URL + `?id=${id}`).then(r => {
        })
        fetchFeatureProduct(NO_TOKEN_FEATURE_PRODUCT_URL).then(r => {
        })
        if (localStorage.getItem('user')) {
            setIsLoggedIn(true)
        }
        setTimeout(()=>{
            let getItem = props.items.find(item => item.id === parseInt(props.id))
            if(getItem){
                // alert(JSON.stringify(getItem.quantity))
                
                setQuantity(getItem.quantity)
            } 
        },2000)
        
    }, [])
   
    const imageRender = (imageItems) => {
        return (
        // <>
        //     <GlassMagnifier
        //             imageSrc={imageItems}
        //             imageAlt="Example"
        //             largeImageSrc={imageItems} // Optional
        //             />
                    
        //             <Magnifier
        //             imageSrc={imageItems}
        //             imageAlt="Example"
        //             largeImageSrc={imageItems} // Optional
        //             mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
        //             touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
        //             />
        // </>
            <ImageGallery items={imageItems} showFullscreenButton={false} showPlayButton={false} useBrowserFullscreen={true} showNav={false}/>
        )
    }

    return (
        <div>
            <main className="main mt-2">
                <div className="page-content mb-10">
                    <div className="container">
                        <div className="product-navigation">
                            <ul className="breadcrumb breadcrumb-lg">
                                <li><a><i className="d-icon-home"/></a></li>
                                <li><a href="#" className="active">Products</a></li>
                                <li>{data.product_name}</li>
                            </ul>
                        </div>
                        <div className="row gutter-lg">
                            <aside className="col-lg-3 right-sidebar sidebar-fixed sticky-sidebar-wrapper">
                                <div className="sidebar-overlay">
                                    <a className="sidebar-close" href="#"><i className="d-icon-times"/></a>
                                </div>
                                <a href="#" className="sidebar-toggle"><i className="fas fa-chevron-left"/></a>
                                <div className="sidebar-content">
                                    <div className="sticky-sidebar">
                                        <div className="service-list mb-4">
                                            
                                            {/* <div className="icon-box icon-box-side icon-box1">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-truck"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Free Delivery</h4>
                                                    <p>Free Delivery on orders over ৳25000.00</p>

                                                </div>
                                            </div> */}
                                            <div className="icon-box icon-box-side icon-box2">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-money"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Return Policy</h4>
                                                    <p>7 days return</p>
                                                </div>
                                            </div>
                                            <div className="icon-box icon-box-side icon-box3">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-service"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">24/7 support</h4>
                                                    <p>Support every time</p>
                                                </div>
                                            </div>
                                            {/* <div className="icon-box icon-box-side icon-box3">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-secure"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Payment Method</h4>
                                                    <p>COD, Card, bKash, Rocket, Nagad</p>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="widget widget-products">
                                            <h4 className="widget-title">Feature Products</h4>
                                            <div className="widget-body">
                                                <div className="products-col">
                                                    {featured.map((product, index) => (
                                                        <div className="products-col" key={index}>
                                                            <Link to={'product-details?id=' + product.id}>
                                                                <div className="product product-list-sm"
                                                                     style={{alignItems: "start"}}
                                                                     onClick={() => {
                                                                         setTimeout(() => {
                                                                             let id = product.id
                                                                             fetchData(PRODUCT_URL + `&id=${id}`).then(() => {
                                                                             })
                                                                             fetchFeatureProduct(NO_TOKEN_FEATURE_PRODUCT_URL).then(() => {
                                                                             })
                                                                         }, 500)
                                                                     }}>
                                                                    <figure className="product-media"
                                                                            style={{height: "auto"}}>
                                                                        <img src={product.feature_image} alt="product"
                                                                             width="100" height="100"/>
                                                                    </figure>
                                                                    <div className="product-details">
                                                                        <h3 className="product-name">

                                                                            {product.product_name}
                                                                        </h3>
                                                                        <div className="product-price">
                                                                            {
                                                                                product.discount_type === 1 || featured.discount_type === 2 ? (
                                                                                        <span
                                                                                            className="price">৳ {product.new_price}</span>
                                                                                    ) :
                                                                                    <span
                                                                                        className="new-price">৳ {product.regular_price}</span>
                                                                            }
                                                                        </div>
                                                                        <div className="ratings-container">
                                                                            <div className="ratings-full">
                                                                                    <span className="ratings"
                                                                                          style={{width: RatingValueToPerchantage(product.avg_rating) + '%'}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                            <div className="col-lg-9">


                                <div className="product product-single row mb-4">
                                    <div className="col-md-6">
                                    {/* <Magnifier
                                        imageSrc={images}
                                        imageAlt="Example"
                                        largeImageSrc={images} // Optional
                                        mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
                                        touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
                                    /> */}
                                        {images.length > 0 ? imageRender(images) : null}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="product-details">
                                            <div className="product-navigation">
                                                <ul className="breadcrumb breadcrumb-lg">
                                                    <li><a href="/"><i className="d-icon-home"/></a></li>
                                                    <li><a href="#" className="active">Products</a></li>
                                                    <li>Detail</li>
                                                </ul>
                                            </div>

                                            <h1 className="product-name">{Object.keys(data).length > 0 ? data.product_name : ''}</h1>
                                            <div className="product-meta">
                                                SKU: <span
                                                className="product-sku">{Object.keys(data).length ? data.product_sku : ''}</span>

                                                BRAND: <span
                                                className="product-brand">{Object.keys(data).length > 0 && data.brands !== null && data.brands !== '' ? data.brands.name : ''}</span>
                                            </div>
                                            {data?.is_price_range ===true && data?.price_range !==null ? 
                                            
                                            <>
                                                        {data.price_range.map((rang)=>
                                                            <>
                                                            <span className="product-sku">({rang.minimum}</span>-<span className="product-brand">{rang.maximum})</span> {' '}{  data.product_units !=null && data.product_units !=undefined? data.product_units.name:""}<span> ৳{rang.price}</span><br/>
                                                            </>
                                                    
                                                        )}
                                                </>       

                                                        :null}
                                            <div className="product-meta">
                                            {/* (data.unit_amount? data.unit_amount:'') + ' '+ */}
                                                Unit: <span
                                                className="product-sku">{Object.keys(data).length ?  (data.product_units !==null && data.product_units !=='' ?data.product_units.name:'') : ''}</span>

                                                
                                            </div>
                                            <div className="product-price">
                                                {Object.keys(data).length > 0 ? (
                                                    <>
                                                        {
                                                            data.discount_type === 1 || data.discount_type === 2 ? (
                                                                    <ins className="new-price">৳ {data.new_price}</ins>

                                                                ) :
                                                                <ins className="new-price">৳ {data.new_price}</ins>

                                                        }
                                                        {
                                                            data.discount_type === 1 || data.discount_type === 2 ? (
                                                                <del
                                                                    className="old-price">৳ {data.regular_price}</del>
                                                            ) : null

                                                        }
                                                    </>

                                                ) : null

                                                }
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings-full">
                                                    <span className="ratings"
                                                          style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}/>
                                                    {/* <span className="tooltiptext tooltip-top"></span> */}
                                                </div>
                                                <a href="#product-tab-reviews"
                                                   className="link-to-tab rating-reviews">( {data.total_reviews} reviews
                                                    )</a>
                                            </div>
                                            <p className="product-short-desc">

                                                {Object.keys(data).length > 0 ? <div
                                                    dangerouslySetInnerHTML={{__html: data.product_summary}}/> : ''}</p>

                                            {attr.map((attribute, index) => (
                                                <>
                                                    {attribute.attribute_name.toLowerCase() === 'color' || attribute.attribute_name.toLowerCase() === 'colour' ?
                                                        <div className="product-form product-color " key={index}>
                                                            <label>{attribute.attribute_name}:</label>
                                                            <div className="product-variations">
                                                                {attribute.attrbute_option.map((option, attrindex) => (
                                                                    <a onClick={() => selecctAttr(index, attrindex)}
                                                                       className={option.active === true ? "color active" : 'color'}
                                                                       href="#"
                                                                       style={{
                                                                           border: '1px solid #e1e1e1',
                                                                           width: 'auto'
                                                                       }}>{option?.attr_option_name}</a>
                                                                ))}

                                                            </div>
                                                        </div>
                                                        :

                                                        <div className="product-form product-size">
                                                            <label>{attribute.attribute_name}:</label>
                                                            <div className="product-form-group">
                                                                <div className="product-variations">
                                                                    {attribute.attrbute_option.map((option, optindex) => (
                                                                        <a onClick={() => selecctAttr(index, optindex)}
                                                                           className={option.active === true ? "size active" : 'size'}
                                                                           href="#">{option.attr_option_name}</a>
                                                                    ))}

                                                                </div>

                                                            </div>
                                                        </div>

                                                    }
                                                </>
                                            ))}


                                            <hr className="product-divider"/>

                                            <div className="product-form product-qty">
                                                
                                                <label>{data.product_units !==undefined && data.product_units !==null ? data.product_units.name:''} :</label>
                                                <div className="product-form-group">
                                                    <div className="input-group">
                                                        <button onClick={() => subQuantity()}
                                                                className="quantity-minus d-icon-minus"/>
                                                        <input onChange={(value) => OnChangeaddTocart(value,data)} name="quantity" value={quantity}
                                                            className="quantity form-control d-flex justify-content-center align-items-center"/>
                                                        <button onClick={() => addQuantity()}
                                                                className="quantity-plus d-icon-plus"/>
                                                    </div>
                                                    { data?.stock_quantity > 0 || data?.is_pre_order === true ?
                                                    <button onClick={() => addTocart(data)}
                                                            className={data?.stock_quantity > 0 || data?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}>
                                                        <i className="d-icon-bag"/>Add To Cart
                                                    </button>
                                
                                    :
                                                    <button onClick={() => addTocart(data)}
                                                            className={data?.stock_quantity > 0 || data?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}>
                                                        <i className="d-icon-bag"/>out of stock
                                                    </button>
                                                    }
                                                </div>
                                            </div>

                                            <hr className="product-divider mb-3"/>

                                            <div className="product-footer">

                                                <div className="product-action">
                                                    <a href="#" onClick={() => {
                                                        props.addItemToWishList(data)
                                                    }} className="btn-product btn-wishlist">
                                                        <i className="d-icon-heart"/>Add To Wishlist
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab tab-nav-simple product-tabs mb-4">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active"
                                               href="#product-tab-description">Description</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-video">Product Video</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-shipping-returns">Shipping &amp;
										Returns</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-reviews">Reviews
                                                ({data.total_reviews})</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active in" id="product-tab-description">
                                            <p>
                                                {data.is_whole_sell ?
                                                    <strong> Order {data.purchase_quantity} units and get wholesale
                                                        Price {data.whole_sell_price} Taka</strong>
                                                    : null

                                                }
                                                {Object.keys(data).length > 0 ? <div
                                                    dangerouslySetInnerHTML={{__html: data.product_details}}/> : ''}</p>
                                        </div>
                                        <div className="tab-pane" id="product-tab-video">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                        {/* <div dangerouslySetInnerHTML={{ __html: "<iframe src={data.youtube_url} />"}} /> */}

                                                    <iframe className="my-auto mx-auto"
                                                            src={Object.keys(data).length > 0 ? data.youtube_url : ''}
                                                            target="_parent" width="535" height="300"
                                                            style={{border: "none", overflow: "hidden"}} scrolling="no"
                                                            frameBorder="0" allowtransparency="true"
                                                            allowFullScreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane " id="product-tab-reviews">
                                            <div className="d-flex align-items-center mb-5">
                                                <h4 className="mb-0 mr-2">Average Rating:</h4>
                                                <div className="ratings-container average-rating mb-0">
                                                    <div className="ratings-full">
                                                        <span className="ratings"
                                                              style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}></span>
                                                        {/* <span className="tooltiptext tooltip-top">{data.avg_rating}</span> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="comments mb-6">
                                                <ul>
                                                    {Object.keys(data).length > 0 && data.product_reviews.map((review, index) => (
                                                        <li>
                                                            <div className="comment">
                                                                <figure className="comment-media">
                                                                    <a href="#">
                                                                        <img src='/static/images/no-image.png'
                                                                             alt="avatar"/>
                                                                    </a>
                                                                </figure>
                                                                <div className="comment-body">
                                                                    <div
                                                                        className="comment-rating ratings-container mb-0">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings"
                                                                                  style={{width: RatingValueToPerchantage(review.rating) + '%'}}/>
                                                                            {/* <span className="tooltiptext tooltip-top">{review.rating}</span> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="comment-user">
                                                                        <h4><a href="#">{review.name}</a></h4>
                                                                        <span
                                                                            className="comment-date">{moment(review.created_at).format("DD MMM YYYY hh:mm:ss A")}
																	</span>
                                                                    </div>

                                                                    <div className="comment-content">
                                                                        <p>{review.comment}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {isLoggedIn && <div className="reply">
                                                <div className="title-wrapper text-left">
                                                    <h3 className="title title-simple text-left text-normal">Add a
                                                        Review</h3>
                                                    <p>Your email address will not be published. Required fields are
                                                        marked
                                                        *</p>
                                                </div>
                                                <form action="#" onSubmit={handleSubmit}>
                                                    <div className="rating-form">
                                                        <label htmlFor="rating">Your rating: </label>
                                                        <ReactStars
                                                            count={rating}
                                                            onChange={ratingChanged}
                                                            size={20}
                                                            activeColor="#fd807f"
                                                        />
                                                    </div>
                                                    <textarea id="reply-message" cols="30" rows="4" name="comment"
                                                              onChange={e => {
                                                                  const val = e.target.value;
                                                                  setComment(val)
                                                              }} value={comment} className="form-control mb-4"
                                                              placeholder="Comment *" required/>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-5">
                                                            <input type="text" className="form-control" id="reply-name"
                                                                   onChange={e => {
                                                                       const val = e.target.value;
                                                                       setName(val)
                                                                   }} value={name} name="name" placeholder="Name *"
                                                                   required/>
                                                        </div>
                                                        <div className="col-md-6 mb-5">
                                                            <input type="email" className="form-control"
                                                                   id="reply-email" onChange={e => {
                                                                const val = e.target.value;
                                                                setEmail(val)
                                                            }} value={email} name="email" placeholder="Email *"
                                                                   required/>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-md">Submit
                                                        <i className="d-icon-arrow-right"/></button>
                                                </form>
                                            </div>}

                                        </div>
                                    </div>
                                </div>

                                <section>
                                    <h2 className="title title-link mb-4">Related Products</h2>

                                    <div className="owl-carousel owl-theme owl-nav-full row cols-2 cols-md-3 cols-lg-4"
                                         data-owl-options="{
									'items': 5,
									'nav': false,
									'loop': false,
									'dots': true,
									'margin': 20,
									'responsive': {
										'0': {
											'items': 2
										},
										'768': {
											'items': 3
										},
										'992': {
											'items': 4,
											'dots': false,
											'nav': true
										}
									}
								}">
                                        {
                                            Object.keys(data).length > 0 ?
                                                <>
                                                    {data.up_sell_product !== null && data.up_sell_product.map((product, index) => (
                                                        <div className="product shadow-media" key={index}>
                                                            <figure className="product-media">
                                                                <a href='#'>
                                                                    <img src={BASE_URL + product.feature_image}
                                                                         alt="product" width="280"
                                                                         height="315"/>
                                                                </a>
                                                                <div className="product-label-group">
                                                                    {product.discount_type === 1 ? (
                                                                            <label
                                                                                className="product-label label-sale">৳ {product.discount_price}%
                                                                                OFF</label>
                                                                        ) :
                                                                        <label
                                                                            className="product-label label-sale">৳ {product.discount_price} TK
                                                                            OFF</label>
                                                                    }
                                                                </div>
                                                                <div className="product-action-vertical">
                                                                    <a href="#" className="btn-product-icon btn-cart"
                                                                       data-toggle="modal"
                                                                       data-target="#addCartModal"
                                                                       title="Add to cart"><i className="d-icon-bag"></i></a>
                                                                </div>
                                                                <div className="product-action">
                                                                    <a href="#" className="btn-product btn-quickview"
                                                                       title="Quick View">Quick
                                                                        View</a>
                                                                </div>
                                                            </figure>
                                                            <div className="product-details">
                                                                <a href="#" className="btn-wishlist"
                                                                   title="Add to wishlist"><i className="d-icon-heart"></i></a>
                                                                <div className="product-cat">
                                                                    <a href="shop-grid-3col.html">categories</a>
                                                                </div>
                                                                <h3 className="product-name">
                                                                    <a href="product.html">{product.product_name}</a>
                                                                </h3>
                                                                <div className="product-price">
                                                                    {product.discount_type === 1 || product.discount_type === 2 ? (
                                                                            <ins
                                                                                className="new-price">৳ {product.new_price}</ins>

                                                                        ) :
                                                                        <ins
                                                                            className="new-price">৳ {product.new_price}</ins>

                                                                    }
                                                                    {
                                                                        product.discount_type !== '' ? (
                                                                            <del
                                                                                className="old-price">৳ {product.regular_price}</del>
                                                                        ) : null

                                                                    }
                                                                </div>
                                                                <div className="ratings-container">
                                                                    <div className="ratings-full">
                                                                        <span className="ratings" style={{width: RatingValueToPerchantage(product.avg_rating) + "%"}}/>
                                                                        {/* <span className="tooltiptext tooltip-top">{product.avg_rating}</span> */}
                                                                    </div>
                                                                    <a href="#" className="rating-reviews">( <span
                                                                        className="review-count">{product.total_reviews}</span>
                                                                        reviews )</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                                : null
                                        }

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* ))} */}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        wishlist: state.cart.wishlist,
        items: state.cart.addedItems,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addItemToWishList: (data) => {
            dispatch(addItemToWishList(data))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductRightSidebarComponent)
