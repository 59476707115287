import React from "react";
import {Link} from "react-router-dom";

const BannerBreadcrumb = ({title, imageLink}) => {
  return (<>
    <div className="container mb-10">
      <div className="page-header mt-5" style={{backgroundImage: `url(${imageLink})`, position: 'relative'}}>
        <h1 className="page-title text-white z-index">
          {title}
        </h1>
        <ul className="breadcrumb">
          <li className="text-white z-index">
            <Link to="/">
              <i className="d-icon-home"/>
            </Link>
          </li>
          <li className="text-white z-index">
            {title}
          </li>
        </ul>
      </div>
    </div>
  </>)
}

export default BannerBreadcrumb
