import React, { Component } from 'react'
import {ORDERS_URL,NO_TOKEN_SHIPPING_CLASS} from '../../constants/api';
import moment from 'moment';
import axios from 'axios';



class OrderComponent extends Component {

    state = {
        first_name:'',
        last_name:'',
        phone_no:'',
        email:'',
        address:'',
        notes:'',
        post_code:'',
        products_name_list:'',
        district:'',
        is_login:false,
        orders:'',
    };

    componentDidMount(){
        const {order} =this.props
        this.loadOrders(order)
       
    }

    getDeleveryCharge = async (orders,products)=>{
      
        var district_id=""
        var code= ""   
        if(!orders.is_shipping){
            district_id=orders.billing_address.districts ? orders.billing_address.districts.id : null
            code=orders.billing_address.post_code
        }else{
        
            district_id=orders.shipping_address.districts ? orders.shipping_address.districts.id : null
            code=orders.shipping_address.post_code
        }
        const result = await axios(
            NO_TOKEN_SHIPPING_CLASS+'?zone='+district_id+'&post_code='+code
        );

        if(result.data.results.length>0){
            let zone = result.data.results[0].shipping_zone           
            this.setState({products_name_list:products+" Duration: "+zone.days+'-'+zone.max_days+' Days'});
        }
        
        
    }
    loadOrders = (id) => {
        let url = ORDERS_URL+id
        var token = localStorage.getItem('token', '');      
        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
      
        fetch(url, {"method":"GET",headers,})
        .then(res => res.json())
        .then(orders => {
            this.setState({orders:orders})
            var normalproduct = []
          
            // alert(tags)
            for(var i=0;i<orders.order_product.length;i++){
                if(!orders.order_product[i].is_pre_order){
                    // alert(JSON.stringify(orders.order_product[i]))
                    normalproduct.push(orders.order_product[i].product.product_name)  
                }
            }
            if(normalproduct.length>0){
           

                this.getDeleveryCharge(orders,normalproduct.toString())
       
                // this.setState({products_name_list:normalproduct.toString()}) 


            }
            // alert(JSON.stringify(normalproduct))
        })
    //   alert(token)
      
    
    };


    render() {
        const {orders} = this.state
        const {items}=this.props
        // var taxSum=0.0
        // if (items.length>0){
        //     for(var i = 0 ; i < items.length ; i++){     
        //         for (var j=0;j<items[i].taxs.length;j++){
        //             taxSum +=parseFloat(items[i].taxs[j].rate)
        //         }
        //     }
        // }  
        return (
            <div>
                {orders !=='' ?
                <main className="main container">
                    <div className="page-header bg-dark"
                        style={{ backgroundImage: `url(${'static/images/shop/bgbanner.png'})`, backgroundColor: "#3C63A4" }}>
                        <h1 className="page-title">order successfull!</h1>
                        <ul className="breadcrumb">
                            {/* <li><a href="demo1.html">Checkout</a></li> */}
                            <li>Order Successfull!</li>
                        </ul>
                    </div>
                    <div className="page-content pt-10 pb-10">
                        <div className="container mt-8">
                            <div className="order-message">
                                <i className="fas fa-check"></i>Thank you, Your order has been received.
					</div>


                            <div className="order-results pt-8 pb-8">
                                <div className="overview-item">
                                    <span>Order number </span>
                                    <strong>{' #'+orders.order_no}</strong>
                                </div>
                                <div className="overview-item">
                                    <span>Status </span>
                                    <strong>Pending </strong>
                                </div>
                                <div className="overview-item">
                                    <span>Date </span>
                                    <strong>{orders.order_date ? moment(orders.order_date).format('D-M-Y: h:m A') : ''}</strong>
                                </div>
                                {orders.reward_points>0 ?
                                <div className="overview-item">
                                    <span>Point</span>
                                    <strong> {orders.reward_points}</strong>
                                </div>
                                :null
                                }
                                <div className="overview-item">
                                    <span>Total </span>
                                    <strong>৳{orders.reward_points>0 ? 0 :  orders.total_order_price+orders.tax} TK</strong>
                                </div>
                                <div className="overview-item">
                                    <span>Payment method </span><br/>
                                    <strong>{orders.payment_methods!==null && orders.payment_methods.payment_type===1 ? 
                                    <>
                                    <p style={{margin:0}}>
                                    {Object.keys(orders).length > 0 ? <div
                                    dangerouslySetInnerHTML={{__html: orders.payment_methods.description}}/>:null}
                                    </p>
                                    {/* {orders.payment_methods.description} */}
                                    {/* <> Bank Name:{orders.payment_methods.bank_name}</><br/>
                                    <> Branch Name:{orders.payment_methods.branch_name}</><br/>   
                                    <> Account Name:{orders.payment_methods.account_name}</><br/>   
                                    <> Account No:{orders.payment_methods.account_number}</><br/>   
                                    <> Routing Number:{orders.payment_methods.routing_number}</> */}
                                    </> : 
                                    <>
                                    <p style={{margin:0}}>
                                    {Object.keys(orders).length > 0 ? <div
                                    dangerouslySetInnerHTML={{__html: orders.payment_methods.description}}/>:null}
                                    </p>
                                    {/* {orders.payment_methods.description} */}
                                    </>
                                    // <>Wallet Number: {orders.payment_methods.wallet_number}</>
                                    }
                                    </strong>
                                </div>
                            </div>

                            <h2 className="title title-simple text-left pt-3">Order Details</h2>
                            <div className="order-details mb-1">
                                <table className="order-details-table">
                                    <thead>
                                        <tr className="summary-subtotal">
                                            <td>
                                                <h3 className="summary-subtitle">Product</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {  orders.order_product !==undefined &&  orders.order_product.map(item => ( 
                                        <tr>
                                            <td className="product-name">{item.product.product_name} <span> <i className="fas fa-times"></i> {item.quantity} *  {item.product.is_whole_sell && (item.quantity >=item.product.purchase_quantity) ?  item.product.whole_sell_price :item.price}</span></td>
                                            <td className="product-price">৳  {item.product.is_whole_sell && (item.quantity >=item.product.purchase_quantity) ?  item.quantity*item.product.whole_sell_price :item.quantity*item.price}</td>
                                        </tr>
                                         ))                             
                                     }
                                       {   (orders.bundle_list !==undefined && orders.bundle_list !==null)   &&  orders.bundle_list.map(item => ( 
                                        <tr>
                                            <td className="product-name">{item.title} <span> <i className="fas fa-times"></i> {item.quantity} * {item.total_price}</span></td>
                                            <td className="product-price">৳ {item.quantity*item.total_price}</td>
                                        </tr>
                                         ))                             
                                     }
                                     {orders.tax>0? 
                                     <tr>
                                            <td  className="product-name">Tax</td>
                                            <td className="product-price">{orders.tax !=='' &&  orders.tax !==null? '৳ ' + orders.tax : 0}</td>
                                          
                                           
                                    </tr> 
                                    :null} 
                                    {
                                        orders.shipping_charge>0?
                                    
                                    <tr>
                                            <td  className="product-name">Delivery Charge</td>
                                            <td className="product-price">{orders.shipping_charge !=='' &&  orders.shipping_charge !==null? '৳ ' + orders.shipping_charge : 0}</td>
                                          
                                           
                                    </tr>  
                                    :null}
                                    {orders.discount_amount>0?
                                    <tr>
                                            <td  className="product-name">Discount</td>
                                            <td className="product-price">{orders.discount_amount !=='' &&  orders.discount_amount !==null? '৳ ' + orders.discount_amount : 0}</td>
                                          
                                           
                                    </tr>  
                                    :null}
                                       
                                    </tbody>
                                </table>
                                <p>{this.state.products_name_list}</p>
                                { 
                                 orders.order_product !==undefined &&  orders.order_product.map(item => (
                                    <>
                                    {
                                         item.product.is_pre_order
                                        ?
                                        <p>{item.product.product_name +" (Pre Order) Duration :"+item.product.pre_order_shipping_estimate+" Days"}</p>
                                        :null
                                    }
                                    </>
                                   
                                ))                             
                                }
                                    
                            </div>
                            {orders.billing_address ? 
                            <>         
                            <h2 className="title title-simple text-left pt-8 mb-2">Billing Address</h2>
                            
                            <div className="overview-item">
                                {orders.billing_address.first_name+ ' ' +orders.billing_address.last_name}<br />  
                                {orders.billing_address.address}<br/>
                                {orders.billing_address.post_code}<br/>
                                {orders.billing_address.phone_no}<br/>
                                {orders.billing_address.districts ? orders.billing_address.districts.name : null}
                                {orders.billing_address.email}
						        </div>
                               
                            
                             </> 
                             :null
                             }  

                            {orders.is_shipping && orders.shipping_address ? 
                            <>         
                            <h2 className="title title-simple text-left pt-8 mb-2">Shipping Address</h2>
                           
                            <div className="overview-item">
                                {orders.shipping_address.first_name+ ' ' +orders.shipping_address.last_name}<br />  
                                {orders.shipping_address.shipping_address}<br/>
                                {orders.shipping_address.post_code}<br/>
                                {orders.shipping_address.phone_no}<br/>
                                {orders.shipping_address.districts ? orders.shipping_address.districts.name : null}
                                {orders.shipping_address.email}
						        </div>
                                
                           
                             </> 
                             :
                             <>
                             <h2 className="title title-simple text-left pt-8 mb-2">Delivery Address</h2>
                            
                             <div className="overview-item">
                             {orders.billing_address.first_name+ ' ' +orders.billing_address.last_name}<br />  
                             {orders.billing_address.address}<br/>
                             {orders.billing_address.post_code}<br/>
                             {orders.billing_address.phone_no}<br/>
                             {orders.billing_address.districts ? orders.billing_address.districts.name : null}
                             {orders.billing_address.email}
                             </div>
                             
                           
                            </>
                             }
                            <a href="/" className="btn btn-icon-left btn-back btn-md mb-4"><i className="d-icon-arrow-left"></i> Back to List</a>
                        </div>
                    </div>
                  
                </main>
                  :null
                }
            </div>
        )
    }
}
export default OrderComponent

