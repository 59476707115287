import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {GET_ALL_CATEGORY_URL} from '../../constants/api'
import {RatingValueToPerchantage} from "../../constants/customMethod";


class SellerPageComponent extends Component {

    constructor() {
        super()
        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        this.getCategory();
    }

    getCategory = () => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        fetch(GET_ALL_CATEGORY_URL, requestOptions)
            .then(res => res.json())
            .then(category => {


                this.setState({categories: category})

            });
    }

    render() {
        const {state} = this.props
        return (
            <div>
                < main className="main container">
                    <div className="page-content mb-10">
                        <div className="container">
                            <ul className="breadcrumb breadcrumb-sm">
                                <li><a href="demo1.html"><i className="d-icon-home"></i></a></li>
                                <li>Shop</li>
                            </ul>
                            {/* <!-- End Breadcrumb --> */}
                            <div className="row main-content-wrap gutter-lg">
                                <aside className="col-lg-3 sidebar sidebar-fixed shop-sidebar sticky-sidebar-wrapper">
                                    <div className="sidebar-overlay">
                                        <a className="sidebar-close" href="#"><i className="d-icon-times"></i></a>
                                    </div>
                                    <div className="sidebar-content">
                                        <div className="sticky-sidebar">
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title">All Categories</h3>
                                                <ul className="widget-body filter-items search-ul">

                                                    {this.state.categories.map((primary_category, index) => (
                                                        <>
                                                            <li className="with-ul" key={index}>
                                                                <a href={"/category?primary_categories__id=" + primary_category.primary_category.id}>{primary_category.primary_category.name}</a>
                                                                {primary_category.secondary_category.length > 0 ?
                                                                    <ul>
                                                                        {primary_category.secondary_category.map((secondary, secindex) => (

                                                                            <li key={secindex}><a
                                                                                href="#">{secondary.name}</a></li>
                                                                        ))}
                                                                    </ul>
                                                                    : null
                                                                }
                                                            </li>

                                                        </>
                                                    ))}


                                                </ul>
                                            </div>


                                        </div>
                                    </div>
                                </aside>
                                <div className="col-lg-9 main-content">
                                    <div className="shop-banner-default banner" style={{
                                        backgroundImage: `url(${state.shop_info ? state.shop_info.shop_image : ''})`,
                                        backgroundColor: "#f2f2f3;"
                                    }}>
                                        <div className="banner-content" style={{position: "relative"}}>
                                        </div>
                                        <h4 className="font-primary lh-1 ls-m mb-0 font-weight-bold text-capitalize"
                                            style={{
                                                position: "absolute",
                                                bottom: "10%",
                                                left: "20%"
                                            }}>{state.shop_info ? state.shop_info.shop_name : ''}</h4>
                                        <p className="font-primary lh-1 ls-m mb-0 text-capitalize"
                                           style={{position: "absolute", bottom: "3%", left: "20%"}}><small><i
                                            className="fa fa-star"></i> Rating: <span>4.9</span></small></p>

                                        <img
                                            src={state.shop_info ? state.shop_info.shop_logo : '/static/images/no-image.png'}
                                            alt="" height="120" width="120" style={{
                                            position: "absolute",
                                            border: "5px solid #FFFFFF",
                                            bottom: "-14%",
                                            left: "5%"
                                        }}/>
                                    </div>
                                    <nav className="toolbox sticky-toolbox sticky-content fix-top mt-10">
                                        <div className="toolbox-left">
                                            <a href="#"
                                               className="toolbox-item left-sidebar-toggle btn btn-sm btn-outline btn-primary d-lg-none">Filters<i
                                                className="d-icon-arrow-right"></i></a>
                                            <div className="toolbox-item toolbox-sort select-box">
                                                <label>Sort By :</label>
                                                <select name="orderby" className="form-control">
                                                    <option value="default">Default</option>
                                                    <option value="popularity" selected="selected">Most Popular</option>
                                                    <option value="rating">Average rating</option>
                                                    <option value="date">Latest</option>
                                                    <option value="price-low">Sort forward price low</option>
                                                    <option value="price-high">Sort forward price high</option>
                                                    <option value="">Clear custom sort</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="toolbox-right">
                                            <div className="toolbox-item toolbox-show select-box">
                                                <label>Show :</label>
                                                <select name="count" className="form-control">
                                                    <option value="12">12</option>
                                                    <option value="24">24</option>
                                                    <option value="36">36</option>
                                                </select>
                                            </div>
                                            <div className="toolbox-item toolbox-layout">
                                                <a href="shop-list.html" className="d-icon-mode-list btn-layout"></a>
                                                <a href="shop.html" className="d-icon-mode-grid btn-layout active"></a>
                                            </div>
                                        </div>
                                    </nav>
                                    <div className="row cols-2 cols-sm-3 product-wrapper">
                                        {state.products.map((product, index) => (
                                            <div className="col-md-3">
                                                <div className="product-wrap">
                                                    <div className="product shadow-media">
                                                        <figure className="product-media">
                                                            <Link to={"product-details?id=" + product.id}>
                                                                <img src={product.feature_image} alt="product"
                                                                     width="280" height="315"/>
                                                            </Link>
                                                            <div className="product-label-group">
                                                                {product.discount_type === 1 ? (
                                                                    <label
                                                                        className="product-label label-sale">{product.discount_price}%
                                                                        OFF</label>


                                                                ) : null
                                                                }

                                                                {product.discount_type === 2 ? (
                                                                    <label
                                                                        className="product-label label-sale">{product.discount_price} TK
                                                                        OFF</label>
                                                                ) : null
                                                                }
                                                            </div>
                                                            <div className="product-action-vertical">
                                                                <a href="javascript:void(0);"
                                                                   onClick={() => this.props.addItemToCart(product)}
                                                                   className="btn-product-icon btn-cart"
                                                                   data-toggle="modal"
                                                                   data-target="#addCartModal" title="Add to cart">
                                                                    <i className="d-icon-bag"/>
                                                                </a>
                                                            </div>
                                                            <div className="product-action">
                                                                <a href="#" className="btn-product btn-quickview"
                                                                   title="Quick View">Quick
                                                                    View</a>
                                                            </div>
                                                        </figure>
                                                        <div className="product-details">
                                                            <a href="javascript:void(0);"
                                                               onClick={() => this.props.addItemToWishList(product)}
                                                               className="btn-wishlist" title="Add to wishlist">
                                                                <i className="d-icon-heart"/>
                                                            </a>
                                                            <h3 className="product-name">
                                                                <Link
                                                                    to={"product-details?id=" + product.id}>{product.product_name}</Link>
                                                            </h3>
                                                            <div className="product-price">
                                                            {product.discount_type === 1 || product.discount_type === 2 ? (
                                                                <ins className="new-price">৳ {product.new_price}</ins>

                                                                ) :
                                                                    <ins className="new-price">৳ {product.new_price}</ins>

                                                                }
                                                                {
                                                                    product.discount_type === 1 || product.discount_type ===2 ? (
                                                                        <del
                                                                            className="old-price">৳ {product.regular_price}</del>
                                                                    ) : null

                                                                }
                                                                {/* {
                                                                    product.discount_type === 1 || product.discount_type === 2
                                                                        ? <ins
                                                                            className="new-price">BDT {product.new_price}</ins>
                                                                        : <ins
                                                                            className="new-price">BDT {product.new_price}</ins>
                                                                }
                                                                {
                                                                    product.discount_type !== '' &&
                                                                    <del
                                                                        className="old-price">BDT {product.regular_price}</del>
                                                                } */}

                                                            </div>
                                                            <div className="ratings-container">
                                                                <div className="ratings-full">
                                                                    <span className="ratings"
                                                                        style={{width: RatingValueToPerchantage(product?.avg_rating) + "%",}}/>
                                                                </div>
                                                            </div>
                                                            {/* <div className="ratings-container">
                                                                <div className="ratings-full">
                                                                    <span className="ratings" style={{width: "100%"}}/>
                                                                    <span className="tooltiptext tooltip-top"/>
                                                                </div>
                                                                <a href="product.html" className="rating-reviews">( 6 reviews )</a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        ))
                                        }

                                    </div>
                                    <nav className="toolbox toolbox-pagination">
                                        <p className="show-info">Showing <span>{state.total_showing} of {state.count}</span> Products
                                        </p>
                                        <ul className="pagination">
                                            <li className="page-item disabled">
                                                <a className="page-link page-link-prev" href="#" aria-label="Previous"
                                                   tabindex="-1"
                                                   aria-disabled="true">
                                                    <i className="d-icon-arrow-left"></i>Prev
                                                </a>
                                            </li>
                                            <li className="page-item active" aria-current="page"><a
                                                className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item page-item-dots"><a className="page-link"
                                                                                        href="#">6</a></li>
                                            <li className="page-item">
                                                <a className="page-link page-link-next" href="#" aria-label="Next">
                                                    Next<i className="d-icon-arrow-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>


                </main>
            </div>
        )
    }
}

export default SellerPageComponent

