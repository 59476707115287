export const matched = matchName => (value, allValues, props) =>
    value !== allValues[matchName]
        ?` Password is not match ${matchName} `
        : undefined;
export const  strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
export const required = value => (value || typeof value === 'number' ? undefined : 'Required');


export const findArrayElementByTitle = (array, status) => {

    return array.find((element) => {
      return element.id === status;
    })
  }