import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL, PRODUCT_URL,BUNDLE_PRODUCT_URL,BUNDLE_PRODUCT_REVIEW_URL,NO_TOKEN_FEATURE_PRODUCT_URL } from '../../constants/api'
import axios from 'axios';
import ReactStars from "react-rating-stars-component";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isValidURL} from '../../constants/HomeData'
import {RatingValueToPerchantage} from '../../constants/customMethod'
import moment from 'moment';

import ImageGallery from "react-image-gallery";

const BundleProductDetailsComponent = (props) => {
    const [data, setData] = useState({});
    const [attr, setAttr] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [featured, setFeatured] = useState([])
    const [images, setImages] = useState([]);
    const [featureNext, setFeatureNext] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [rating, setRating] = useState(5);
    const [comment, setComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [seletctedAttr,setSelectedAttr]=useState([]);
    const [productType, setProductType] = useState(1)

    const ratingChanged = (newRating) => {
        setRating(newRating)
    };


    const addToCart = (data) => {
        if (seletctedAttr.length === attr.length) {
            data["selected_attr"] = setSelectedAttr
            data["added_quantity"] = quantity
            props.addItemToCart(data)
        } else {
            alert("Please Select your product type !!")
        }
    }

    const addQuantity = () => {
        const newQuantity = quantity + 1;
        setQuantity(newQuantity)
    }

    const subQuantity = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity)
        }
    }
    const handleSubmit = (evt) => {
        evt.preventDefault();
        const data = {
            'email': email,
            'rating': rating,
            'name': name,
            'comment': comment,
            'bundle_product_id': props.id,
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };
        fetch(BUNDLE_PRODUCT_REVIEW_URL, requestOptions)
            .then(res => res.json())
            .then(result => {
                if (result.name) {
                    toast.success("Your has been reviewed successfully!!!")
                    setTimeout(() => {
                        window.location.reload();
                    }, 500)
                } else {
                    toast.warn("Something wrong here.please try again!!!")
                }
            });

    }

    useEffect(() => {
        // let id = props.id
        // fetchData(productType === 1 ? PRODUCT_URL + `&id=${id}` : VIRTUAL_PRODUCT_URL + `?id=${id}`).then(r => {
        // })
        fetchFeatureProduct(BUNDLE_PRODUCT_URL).then(r => {
        })
        if (localStorage.getItem('user')) {
            setIsLoggedIn(true)
        }
    }, [])
    const fetchFeatureProduct = async (url) => {
        const result = await axios(url);
        setFeatured(featured => [...featured, ...result.data.results]);
        setFeatureNext(result.data.next);
    }

    const fetchData = async (url) => {
        const result = await axios(url);
        setData(result.data.results[0]);
        console.log(result.data.results[0])
        const allImages = [];
        if (result.data.results[0]?.feature_image) {
            allImages.push({
                original: result.data.results[0]?.feature_image,
                thumbnail: result.data.results[0]?.feature_image,
            })
        }
        if (result.data.results[0]?.gallery_images && result.data.results[0]?.gallery_images.length > 0) {
            result.data.results[0]?.gallery_images.map(eachImage => {
                allImages.push({
                    original: 'http://54.254.205.44' + eachImage.image,
                    thumbnail: 'http://54.254.205.44' + eachImage.image,
                })
            })
        }
        setImages(allImages)
    }

    useEffect(() => {
        const id = props.id;
        fetchData(BUNDLE_PRODUCT_URL + `&id=${id}`).then(() => {
        })
        fetchFeatureProduct(NO_TOKEN_FEATURE_PRODUCT_URL).then(() => {
        })
    }, [])

    const imageRender = (imageItems) => {
        return (
            <ImageGallery items={imageItems} showFullscreenButton={false} showPlayButton={false} showNav={false}/>
        )
    }

    return (
        <main className="main mt-2">
            <div className="page-content mb-10">
                <div className="container">
                    <div className="product-navigation">
                        <ul className="breadcrumb breadcrumb-lg">
                            <li><Link to={"/"}><i className="d-icon-home"/></Link></li>
                            <li><Link to={"#"} className="active">Bundle</Link></li>
                            <li>Detail</li>
                        </ul>
                    </div>
                    <div className="row gutter-lg">
                        <aside className="col-lg-3 right-sidebar sidebar-fixed sticky-sidebar-wrapper">
                            <div className="sidebar-overlay">
                                <Link to={'#'} className="sidebar-close"><i className="d-icon-times"/></Link>
                            </div>
                            <Link to={'#'} className="sidebar-toggle"><i className="fas fa-chevron-left"/></Link>
                            <div className="sidebar-content">
                                <div className="sticky-sidebar">
                                    <div className="service-list mb-4">
                                        <div className="icon-box icon-box-side icon-box3">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-secure"/>
                                                </span>
                                            <div className="icon-box-content">
                                                <h4 className="icon-box-title">Secured Payment</h4>
                                                <p>We ensure secure payment!</p>
                                            </div>
                                        </div>
                                        <div className="icon-box icon-box-side icon-box1">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-truck"/>
                                                </span>
                                            <div className="icon-box-content">
                                                <h4 className="icon-box-title">Free Shipping</h4>
                                                <p>On all US orders above $99</p>
                                            </div>
                                        </div>
                                        <div className="icon-box icon-box-side icon-box2">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-money"/>
                                                </span>
                                            <div className="icon-box-content">
                                                <h4 className="icon-box-title">Money Back guarantee</h4>
                                                <p>Any back within 30 days</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget-products">
                                        <h4 className="widget-title">Feature Products</h4>
                                        <div className="widget-body">
                                            <div className="products-col">
                                                {featured.map((product, index) => (
                                                    <div className="products-col" key={index}>
                                                        <div className="product product-list-sm"
                                                             style={{alignItems: "start"}}>
                                                            <figure className="product-media" style={{height: "auto"}}>
                                                                <a href={'product-details?id=' + product.id}>
                                                                    <img
                                                                        src={product.feature_image ? isValidURL(product.feature_image) : ''}
                                                                        alt="product"
                                                                        width="100" height="100"/>
                                                                </a>
                                                            </figure>
                                                            <div className="product-details">
                                                                <h3 className="product-name">
                                                                    {product?.product_name}
                                                                </h3>
                                                                <div className="product-price">
                                                                    {
                                                                        product.discount_type === 1 || featured.discount_type === 2
                                                                            ? <span
                                                                                className="price">BDT {product.new_price}</span>
                                                                            : <span
                                                                                className="new-price">BDT {product.regular_price}</span>
                                                                    }
                                                                </div>
                                                                <div className="ratings-container">
                                                                    <div className="ratings-full">
                                                                        <span className="ratings"
                                                                              style={{width: RatingValueToPerchantage(product.avg_rating) + '%'}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Widget Products --> */}
                                </div>
                            </div>
                        </aside>
                        <div className="col-lg-9">
                            <div className="product product-single row mb-4">
                                <div className="col-md-6">
                                    {images.length > 0 ? imageRender(images) : null}
                                </div>
                                <div className="col-md-6">
                                    <div className="product-details">
                                        <div className="product-navigation">
                                            <h1 className="product-name">{data?.title}</h1>
                                        </div>
                                            
                                            <h5 className="product-name">[ {Object.keys(data).length>0 ? data.product_list.map(dt=> dt.product_name +', ') : ''} ]</h5>
                                            <p > Total Product: {Object.keys(data).length>0 ? data.total_quantity : ''}</p>
                                            <div className="ratings-container">
                                                <div className="ratings-full">
                                                    <span className="ratings" style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}/>
                                                    {/* <span className="tooltiptext tooltip-top"></span> */}
                                                </div>
                                                <a href="#product-tab-reviews"
                                                   className="link-to-tab rating-reviews">( {data.total_reviews} reviews
                                                    )</a>
                                            </div>
                                            <div className="product-meta">
                                            
                                                {/* SKU: <span className="product-sku">{Object.keys(data).length ? data.product_list[0].product_sku : ''}</span> */}
                                              
										        {/* BRAND: <span className="product-brand">{Object.keys(data).length>0 &&  data.product_list[0].brand!==null && data.product_list[0].brand!=='' ? data.product_list[0].brand : ''}</span> */}
                                            </div>
                                            {/* <div className="product-navigation">
                                                

                                            <h1 className="product-name">{Object.keys(data).length>0 ? data.title : ''}</h1>
                                            </div>
                                            <div className="product-navigation">
                                            <h5 className="product-name">{Object.keys(data).length>0 ? data.product_list[0].product_name : ''}</h5>
                                                
                                            </div> */}
                                        
                                    <div className="product-price">
                                            {Object.keys(data).length>0 ? ( 
                                            <>   
                                            {
                                                    data.discount_type === 1 ||  data.discount_type === 2 ? (
                                                        <ins className="new-price">BDT { data.total_price}</ins>

                                                    ) :
                                                        <ins className="new-price">BDT { data.total_price}</ins>

                                                }
                                                {
                                                   data.discount_type === 1 ||  data.discount_type === 2 ? (
                                                        <del
                                                            className="old-price">BDT { data.regular_price}</del>
                                                    ) : null

                                                }
                                            </>
                                            
                                            ):null
                                            
                                            }
                                    </div>
                                    <hr className="product-divider" />

                                            <div className="product-form product-qty">
                                                <label>QTY:</label>
                                                <div className="product-form-group">
                                                    <div className="input-group">
                                                        <button  onClick={()=>subQuantity()} className="quantity-minus d-icon-minus"></button>
                                                        <span className="quantity form-control">{quantity}</span>
                                                        <button onClick={()=>addQuantity()} className="quantity-plus d-icon-plus"></button>
                                                    </div>
                                                    <button  onClick={()=>addToCart(data)} className="btn-product btn-cart"><i className="d-icon-bag"></i>Add To
												Cart</button>
                                                </div>
                                            </div>

                                            <hr className="product-divider mb-3" />

                                            <div className="product-footer">
                                               
                                                <div className="product-action">
                                                    <a href="#" className="btn-product btn-wishlist"><i className="d-icon-heart"></i>Add To
												Wishlist</a>
                                                  
                                                  
                                                </div>
                                            </div>
                                            </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="product-details">
                                            <div className="product-navigation">
                                                <ul className="breadcrumb breadcrumb-lg">
                                                    <li><a href="demo1.html"><i className="d-icon-home"></i></a></li>
                                                    <li><a href="#" className="active">Products</a></li>
                                                    <li>Detail</li>
                                                </ul>

                                                
                                            </div>

                                            <h1 className="product-name">{Object.keys(data).length>0 ? data.product_list[0].product_name : ''}</h1>
                                            <div className="product-meta">
                                                SKU: <span className="product-sku">{Object.keys(data).length ? data.product_list[0].product_sku : ''}</span>
                                              
										        BRAND: <span className="product-brand">{Object.keys(data).length>0 &&  data.product_list[0].brand!==null && data.product_list[0].brand!=='' ? data.product_list[0].brand.name : ''}</span>
                                            </div>
                                           
                                            <div className="product-price">
                                            {Object.keys(data).length>0 ? ( 
                                            <>   
                                            {
                                                    data.discount_type === 1 ||  data.discount_type === 2 ? (
                                                        <ins className="new-price">BDT { data.new_price}</ins>

                                                    ) :
                                                        <ins className="new-price">BDT { data.new_price}</ins>

                                                }
                                                {
                                                   data.discount_type === 1 ||  data.discount_type === 2 ? (
                                                        <del
                                                            className="old-price">BDT { data.regular_price}</del>
                                                    ) : null

                                                }
                                            </>
                                            
                                            ):null
                                            
                                            }
                                        <p className="product-short-desc">
                                            <ul>{data?.product_list && data?.product_list.length > 0 && data?.product_list.map(item =>
                                                <li>{item.product_name}</li>)}</ul>
                                        </p>
                                        <div className="product-price">
                                            <ins className="new-price">BDT {data?.total_price}</ins>
                                            <del className="old-price">BDT {data?.total_regular_price}</del>
                                        </div>
                                        <hr className="product-divider"/>
                                        <div className="product-form product-qty">
                                            <label>QTY:</label>
                                            <div className="product-form-group">
                                                <div className="product-variations">
                                                {attribute.attrbute_option.map((option, optindex) => ( 
                                                    <a  onClick={()=>selecctAttr(index,optindex)} className={option.active === true ? "size active" : 'size'} href="#">{option.attr_option_name}</a>
                                                ))}         
                                                   
                                                </div>
                                                <a href="#" className="product-variation-clean">Clean All</a>
                                            </div>
                                        </div>
                                       
                                            }
                                            </>
                                                ))}

                                            <hr className="product-divider" />

                                            <div className="product-form product-qty">
                                                <label>QTY:</label>
                                                <div className="product-form-group">
                                                    <div className="input-group">
                                                        <button  onClick={()=>subQuantity()} className="quantity-minus d-icon-minus"></button>
                                                        <span className="quantity form-control">{quantity}</span>
                                                        <button onClick={()=>addQuantity()} className="quantity-plus d-icon-plus"></button>
                                                    </div>
                                                    <button  onClick={()=>addTocart(data)} className="btn-product btn-cart"><i className="d-icon-bag"></i>Add To
												Cart</button>
                                                </div>
                                            </div>

                                            <hr className="product-divider mb-3" />

                                            <div className="product-footer">
                                               
                                                <div className="product-action">
                                                    <a href="#" className="btn-product btn-wishlist"><i className="d-icon-heart"></i>Add To
												Wishlist</a>
                                                  
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="tab tab-nav-simple product-tabs mb-4">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active"
                                               href="#product-tab-description">Description</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-video">Product Video</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-shipping-returns">Shipping &amp;
										Returns</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-reviews">Reviews
                                                ({data.total_reviews})</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active in" id="product-tab-description">
                                            <p>
                                            {data.is_whole_sell ?         
                                                <strong> Order {data.purchase_quantity} units and get wholesale Price {data.whole_sell_price} Taka</strong>
                                            :null

                                            }
                                                {Object.keys(data).length > 0 ? <div
                                                dangerouslySetInnerHTML={{__html: data.product_summary}}/> : ''}</p>
                                        </div>
                                        <div className="tab-pane" id="product-tab-video">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    <iframe className="my-auto mx-auto"
                                                            src={Object.keys(data).length > 0 ? data.youtube_url : ''}
                                                            target="_parent" width="535" height="300"
                                                            style={{border: "none", overflow: "hidden"}} scrolling="no"
                                                            frameBorder="0" allowtransparency="true"
                                                            allowFullScreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane " id="product-tab-reviews">
                                            <div className="d-flex align-items-center mb-5">
                                                <h4 className="mb-0 mr-2">Average Rating:</h4>
                                                <div className="ratings-container average-rating mb-0">
                                                    <div className="ratings-full">
                                                        <span className="ratings"
                                                              style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}></span>
                                                        {/* <span className="tooltiptext tooltip-top">{data.avg_rating}</span> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="comments mb-6">
                                                <ul>
                                                    {Object.keys(data).length > 0 && data.bundle_product_reviews.map((review, index) => (
                                                        <li>
                                                            <div className="comment">
                                                                <figure className="comment-media">
                                                                    <a href="#">
                                                                        <img src='/static/images/no-image.png'
                                                                             alt="avatar"/>
                                                                    </a>
                                                                </figure>
                                                                <div className="comment-body">
                                                                    <div
                                                                        className="comment-rating ratings-container mb-0">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings"
                                                                                  style={{width: RatingValueToPerchantage(review.rating) + '%'}}></span>
                                                                            {/* <span className="tooltiptext tooltip-top">{review.rating}</span> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="comment-user">
                                                                        <h4><a href="#">{review.name}</a></h4>
                                                                        <span
                                                                            className="comment-date">{moment(review.created_at).format("DD MMM YYYY hh:mm:ss A")}
																	</span>
                                                                    </div>

                                                                    <div className="comment-content">
                                                                        <p>{review.comment}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            {isLoggedIn && <div className="reply">
                                                <div className="title-wrapper text-left">
                                                    <h3 className="title title-simple text-left text-normal">Add a
                                                        Review</h3>
                                                    <p>Your email address will not be published. Required fields are
                                                        marked
                                                        *</p>
                                                </div>
                                                <form action="#" onSubmit={handleSubmit}>
                                                    <div className="rating-form">
                                                        <label htmlFor="rating">Your rating: </label>
                                                        <ReactStars
                                                            count={rating}
                                                            onChange={ratingChanged}
                                                            size={20}
                                                            activeColor="#fd807f"
                                                        />
                                                    </div>
                                                    <textarea id="reply-message" cols="30" rows="4" name="comment"
                                                              onChange={e => {
                                                                  const val = e.target.value;
                                                                  setComment(val)
                                                              }} value={comment} className="form-control mb-4"
                                                              placeholder="Comment *" required/>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-5">
                                                            <input type="text" className="form-control" id="reply-name"
                                                                   onChange={e => {
                                                                       const val = e.target.value;
                                                                       setName(val)
                                                                   }} value={name} name="name" placeholder="Name *"
                                                                   required/>
                                                        </div>
                                                        <div className="col-md-6 mb-5">
                                                            <input type="email" className="form-control"
                                                                   id="reply-email" onChange={e => {
                                                                const val = e.target.value;
                                                                setEmail(val)
                                                            }} value={email} name="email" placeholder="Email *"
                                                                   required/>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-md">Submit
                                                        <i className="d-icon-arrow-right"/></button>
                                                </form>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab tab-nav-simple product-tabs mb-4">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#product-tab-description">Description</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-video">Product Video</a>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <a className="nav-link" href="#product-tab-reviews">Reviews (8)</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active in" id="product-tab-description">
                                            <p>{Object.keys(data).length>0 ? <div dangerouslySetInnerHTML={{__html: data.product_summary}} /> : ''}</p>
                                        </div>
                                        <div className="tab-pane" id="product-tab-video">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    <iframe className="my-auto mx-auto" src={Object.keys(data).length>0 ? data.youtube_url : ''} target="_parent" width="535" height="300" style={{ border: "none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane " id="product-tab-reviews">
                                            <div className="d-flex align-items-center mb-5">
                                                <h4 className="mb-0 mr-2">Average Rating:</h4>
                                                <div className="ratings-container average-rating mb-0">
                                                    <div className="ratings-full">
                                                        <span className="ratings" style={{ width: "80%" }}></span>
                                                        <span className="tooltiptext tooltip-top">4.00</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="comments mb-6">
                                                <ul>
                                                    <li>
                                                        <div className="comment">
                                                            <figure className="comment-media">
                                                                <a href="#">
                                                                    <img src="static/images/blog/comments/1.jpg" alt="avatar" />
                                                                </a>
                                                            </figure>
                                                            <div className="comment-body">
                                                                <div className="comment-rating ratings-container mb-0">
                                                                    <div className="ratings-full">
                                                                        <span className="ratings" style={{ width: "80%" }}></span>
                                                                        <span className="tooltiptext tooltip-top">4.00</span>
                                                                    </div>
                                                                </div>
                                                                <div className="comment-user">
                                                                    <h4><a href="#">Jimmy Pearson</a></h4>
                                                                    <span className="comment-date">November 9, 2018 at 2:19
																	pm</span>
                                                                </div>

                                                                <div className="comment-content">
                                                                    <p>Sed pretium, ligula sollicitudin laoreet viverra,
                                                                    tortor libero sodales leo, eget blandit nunc tortor
                                                                    eu nibh. Nullam mollis. Ut justo. Suspendisse
                                                                    potenti.
                                                                    Sed egestas, ante et vulputate volutpat, eros pede
                                                                    semper est, vitae luctus metus libero eu augue.
                                                                    Morbi purus libero, faucibus adipiscing, commodo
                                                                    quis, avida id, est. Sed lectus. Praesent elementum
                                                                    hendrerit tortor. Sed semper lorem at felis.
																Vestibulum volutpat.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="comment">
                                                            <figure className="comment-media">
                                                                <a href="#">
                                                                    <img src="static/images/blog/comments/3.jpg" alt="avatar" />
                                                                </a>
                                                            </figure>

                                                            <div className="comment-body">
                                                                <div className="comment-rating ratings-container mb-0">
                                                                    <div className="ratings-full">
                                                                        <span className="ratings" style={{ width: "80%" }}></span>
                                                                        <span className="tooltiptext tooltip-top">4.00</span>
                                                                    </div>
                                                                </div>
                                                                <div className="comment-user">
                                                                    <h4><a href="#">Johnathan Castillo</a></h4>
                                                                    <span className="comment-date">November 9, 2018 at 2:19
																	pm</span>
                                                                </div>

                                                                <div className="comment-content">
                                                                    <p>Vestibulum volutpat, lacus a ultrices sagittis, mi
                                                                    neque euismod dui, eu pulvinar nunc sapien ornare
                                                                    nisl. Phasellus pede arcu, dapibus eu, fermentum et,
																dapibus sed, urna.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                          
                                            <div className="reply">
                                                <div className="title-wrapper text-left">
                                                    <h3 className="title title-simple text-left text-normal">Add a Review</h3>
                                                    <p>Your email address will not be published. Required fields are marked
												*</p>
                                                </div>
                                                <div className="rating-form">
                                                    <label for="rating">Your rating: </label>
                                                    <span className="rating-stars selected">
                                                        <a className="star-1" href="#">1</a>
                                                        <a className="star-2" href="#">2</a>
                                                        <a className="star-3" href="#">3</a>
                                                        <a className="star-4 active" href="#">4</a>
                                                        <a className="star-5" href="#">5</a>
                                                    </span>

                                                    <select name="rating" id="rating" required="" style={{ display: "none" }}>
                                                        <option value="">Rate…</option>
                                                        <option value="5">Perfect</option>
                                                        <option value="4">Good</option>
                                                        <option value="3">Average</option>
                                                        <option value="2">Not that bad</option>
                                                        <option value="1">Very poor</option>
                                                    </select>
                                                </div>
                                                <form action="#">
                                                    <textarea id="reply-message" cols="30" rows="4"
                                                        className="form-control mb-4" placeholder="Comment *"
                                                        required></textarea>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-5">
                                                            <input type="text" className="form-control" id="reply-name"
                                                                name="reply-name" placeholder="Name *" required />
                                                        </div>
                                                        <div className="col-md-6 mb-5">
                                                            <input type="email" className="form-control" id="reply-email"
                                                                name="reply-email" placeholder="Email *" required />
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-md">Submit<i
                                                        className="d-icon-arrow-right"></i></button>
                                                </form>
                                                <div className="input-group">
                                                    <button onClick={() => subQuantity()}
                                                            className="quantity-minus d-icon-minus"/>
                                                    <span
                                                        className="quantity form-control d-flex justify-content-center align-items-center">{quantity}</span>
                                                    <button onClick={() => addQuantity()}
                                                            className="quantity-plus d-icon-plus"/>
                                                </div>
                                                <button onClick={() => addToCart(data)}
                                                        className="btn-product btn-cart">
                                                    <i className="d-icon-bag"/>Add To Cart
                                                </button>
                                            </div>
                                        </div>
                                        <hr className="product-divider mb-3"/>
                                        <div className="product-footer">
                                            <div className="product-action">
                                                <a href="#" className="btn-product btn-wishlist">
                                                    <i className="d-icon-heart"/>Add To Wishlist
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default BundleProductDetailsComponent
