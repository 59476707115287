import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { NO_TOKEN_SELLERS,PRODUCT_URL } from '../../constants/api'
import { addToCart,addItemToWishList } from '../../actions/cartActions'


class SellerPageContainer extends Component {

    static propTypes = {
        Layout: PropTypes.func.isRequired,
        addItemToCart: PropTypes.func.isRequired,
        addItemToWishList: PropTypes.func.isRequired,  
    }

    constructor(props) {
        super(props)
        this.state = { 
            shop_info:'',
            products:[],
            total_showing:0,
            count:0
        }
        this.addItemToCart = this.addItemToCart.bind(this);
        this.addItemToWishList = this.addItemToWishList.bind(this);
        
    
        // this.addItemToCart=this.addItemToCart.bind(this)
      }

      addItemToCart (data)  {
          this.props.addToCart(data)
       }
       addItemToWishList (data)  {    
        this.props.addItemToWishList(data)
       }
  
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var shop_id = query.get('id')
        this.fetchShopInfo(NO_TOKEN_SELLERS+"/"+shop_id)
        this.fetchShopRelatedproduct(PRODUCT_URL+'&vendor__id='+shop_id)
        // var id = query.get('id')
        // alert(id)
    }


    fetchShopInfo = (url) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          
        };
   // alert(url)
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(response => {
                
                this.setState({shop_info:response})
              
            });
    }

    fetchShopRelatedproduct = (url) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          
        };
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(response => {
                this.setState({products:response.results,count:response.count,total_showing:response.results.length})
              
            });
    }

    render = () => {
        const { Layout } = this.props;

        // const { error } = this.state;

        return (
            <div>
                <Layout 
                    state={this.state}
                    addItemToCart={this.addItemToCart} 
                    addItemToWishList={this.addItemToWishList}
                    // products={this.state.products}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        addToCart: (data)=>{dispatch(addToCart(data))},
        addItemToWishList: (data)=>{dispatch(addItemToWishList(data))},
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SellerPageContainer)

