import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import axios from 'axios';
import {NO_TOKEN_BRANDS} from '../../constants/api'

const BrandsComponent = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                NO_TOKEN_BRANDS,
            );
            setData(result.data.results);
        };

        fetchData().then(() => {
        })
    }, []);

    return (
        <div>
            <main className="main container">
                <div className="page-header bg-dark"
                     style={{backgroundImage: `url(${"static/images/shop/bgbanner.png"})`, position: 'relative'}}>
                    <h1 className="page-title text-white z-index">Brands</h1>
                    <ul className="breadcrumb">
                        <li className="text-white z-index"><Link to={"/"}><i className="d-icon-home"/></Link></li>
                        <li className="text-white z-index">Brands</li>
                    </ul>
                </div>

                <div className="mt-lg-10 mb-lg-10">
                    <div className="row">
                        {data.map(item => (
                            <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 pt-3 pb-3 bg-white">
                                <div className="card shadow">
                                    <div className="card-body">
                                        <a href={"/brand?brand__id=" + item.id}>
                                            {item.image !== null && item.image !== '' && item.image !== undefined ? (
                                                <img className="rounded-circle" src={item.image} alt="..." style={{
                                                    height: "150px",
                                                    width: "150px",
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    padding: "15px"
                                                }}/>
                                            ) : <img className="rounded-circle" src='/static/images/no-image.png'
                                                     alt="..." style={{
                                                height: "150px",
                                                width: "150px",
                                                display: "block",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                padding: "15px"
                                            }}/>
                                            }
                                        </a>
                                    </div>
                                    <div className="card-footer w-100 text-center h-100 shadow w-100"
                                         style={{background: "#99ee99", marginTop: "-6px"}}>
                                        <h6 className="pt-4">
                                            <a className="text-black font-weight-normal text-uppercase"
                                               href={"/brand?brand__id=" + item.id}> {item.name}</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </div>
    )
}

export default BrandsComponent
