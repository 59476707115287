// noinspection DuplicatedCode

import React, { useState, useEffect} from 'react'

import {isValidURL} from '../constants/HomeData'
import {RatingValueToPerchantage} from './customMethod'
import "./modal.css"
import Modals from "react-modal";
import {GlassMagnifier} from "react-image-magnifiers";
import {Link} from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { BASE_URL } from './api';

Modals.setAppElement("#root");

export const Modal = ({canShow, updateModalState, data, addItemToCart, addItemToWishList, showOldPrice}) => {
    const [quantity, setQuantity] = useState(1);
    const [attr, setAttr] = useState([]);
    const [seletctedAttr, setSelectedAttr] = useState([]);

    const addQuantity = () => {
        const newquantity = quantity + 1;
        setQuantity(newquantity)
    }

    const subQuantity = () => {
        if (quantity > 1) {
            const newquantity = quantity - 1;
            setQuantity(newquantity)
        }
    }

    const addTocart = (data) => {
        if (seletctedAttr.length === attr.length) {
            data["selected_attr"] = setSelectedAttr
            data["added_quantity"] = quantity
            addItemToCart(data)
        } else {
            alert("Please Select your Required attribute option  !!")
        }
    }

    const selecctAttr = (index, attrindex) => {
        const selected_attr = seletctedAttr;
        const attr_id = attr[index].attrbute_id;
        const attr_option = attr[index].attrbute_option[attrindex].attr_option_id;
        const check = selected_attr.findIndex(x => x.attrbute_id === attr_id);
        if (check === -1) {
            const options_obj = {
                'attrbute_id': attr_id,
                'attr_option_id': attr_option,
            };
            selected_attr.push(options_obj)
        } else {
            selected_attr[check].attr_option_id = attr_option
        }
        attr[index].attrbute_option.map(a => a.active = false);
        attr[index].attrbute_option[attrindex].active = true
        setAttr(attr)
        setSelectedAttr(selected_attr)
    }

    useEffect(() => {
        let options_obj;
        if (canShow) {
            setQuantity(1)
            const allImages = [];
            if (data?.feature_image) {
                allImages.push({
                    original: data?.feature_image,
                    thumbnail: data?.feature_image,
                })
            }
            if (data?.gallery_images && data?.gallery_images.length > 0) {
                data?.gallery_images.map(eachImage => {
                    allImages.push({
                        original: BASE_URL + eachImage.image,
                        thumbnail: BASE_URL + eachImage.image,
                    })
                })
            }
            setImages(allImages)
            const details = data.product_details_info;
            const attr_data = [];
            if (details.length > 0) {
                for (let i = 0; i < details.length; i++) {
                    for (let j = 0; j < details[i].attributeoption.length; j++) {
                        const check = attr_data.findIndex(x => x.attrbute_id === details[i].attribute);
                        if (check === -1) {
                            const options = [];
                            options_obj = {
                                'attr_option_id': details[i].attributeoption[j].id,
                                'attr_option_name': details[i].attributeoption[j].name,
                                'attr_color_code': details[i].attributeoption[j].color_code,
                                'active': false
                            };
                            options.push(options_obj)
                            const attr = {
                                'attrbute_id': details[i].attribute,
                                'attribute_name': details[i].attributeoption[j].attribute,
                                'attrbute_option': options
                            };
                            attr_data.push(attr)
                        } else {
                            options_obj = {
                                'attr_option_id': details[i].attributeoption[j].id,
                                'attr_option_name': details[i].attributeoption[j].name,
                                'attr_color_code': details[i].attributeoption[j].color_code,
                                'active': false
                            };
                            const attrbute_options = attr_data.find(x => x.attrbute_id === details[i].attribute).attrbute_option;
                            attrbute_options.push(options_obj)
                            attr_data[check].attrbute_option = attrbute_options
                        }
                    }
                }
                attr_data.reverse()
                setAttr(attr_data)
            }
        }
    }, [data]);


    // const selecctAttr =(index,attrindex)=>{
    //     var selected_attr=seletctedAttr
    //     var attr_id = attr[index].attrbute_id
    //     var attr_option = attr[index].attrbute_option[attrindex].attr_option_id
    //     var check = selected_attr.findIndex(x => x.attrbute_id === attr_id)
    //     if(check===-1){
    //         var options_obj = {
    //             'attrbute_id': attr_id,
    //             'attr_option_id': attr_option, 
    //         } 
    //         selected_attr.push(options_obj)
    //     }else{
    //         selected_attr[check].attr_option_id= attr_option       
    //     }
    //     attr[index].attrbute_option.map(a=>a.active=false);
    //     attr[index].attrbute_option[attrindex].active=true
    //    // setAttr(attr)
    //     setSelectedAttr(selected_attr)
    // }

    const imageRender = (imageItems) => {
        return (
            <ImageGallery items={imageItems} showFullscreenButton={false} showPlayButton={false} showNav={true} showBullets={true} showThumbnails={false} bulletClass={"danger"}/>
        )
    }

    const [images, setImages] = useState([]);

    if (canShow) {
        return (
            <div id="ex1" className="mymodal quick-view" style={{height: 'auto'}}>
                <div className="product product-single row mb-4 product-popup">
                    <div className="col-md-6">
                        <div className="product-gallery -pg-vertical">
                            {images.length > 0 ? imageRender(images) : null}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="product-details scrollable">
                            <h2 className="product-name"><a
                                href={"/product-details?id=" + data.id}>{data.product_name}</a></h2>
                            <div className="product-meta">
                                SKU: <span className="product-sku">{data.product_sku}</span>
                                BRAND: <span
                                className="product-brand">{data.brands !== null && data.brands !== '' ? data.brands.name : ''}</span>
                            </div>
                            <div className="product-meta">
                                                Product weight: <span
                                                className="product-sku">{Object.keys(data).length ? (data.unit_amount? data.unit_amount:'') + ' '+ (data.product_units !==null && data.product_units !=='' ?data.product_units.name:'') : ''}</span>

                                                
                                            </div>
                            <div className="product-price">

                                {
                                    data.discount_type === 1 || data.discount_type === 2 ? (
                                            <ins className="product-price">৳ {data.new_price}</ins>

                                        ) :
                                        <ins className="product-price">৳ {data.new_price}</ins>

                                }
                                {showOldPrice ? (
                                    <>
                                        {
                                            data.discount_type === 1 || data.discount_type === 2 ? (
                                                <del
                                                    className="product-price">৳ {data.regular_price}</del>
                                            ) : null

                                        }
                                    </>
                                ) : null
                                }
                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings"
                                          style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}/>
                                    {/* <span className="tooltiptext tooltip-top"></span> */}
                                </div>
                                {/* <a href="#product-tab-reviews" className="link-to-tab rating-reviews">( 6 reviews )</a> */}
                            </div>
                            <p className="product-short-desc">
                                <div dangerouslySetInnerHTML={{__html: data.product_summary}}/>
                            </p>
                            {attr.map((attribute, index) => (
                                <>
                                    {attribute.attribute_name.toLowerCase() === 'color' || attribute.attribute_name.toLowerCase() === 'colour' ?
                                        <div className="product-form product-color" key={index}>
                                            <label>{attribute.attribute_name}:</label>
                                            <div className="product-variations">
                                                {attribute.attrbute_option.map((option, attrindex) => (
                                                    <Link onClick={() => selecctAttr(index, attrindex)}
                                                          className={option.active === true ? "color active" : 'color'}
                                                          data-src="" style={{
                                                        backgroundColor: option.attr_color_code,
                                                        cursor: 'pointer'
                                                    }}/>
                                                ))}
                                            </div>
                                        </div>
                                        :
                                        <div className="product-form product-size">
                                            <label>{attribute.attribute_name}:</label>
                                            <div className="product-form-group">
                                                <div className="product-variations">
                                                    {attribute.attrbute_option.map((option, optindex) => (
                                                        <Link onClick={() => selecctAttr(index, optindex)}
                                                              className={option.active === true ? "size active" : 'size'}
                                                              style={{cursor: 'pointer'}}>{option.attr_option_name}</Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                            <hr className="product-divider"/>
                            <div className="product-form product-qty">
                                <label>QTY:</label>
                                <div className="product-form-group">
                                    <div className="input-group">
                                        <button onClick={() => subQuantity()} className="quantity-minus d-icon-minus"/>
                                        <span
                                            className="quantity form-control d-flex justify-content-center align-items-center">{quantity}</span>
                                        <button onClick={() => addQuantity()} className="quantity-plus d-icon-plus"/>
                                    </div>
                                    { data?.stock_quantity > 0 || data?.is_pre_order === true ?

                                    <a style={{cursor: 'pointer'}} onClick={() => addTocart(data)}
                                    className={data?.stock_quantity > 0 || data?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}><i className="d-icon-bag"/>Add To Cart</a>
                                       :
                                       <a style={{cursor: 'pointer'}} onClick={() => addTocart(data)}
                                       className={data?.stock_quantity > 0 || data?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}><i className="d-icon-bag"/>Out Of Stock</a>
                                    }

                                    <a style={{cursor: 'pointer'}} onClick={addItemToWishList}
                                       className="btn-product btn-cart"><i className="d-icon-heart"/>Add To Wishlist</a>
                                    <button onClick={updateModalState} className="btn-product btn-cart ">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};


// *****************BUNDLE MODAL **********************


export const BundleModal = ({canShow, updateModalState, data, addItemToCart, addItemToWishList, showOldPrice}) => {

    const [quantity, setQuantity] = useState(1);
    const [attr, setAttr] = useState([]);
    const [seletctedAttr, setSelectedAttr] = useState([]);

    const addQuantity = () => {
        var newquantity = quantity + 1
        setQuantity(newquantity)
    }

    const subQuantity = () => {
        if (quantity > 1) {
            var newquantity = quantity - 1
            setQuantity(newquantity)
        }

    }
    const addTocart = (data) => {
        if (seletctedAttr.length === attr.length) {
            data["selected_attr"] = setSelectedAttr
            data["added_quantity"] = quantity
            addItemToCart(data)
        } else {
            alert("Please Select your Required attribute option  !!")
        }
    }


    // const selecctAttr =(index,attrindex)=>{
    //     var selected_attr=seletctedAttr
    //     var attr_id = attr[index].attrbute_id
    //     var attr_option = attr[index].attrbute_option[attrindex].attr_option_id

    //     var check = selected_attr.findIndex(x => x.attrbute_id === attr_id)
    //     if(check===-1){
    //         var options_obj = {
    //             'attrbute_id': attr_id,
    //             'attr_option_id': attr_option, 
    //         } 
    //         selected_attr.push(options_obj)
    //     }else{
    //         selected_attr[check].attr_option_id= attr_option       
    //     }
    //     attr[index].attrbute_option.map(a=>a.active=false);
    //     attr[index].attrbute_option[attrindex].active=true
    //     setAttr(attr)
    //     setSelectedAttr(selected_attr)


    // }
    useEffect(() => {
        if (canShow) {
            setQuantity(1)
            var details = data.product_list
            var attr_data = []
            //     if(details.length>0){
            //         for (var i=0;i<details.length;i++){
            //             for(var j=0;j<details[i].attributeoption.length;j++){

            //             var check = attr_data.findIndex(x => x.attrbute_id === details[i].attribute);
            //             if (check === -1) {
            //                 var options=[]
            //                 var options_obj = {
            //                     'attr_option_id': details[i].attributeoption[j].id,
            //                     'attr_option_name': details[i].attributeoption[j].name,
            //                     'attr_color_code': details[i].attributeoption[j].color_code,
            //                     'active':false
            //                 }
            //                 options.push(options_obj)
            //                 var attr={
            //                     'attrbute_id':details[i].attribute,
            //                     'attribute_name':details[i].attributeoption[j].attribute,
            //                     'attrbute_option':options
            //                 }
            //                 attr_data.push(attr)
            //             }else{
            //                 var options_obj = {
            //                     'attr_option_id': details[i].attributeoption[j].id,
            //                     'attr_option_name': details[i].attributeoption[j].name,
            //                     'attr_color_code': details[i].attributeoption[j].color_code,
            //                     'active':false
            //                 }

            //                 var attrbute_options = attr_data.find(x => x.attrbute_id === details[i].attribute).attrbute_option
            //                 attrbute_options.push(options_obj)
            //                 // attrbute_options.reverse()
            //                 attr_data[check].attrbute_option = attrbute_options

            //                 }
            //             }
            //         }
            //         attr_data.reverse()
            //         setAttr(attr_data)
            // }
        }
    }, [data]);


    // const selecctAttr =(index,attrindex)=>{
    //     var selected_attr=seletctedAttr
    //     var attr_id = attr[index].attrbute_id
    //     var attr_option = attr[index].attrbute_option[attrindex].attr_option_id

    //     var check = selected_attr.findIndex(x => x.attrbute_id === attr_id)
    //     if(check===-1){
    //         var options_obj = {
    //             'attrbute_id': attr_id,
    //             'attr_option_id': attr_option, 
    //         } 
    //         selected_attr.push(options_obj)
    //     }else{
    //         selected_attr[check].attr_option_id= attr_option       
    //     }
    //     attr[index].attrbute_option.map(a=>a.active=false);
    //     attr[index].attrbute_option[attrindex].active=true
    //    // setAttr(attr)
    //     setSelectedAttr(selected_attr)


    // }


    if (canShow) {
        // alert(JSON.stringify(data))
        return (
            <div className="mymodal" style={{maxWidth: "100%"}}>
                <div className="product product-single row mb-4">
                    <div className="col-md-6">
                        <div className="product-gallery pg-vertical">

                            <div class="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1">
                                {Object.keys(data).length > 0 ?

                                    <>
                                        <GlassMagnifier
                                            key={0}
                                            imageSrc={data.feature_image ? data.feature_image : ''}
                                            imageAlt={data.product_name}
                                            largeImageSrc={data.feature_image} // Optional
                                            style={{
                                                magnifierSize: "25%",
                                                allowOverflow: false,
                                                magnifierBorderColor: "rgba(255,255,255,.5)",
                                                magnifierBorderSize: 5,
                                                magnifierOffsetX: 0,
                                                magnifierOffsetY: 0,
                                                square: "Square"

                                            }}
                                        />
                                        {/* <figure className="product-image" key={0}>
                        <img src={data.feature_image}
                        data-zoom-image={data.feature_image}
                        alt={data.product_name} width="800" height="900" />
                    </figure> */}
                                        {data.gallery_images.map((gallery, index) => (
                                            <GlassMagnifier
                                                key={index + 1}
                                                imageSrc={isValidURL(gallery.image)}
                                                imageAlt={data.product_name}
                                                largeImageSrc={isValidURL(gallery.image)} // Optional
                                                style={{
                                                    magnifierSize: "25%",
                                                    allowOverflow: true,
                                                    magnifierBorderColor: "rgba(255,255,255,.5)",
                                                    magnifierBorderSize: 5,
                                                    magnifierOffsetX: 100,
                                                    magnifierOffsetY: 100,
                                                    square: "Square"

                                                }}
                                            />

                                            // <figure className="product-image" key={index+1}>
                                            //     <img src={BASE_URL+gallery.image}
                                            //         data-zoom-image={BASE_URL+gallery.image}
                                            //         alt={data.product_name} width="800" height="900" />
                                            // </figure>
                                        ))
                                        }
                                    </>


                                    : null
                                }
                            </div>
                            <div class="product-thumbs-wrap">
                                <div class="product-thumbs">
                                    {Object.keys(data).length > 0 ?

                                        <>
                                            <GlassMagnifier
                                                className="product-thumb active"
                                                key={0}
                                                imageSrc={data.feature_image ? data.feature_image : ''}
                                                imageAlt={data.product_name}
                                                largeImageSrc={data.feature_image} // Optional
                                                style={{
                                                    magnifierSize: "25%",
                                                    allowOverflow: false,
                                                    magnifierBorderColor: "rgba(255,255,255,.5)",
                                                    magnifierBorderSize: 5,
                                                    magnifierOffsetX: 0,
                                                    magnifierOffsetY: 0,
                                                    square: "Square"

                                                }}
                                            />
                                            {/* <div className="product-thumb active" key={0}>
                    <img src={data.feature_image} 
                        alt="product thumbnail" width="109" height="122" />
                    </div>     */}

                                            {data.gallery_images.map((gallery, index) => (
                                                <GlassMagnifier
                                                    className="product-thumb"
                                                    key={index + 1}
                                                    imageSrc={isValidURL(gallery.image)}
                                                    imageAlt={data.product_name}
                                                    largeImageSrc={isValidURL(gallery.image)} // Optional
                                                    style={{
                                                        magnifierSize: "25%",
                                                        allowOverflow: true,
                                                        magnifierBorderColor: "rgba(255,255,255,.5)",
                                                        magnifierBorderSize: 5,
                                                        magnifierOffsetX: 100,
                                                        magnifierOffsetY: 100,
                                                        square: "Square"

                                                    }}
                                                />

                                                // <div className="product-thumb" key={index+1}>
                                                // <img src={BASE_URL+gallery.image}
                                                //     alt="product thumbnail" width="109" height="122" />
                                                // </div>
                                            ))
                                            }

                                        </>
                                        : null
                                    }

                                </div>

                                <button className="thumb-up disabled"><i className="fas fa-chevron-left"></i></button>
                                <button className="thumb-down disabled"><i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="product-details scrollable">
                            <h2 className="product-name"><a
                                href={"/bundle-product-details?id=" + data.id}>{data.product_list[0].product_name}</a>
                            </h2>
                            <div className="product-meta">
                                SKU: <span
                                className="product-sku">{data.product_list !== null ? data.product_list[0].product_sku : ''}</span>
                                {/* BRAND: <span className="product-brand">{data.brands!==null && data.brands!=='' ? data.brands.name : ''}</span> */}
                            </div>
                            <div className="product-price">

                                {
                                    data.discount_type === 1 || data.discount_type === 2 ? (
                                            <ins className="new-price">৳ {data.total_price}</ins>

                                        ) :
                                        <ins className="new-price">৳ {data.total_price}</ins>

                                }
                                {showOldPrice ? (
                                    <>
                                        {
                                            data.product_list[0].discount_type === 1 || data.product_list[0].discount_type === 2 ? (
                                                <del
                                                    className="old-price">৳ {data.product_list[0].regular_price}</del>
                                            ) : null

                                        }
                                    </>
                                ) : null
                                }


                            </div>
                            <div className="ratings-container">
                                <div className="ratings-full">
                                    <span className="ratings"
                                          style={{width: RatingValueToPerchantage(data.avg_rating) + '%'}}></span>
                                    {/* <span className="tooltiptext tooltip-top"></span> */}
                                </div>
                                {/* <a href="#product-tab-reviews" className="link-to-tab rating-reviews">( 6 reviews )</a> */}
                            </div>
                            <p className="product-short-desc">
                                <div dangerouslySetInnerHTML={{__html: data.product_summary}}/>
                            </p>
                            {/* {attr.map((attribute, index) => (
                            <>  
                            {attribute.attribute_name.toLowerCase()==='color' || attribute.attribute_name.toLowerCase()==='colour' ?   
                            <div className="product-form product-color" key={index}>
                                <label>{attribute.attribute_name}:</label>
                                <div className="product-variations">
                                {attribute.attrbute_option.map((option, attrindex) => ( 
                                    <a onClick={()=>selecctAttr(index,attrindex)} className={option.active === true ? "color active" : 'color'} data-src=""
                                         style={{ backgroundColor: option.attr_color_code,cursor:'pointer' }}></a>
                                ))}      
                                    
                                </div>
                            </div>
                            :
                        
                        <div className="product-form product-size">
                            <label>{attribute.attribute_name}:</label>
                            <div className="product-form-group">
                                <div className="product-variations">
                                {attribute.attrbute_option.map((option, optindex) => ( 
                                    <a   onClick={()=>selecctAttr(index,optindex)} className={option.active === true ? "size active" : 'size'} style={{cursor:'pointer'}}>{option.attr_option_name}</a>
                                ))}         
                                    
                                </div>
                                
                            </div>
                        </div>
                        
                            }
                            </>
                    ))} */}


                            <hr className="product-divider"/>

                            <div className="product-form product-qty">
                                <label>QTY:</label>
                                <div className="product-form-group">
                                    <div className="input-group">
                                        <button onClick={() => subQuantity()}
                                                className="quantity-minus d-icon-minus"></button>
                                        <span className="quantity form-control">{quantity}</span>
                                        <button onClick={() => addQuantity()}
                                                className="quantity-plus d-icon-plus"></button>
                                    </div>
                                    <a onClick={() => addTocart(data)} className="btn-product btn-cart"><i
                                        className="d-icon-bag"></i>Add To Cart</a>
                                    <a style={{cursor: 'pointer'}} onClick={addItemToWishList}
                                       className="btn-product btn-cart"><i className="d-icon-heart"
                                                                           style={{pointer: 'cursor'}}></i>Add To
                                        Wishlist</a>

                                    <button onClick={updateModalState} className="btn-product btn-cart ">Close</button>
                                    {/* <button  onClick={updateModalState} className="btn-product ">Close</button> */}

                                </div>
                            </div>

                            {/* <hr className="product-divider mb-3"/>

            <div className="product-footer">
              
                <div  className="product-action">
                    <a   onClick={addItemToWishList} className="btn-product btn-wishlist"><i className="d-icon-heart" style={{pointer:'cursor'}}></i>Add To Wishlist</a>
                </div>
            </div> */}
                            {/* <span className="divider"></span>
                    <a href="#" className="btn-product btn-compare"><i className="d-icon-random"></i>Add To Compare</a> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};
