import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



class ComingSoon extends Component {
    render() {
        return (
            <div>
                <main className="main" >
                    <div className="page-content">
                        <section className="coming-section container text-center text-grey font-primary ">
                            <div className="row align-items-center pt-10 pb-10">
                                <div className="col-md-6">
                                    <img src="static/images/subpages/coming-soon.jpg" alt="coming soon" width="519" height="568" />
                                </div>
                                <div className="col-md-6 order-md-first">
                                    <h1 className="mb-9 ls-m lh-1 font-italic text-uppercase">Coming Soon</h1>
                                    <div className="countdown countdown-coming mb-7" data-format="DHMS" data-until="+10d"
                                        data-relative="true">00:00:00</div>
                                    <hr className="mb-6 ml-8 mr-8" />
                                    <p>We are currently working on an awesome new site. Stay tuned for more information.
                                Subscribe to our newsletter to stay updated on our progress.</p>
                                    <form action="#" className="ml-lg-8 mr-lg-8 mb-8">
                                        <input type="email" className="form-control font-primary text-grey" name="email" id="email"
                                            placeholder="Enter Your Email Address" required="" />
                                        <button className="btn btn-primary btn-link btn-icon-right" type="submit">subscribe<i
                                            className="d-icon-arrow-right"></i></button>
                                    </form>
                                    <div className="social-links">
                                        <a href="#" className="social-link social-facebook fab fa-facebook-f"></a>
                                        <a href="#" className="social-link social-twitter fab fa-twitter"></a>
                                        <a href="#" className="social-link fab fa-instagram"></a>
                                        <a href="#" className="social-link fab fa-pinterest"></a>
                                        <a href="#" className="social-link fas fa-envelope"></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        )
    }
}
export default ComingSoon

