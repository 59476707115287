// noinspection DuplicatedCode

import React, {useState, useEffect} from "react";
import axios from "axios";
import {NO_TOKEN_BEAUTY_AND_HEALTH_PRODUCT_URL} from "../../constants/api";
import {ProductSection} from "../product/ProductSection";

const ProductComponent = (props) => {
    const [products, setProducts] = useState([]);
    const [next, setNext] = useState("");


    const loadMore = async () => {
        if (next !== "") {
            fetchData(next).then(() => {
            })
        }
    }

    const fetchData = async (url) => {
        const result = await axios(url);
        setProducts((products) => [...products, ...result.data.results]);
        setNext(result.data.next);
    }

    useEffect(() => {
        fetchData(NO_TOKEN_BEAUTY_AND_HEALTH_PRODUCT_URL).then(() => {
        });
    }, [])

    return (
        <main className="main mt-lg-4">
            <div className="page-content">
                <div className="container">
                    {products.length > 0 ? (
                        <section className="product-wrapper mt-6 appear-animate-visible">
                            <h2 className="title title-underline with-link">
                                <span>Beauty & Health</span>
                                <a href="#" className="font-weight-bold">
                                    View more
                                    <i className="fas fa-chevron-right"/>
                                </a>
                            </h2>
                            <div className="row cols-xl-5 cols-lg-4 cols-md-2 cols-2 mb-5">
                                {products.map((product, index) => (
                                    <ProductSection
                                        index={product.id}
                                        product={product}
                                        addToCart={() => props.addItemToCart(product)}
                                        addToWishlist={() => props.addItemToWishList(product)}
                                        showQuickModal={true}
                                    />
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-offset-3 ml-auto mr-auto">
                                    <button onClick={() => loadMore()}
                                            className="btn btn-lg btn-block btn-outline ml-auto mr-auto mt-lg-5 text-center text-white" style={{
                                                backgroundColor: "#00667f"
                                    }}>Load
                                        More
                                    </button>
                                </div>
                            </div>
                        </section>
                    ) : null}
                </div>
            </div>
        </main>
    );
}

export default ProductComponent
