// noinspection DuplicatedCode

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    BASE_URL,
    LOAD_USER_URL,
    PRODUCT_URL,
    LOGO_URL,
    LOGIN_URL,
    GET_ALL_CATEGORY_URL,
} from '../../constants/api'
import {
    addQuantity,
    subtractQuantity,
    removeItem,
    emptyCart,
    bundleRemoveItem,
} from '../../actions/cartActions'
import axios from 'axios'
import {isValidURL} from '../../constants/HomeData'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class HeaderTopComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            suggestions: [],
            text: '',
            category: [],
            phone_no: '',
            password: '',
            logo: [],
            login: false,
            userName: '',
            category_id: '',
            search_text: ''
        }
        this.selectedText = this.selectedText.bind(this)
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    loadUser = () => {
        const token = localStorage.getItem('token');
        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;

            fetch(LOAD_USER_URL, {"method": "GET", headers})
                .then(res => res.json())
                .then(res => {
                    if (res.id !== undefined) {
                        this.setState({userName: res.first_name !== null ? res.first_name : '', login: true})

                    } else {
                        localStorage.removeItem('token')
                        throw res;
                    }
                }).catch()
        }
    };
    onPhoneLogin = () => {
        let data = {
            phone_no: this.state.phone_no,
            password: this.state.password
        }

        let url = LOGIN_URL
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    toast.warning('Already Login');
                } else {
                    toast.success("Login successfully");
                }
            })
            .catch((error) => {
                toast.warning('Error:', error);
            });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.onPhoneLogin()
    }

    componentDidMount() {
        // alert(JSON.stringify(window.location.pathname))
        // this.getCategory()
        this.getLogo()
        this.loadUser()
        const path = window.location.pathname;
        const page = path.split("/").pop();

        setTimeout(function () {
            if (page === "search-results") {
                this.setState({'showSearch': true})
            }
        }.bind(this), 1000)
    }

    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    getLogo = () => {
        fetch(LOGO_URL)
            .then(res => res.json())
            .then(logo => {
                this.setState({logo: logo.results})
            })
    }

    getCategory = () => {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        };

        fetch(GET_ALL_CATEGORY_URL, requestOptions)
            .then(res => res.json())
            .then(category => {
                this.setState({category: category})
            });
    }
    onTextChange = (e) => {
        const value = e.target.value;
        let suggestions = [];
        axios.get(PRODUCT_URL + '&search=' + value)
            .then(({data}) => {
                this.setState({
                    suggestions: data.results
                })
            })

        this.setState({
            suggestions,
            text: e.target.value
        })
    }

    SearchData = () => {
        this.props.history.push("search-results", {
            product:
                {product_name: this.state.text, category: this.state.category_id}
        });
       
    }


    selectedText() {
        this.props.history.push("search-results", {
            product:
                {product_name: this.state.text, category: this.state.category_id}
        });
       
    }

    showProductDetails(id) {
        this.props.history.push("product-details?id=" + id);
        // window.location.href="product-details?id=" + id;
        
        this.setState({
            suggestions:[],
            
        })
    }

    render() {
        let {suggestions, category, logo} = this.state;
        const {pageTitle, totalQuantity, items, total, bundleItems} = this.props;
        let addedItems = totalQuantity > 0 ? <>
                <i className="minicart-icon">
                    <span className="cart-count"> {totalQuantity}</span>
                </i>
            </> :
            <></>
        const pathname = window.location.pathname;

        return (

            <div>
                <ToastContainer/>
                <header className="header">
                    <div className="header-top">
                        <div className="container">
                            <div className="header-left">
                            
                            {/* <div className="social-links">
                                
                                    <a href="https://play.google.com/store/apps/details?id=com.obahe" target="_blank" title="Mobile app">
                                       
                                        <p className="welcome-msg">Download Obahe App</p>
                                    </a>
                                
                            
                            </div> */}
                                <p className="welcome-msg">Welcome to My Choice BD!</p>
                            </div>
                            
                            <div className="header-right">
                                {/* <!-- End DropDown Menu --> */}
                                <div className="dropdown dropdown-expanded d-lg-show">
                                    <a href="#dropdown">Links</a>
                                    <ul className="dropdown-box">
                                        <li><a href="/about-us">About</a></li>
                                        {/* <li><a href="/blog">Article</a></li>` */}
                                        <li><a href="/faq">FAQ</a></li>
                                        <li><a href="/contact-us">Contact</a></li>
                                    </ul>
                                </div>
                                {/* <!-- End DropDownExpanded Menu --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End HeaderTop --> */}
                    <div className="header-middle sticky-header fix-top sticky-content">
                        <div className="container"
                             style={{paddingTop: "12px", paddingBottom: "12px", position: "relative"}}>
                            <div className="header-left">
                                {logo !==undefined &&   logo.map((logo,index) => (
                                    <span key={index}>
                                        <a href="#" className="mobile-menu-toggle">
                                            <i className="d-icon-bars2" aria-hidden="true"></i>
                                        </a>


                                        <a href="/" className="logo d-lg-block d-none mr-4"
                                           key={'logo-sdf' + logo?.logo}>
                                            <img src={logo.logo} alt="logo" width="163" height="55"/>
                                        </a>
                                    </span>
                                ))}
                            </div>
                            <div className="header-center">
                                {logo !==undefined && logo.map((logo,ind) => (


                                    <a href="/" className="logo d-lg-none d-block" key={'logo-1-' + logo?.logo} key={ind}>
                                        <img src={logo.logo} alt="logo" width="163" height="55"/>
                                    </a>
                                ))}
                                {/* <!-- End Logo --> */}
                                <div className="header-search hs-expanded">
                                    <form action="#" method="get" className="input-wrapper">
                                        {/* <div className="select-box">
                                            <select id="category_id" onChange={this.handleChange} name="category_id">
                                                <option value="">All Categories</option>
                                                {category !==undefined && category.map((cate, index) => (
                                                    <option key={'sdsdf' + index}
                                                            value={cate.primary_category.name}>{cate.primary_category.name}</option>
                                                ))}
                                            </select>
                                        </div> */}
                                        <input type="text" className="form-control search-input" name="search_text"
                                               onChange={this.onTextChange} id="search"
                                               placeholder="Search your keyword..." required=""/>
                                        
                                        {!this.state.showSearch ?
                                            <button className="btn btn-sm btn-search"
                                                    onClick={() => this.selectedText()}>
                                                <i className="d-icon-search"/></button>
                                            :
                                            <button className="btn btn-sm btn-search" onClick={() => this.SearchData()}>
                                                <i className="d-icon-search"/></button>
                                        }
                                    </form>
                                </div>
                                {/* <!-- End Header Search --> */}
                            </div>
                            <div className="header-right">
                                {!this.state.login ?
                                    <Link className="cart-toggle userlogin" to="/login">
                                        <i className="d-icon-user"/>
                                        <span>Login</span>
                                    </Link> :
                                    <Link className="cart-toggle userlogin" to="/account">
                                        <i className="d-icon-user"/>
                                        <span>{this.state.userName !== '' && this.state.userName !== null ? this.state.userName : 'No Name'}</span>
                                    </Link>
                                }
                                <span className="divider ml-2"/>
                                <Link className="cart-toggle userlogin" to="/wishlist">
                                    <i className="d-icon-heart-full"/>
                                    <span>Wishlist</span>
                                </Link>
                                <span className="divider ml-2"/>

                                <div className="dropdown cart-dropdown">
                                    <Link to="/cart" className="cart-toggle">
                                        <span className="cart-label">
                                            {total > 0 ?
                                                <>
                                                    <span className="cart-name">My Cart</span>
                                                    <span
                                                        className="cart-price">৳ {total > 0 ? Math.round(total) : ''}</span>
                                                </>
                                                : null
                                            }
                                        </span>
                                        {addedItems}
                                    </Link>
                                    {/* <!-- End Cart Toggle --> */}

                                    {items.length > 0 || bundleItems.length ? (
                                        <div className="dropdown-box">
                                            <div className="product product-cart-header">
                                                <span className="product-cart-counts">{totalQuantity} items</span>
                                                <span><a href="/cart">View cart</a></span>
                                            </div>
                                            <div className="products scrollable">


                                                {/* <!-- End of Cart Product --> */}
                                                {items.map((item, index) => (
                                                    <div className="product product-cart" key={'item-11-' + index}>
                                                        <div className="product-detail">
                                                            <a href={'product-details?id=' + item.id}
                                                               className="product-name">{item.product_name}</a>
                                                            <div className="price-box">
                                                                <span
                                                                    className="product-quantity">{item.quantity}</span>
                                                                {item.is_whole_sell && (item.quantity >= item.purchase_quantity) ? item.whole_sell_price : '৳ '+ Math.round(item.new_price)}
                                                            </div>
                                                        </div>
                                                        <figure className="product-media">
                                                            <a href="#">
                                                                <img src={isValidURL(item.feature_image)} alt="product"
                                                                     width="90"
                                                                     height="90"/>
                                                            </a>
                                                            <button className="btn btn-link btn-close"
                                                                    onClick={() => this.props.removeItem(item)}>
                                                                <i className="fas fa-times"/>
                                                            </button>
                                                        </figure>
                                                    </div>
                                                ))
                                                }
                                                <>
                                                    {
                                                        bundleItems.length > 0 ? <h6>Bundle Product</h6> : null
                                                    }
                                                </>
                                                {bundleItems.map((item, index) => (
                                                    <div className="product product-cart" key={'ser' + index}>
                                                        <div className="product-detail">
                                                            <a href="product.html"
                                                               className="product-name">{item.title}</a>
                                                            <div className="price-box">
                                                                <span
                                                                    className="product-quantity">{item.quantity}</span>
                                                                <span
                                                                    className="product-price">{item.total_price}</span>
                                                            </div>
                                                        </div>
                                                        <figure className="product-media">
                                                            <a href="#">
                                                                <img src={isValidURL(item.feature_image)} alt="product"
                                                                     width="90"
                                                                     height="90"/>
                                                            </a>
                                                            <button className="btn btn-link btn-close"
                                                                    onClick={() => this.props.bundleRemoveItem(item)}>
                                                                <i className="fas fa-times"/>
                                                            </button>
                                                        </figure>
                                                    </div>
                                                ))
                                                }
                                                {/* <!-- End of Cart Product --> */}
                                            </div>
                                            {/* <!-- End of Products  --> */}
                                            <div className="cart-total">
                                                <label>Subtotal:</label>
                                                <span className="price">৳  {total > 0 ? Math.round(total) : ''}</span>
                                            </div>
                                            {/* <!-- End of Cart Total --> */}
                                            <div className="cart-action">
                                                <a href="/checkout" className="btn btn-dark"><span>Checkout</span></a>
                                            </div>
                                            {/* <!-- End of Cart Action --> */}
                                        </div>


                                    ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-gray search-box-dropdown" style={{
                        position: "absolute",
                        top: "105px",
                        left: "48%",
                        transform: "translate(-50%, 0px)",
                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                        zIndex: "9999",
                        display: "none",
                        paddingTop: "1.5rem",
                        height: "80vh",
                        overflow: "auto"
                    }}>
                        {suggestions.map((item, index) =>
                            (
                                <div className="tab tab-nav-simple tab-nav-left pl-3 pr-3 pb-3 show-tab"
                                     key={'tees-ee-' + index}>
                                    <>
                                        {/* <ul className="nav nav-tabs" role="tablist" style={{ width: "100%"}}>
                                <li className="nav-item" style={{ fontSize: "10" }}>
                                    <a className="nav-link active" href="#new-arrivals"><small>Products</small></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#best-sellers"><small>Shops</small></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#featured"><small>Brands</small></a>
                                </li>
                            </ul> */}


                                        {/* <div  className="tab-pane active" id="new-arrivals"  >
                                    <div className="row main-content-wrap gutter-lg">
                                        <div className="col-md-12 mt-3 mb-2 d-flex">
                                            <div className="w-100 hover-area" style={{ height: "80px", position: "relative" }}>

                                                <div className="ml-3" >
                                                    <img src="static/images/shop/3.jpg" alt="" height="60" width="60" style={{ position:" absolute", top: "50%", transform: "translate(0, -50%)", border: "2px solid #E7E8E9" }} />
                                                    <h6 className="text-capitalize" style={{ position: "absolute", top: "34%", transform: "translate(67%, -70%)", fontSize: "15px" }}>Product</h6>
                                                    <p className="font-normal ml-1" style={{ position: "absolute", top: "62%", transform: "translate(155%, -50%)" }}>৳ <strong>6656</strong></p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                            </div> */}
                                        {/* ))} */}

                                        <div className="tab-content search-result-area-res">
                                            <div key={index} className="tab-pane active py-0" id="new-arrivals">
                                                <div className="row main-content-wrap gutter-lg">
                                                    <div className="col-md-12 d-flex">
                                                        <div className="w-100 hover-area"
                                                             style={{height: "60px", position: "relative"}}>
                                                            <div
                                                                className="d-flex justify-content-start align-items-center h-100 px-3"
                                                                onClick={() => this.showProductDetails(item.id)}>
                                                                <img src={item.feature_image} alt="" height="55"
                                                                     width="55" style={{
                                                                    border: "2px solid #E7E8E9",
                                                                    padding: "0.4rem"
                                                                }}/>
                                                                <p className="font-normal ml-3 mb-0px" style={{
                                                                    width: "60px",
                                                                    whiteSpace: "nowrap"
                                                                }}>৳ <strong>{item.new_price}</strong></p>
                                                                <a href={"product-details?id=" + item.id}>
                                                                <h6 className="text-capitalize ml-3 mb-0px" style={{
                                                                    fontSize: "15px",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                }}>{item.product_name}</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* <div className="tab-pane" id="best-sellers">
                                    <div className="row main-content-wrap gutter-lg">
                                        <div className="col-md-12 mt-3 mb-2 d-flex">
                                            <div className="w-100 hover-area" style={{ height: "80px", position: "relative" }}>
                                                <div className="ml-3">
                                                    <img src="static/images/shop/3.jpg" alt="" height="60" width="60" style={{ position:" absolute", top: "50%", transform: "translate(0, -50%)", border: "2px solid #E7E8E9" }} />
                                                    <h6 className="text-capitalize" style={{ position: "absolute", top: "34%", transform: "translate(67%, -70%)", fontSize: "15px" }}>Baby products</h6>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                            {/* <div className="tab-pane" id="featured">
                                    <div className="row main-content-wrap gutter-lg">
                                        <div className="col-md-12 mt-3 mb-2 d-flex">
                                            <div className="w-100 hover-area" style={{ height: "80px", position: "relative" }}>
                                                <div className="ml-3">
                                                    <img src="static/images/shop/3.jpg" alt="" height="60" width="60" style={{ position:" absolute", top: "50%", transform: "translate(0, -50%)", border: "2px solid #E7E8E9" }} />
                                                    <h6 className="text-capitalize" style={{ position: "absolute", top: "34%", transform: "translate(67%, -70%)", fontSize: "15px" }}>Baby products</h6>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                        </div>
                                    </>
                                </div>
                            ))}

                    </div>
                    <div className="header-bottom sticky-header fix-top sticky-content has-dropdown">
                        <div className="container">
                            <div className="inner-wrap">
                                <div className="header-left">
                                    
                                        
                                    {/* <div className={pathname.match('/home')?"dropdown category-dropdown menu-fixed":"dropdown category-dropdown"} 
                                             style={{position: "relative"}}>
                                            <Link to="#!" className="text-white">
                                                <i className="d-icon-bars"/>
                                            </Link>
                                 
                                           
                                            <div className="dropdown-overlay"/>
                                            
                            
                                            <div className="dropdown-box">
                                                <ul className="menu vertical-menu category-menu">
                                                    <li>
                                                        <Link to="#" className="menu-title">Browse Our Categories</Link>
                                                    </li>
                                                    {
                                                        category.map((cate, index) =>
                                                        <>
                                            
                                                                    
                                                       
                                                            <li  key={'sdf-' + index}>
                                                                
                                                                <img src={isValidURL(cate.primary_category.image)} style={{height:"20px",width:'20px',float:"left",margin:"12px"}}  />
                                                                <Link
                                                                    onClick={()=>window.location.href="/category?primary_categories__id=" + cate.primary_category.id}
                                                                    to={"/category?primary_categories__id=" + cate.primary_category.id}>{cate.primary_category.name}
                                                                    {
                                                                        cate.secondary_category.length > 0 &&
                                                                        <span style={{float: "right"}}> > </span>
                                                                    }
                                                                </Link>
                                                                {
                                                                    cate.secondary_category.length > 0 && <>
                                                                        <ul className="menu vertical-menu category-menu">
                                                                            {cate.secondary_category.map((secondary, secindex) => (
                                                                                
                                                                                <li  key={'dfdf-' + secindex}>
                                                                                

                                                                                    <Link
                                                                                        onClick={()=>window.location.href="/category?secondary_categories__id=" + secondary.id}

                                                                                        to={"/category?secondary_categories__id=" + secondary.id}>{secondary.name}
                                                                                    </Link>
                                                                                    {
                                                                                        secondary.child_category.length > 0 &&
                                                                                        <ul >
                                                                                            {
                                                                                                secondary.child_category.map((child, childindex) => (
                                                                                                    <li key={'xx' + childindex}>
                                                                                                    

                                                                                                        <Link
                                                                                                        onClick={()=>window.location.href="/category?child_categories__id=" + child.id}
                                                                                                            to={"/category?child_categories__id=" + child.id}>{child.name}</Link>
                                                                                                    </li>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                    }
                                                                                </li>
                                                                            ))}
                                                                        </ul>

                                                                    </>
                                                                }
                                                            </li>
                                                            </>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                           
                                      
                                    </div> */}
                                    <nav className="main-nav">
                                        <ul className="menu">
                                            <li className={`${pathname.match('/home') ? 'active' : ''}`}>
                                                <Link to="/home">Home</Link>
                                            </li>
                                            {/* <li to="/all-shop"
                                                className={`${pathname.match('/all-shop') ? 'active' : ''}`}>
                                                <Link to="/all-shop">All Shops</Link>
                                            </li> */}
                                            <li className={`${pathname.match('/all-brand') ? 'active' : ''}`}>
                                                <Link to="/all-brand">Brands</Link>
                                            </li>
                                            {/* <li className={`${pathname.match('/campaign') ? 'active' : ''}`}>
                                                <Link to="/campaign">Campaigns</Link>
                                            </li> */}
                                            {/* <li className={`${pathname.match('/on-sale') ? 'active' : ''}`}>
                                                <Link to="/on-sale">On Sale</Link>
                                            </li> */}
                                        </ul>
                                    </nav>
                                </div>
                                <div className="header-right">
                                    <ul className="menu">
                                        {/* <li>
                                            <a href={BASE_URL}>Seller Zone</a>
                                        </li> */}
                                        <li>
                                            <a href="/about-us">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/support">Support</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* <!-- End Header -->< */}
                <div className="mobile-menu-wrapper">
                    <div className="mobile-menu-overlay">
                    </div>
                    {/* <!-- End Overlay --> */}
                    <a className="mobile-menu-close" ><i className="d-icon-times"/></a>
                    {/* <!-- End CloseButton --> */}
                    <div className="mobile-menu-container scrollable">
                        {/* <form action="#" className="input-wrapper mb-6">
                            <input type="text" className="form-control mobile-search-input" name="search"
                                   autoComplete="off" placeholder="Search your keyword..." required/>
                            <button className="btn btn-search" type="submit">
                                <i className="d-icon-search"/>
                            </button>
                        </form> */}
                        {/* <!-- End Search Form --> */}
                        {/* <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                
                                <li className="nav-item">
                                    <a className="nav-link" href="#categories">Categories</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                
                                <div className="tab-pane active" id="categories">
                                    <ul className="mobile-menu mmenu-anim">
                                        <li><a href="#" className="menu-title">Browse Our Categories</a>
                                        </li>
                                        {
                                                        category.map((cate, index) =>
                                                        <>
                                            
                                                                    
                                                        
                                                            <li style={{position: "relative"}} key={'sdf-' + index}>
                                                                
                                                                <img src={isValidURL(cate.primary_category.image)} style={{height:"20px",width:'20px',float:"left",margin:"12px"}}  />
                                                                <Link
                                                                    onClick={() => window.location.href="/category?primary_categories__id=" + cate.primary_category.id}
                                                                    
                                                                    to={"/category?primary_categories__id=" + cate.primary_category.id}>{cate.primary_category.name}
                                                                    {
                                                                        cate.secondary_category.length > 0 &&
                                                                        <span style={{float: "right"}}> > </span>
                                                                    }
                                                                </Link>
                                                                {
                                                                    cate.secondary_category.length > 0 && <>
                                                                        <ul style={{position: "absolute"}}>
                                                                            {cate.secondary_category.map((secondary, secindex) => (
                                                                                
                                                                                <li style={{position: "relative"}} key={'dfdf-' + secindex}>
                                                                                

                                                                                    <Link
                                                                                        onClick={() => window.location.href="/category?secondary_categories__id=" + secondary.id}

                                                                                        to={"/category?secondary_categories__id=" + secondary.id}>{secondary.name}
                                                                                    </Link>
                                                                                    {
                                                                                        secondary.child_category.length > 0 &&
                                                                                        <ul style={{
                                                                                            position: "absolute"
                                                                                            
                                                                                        }}>
                                                                                            {
                                                                                                secondary.child_category.map((child, childindex) => (
                                                                                                    <li style={{position: "relative"}} key={'xx' + childindex}>
                                                                                                    

                                                                                                        <Link
                                                                                                            onClick={() => window.location.href="/category?child_categories__id=" + child.id}

                                                                                                            to={"/category?child_categories__id=" + child.id}>{child.name}</Link>
                                                                                                    </li>
                                                                                                ))
                                                                                            }
                                                                                        </ul>
                                                                                    }
                                                                                </li>
                                                                            ))}
                                                                        </ul>

                                                                    </>
                                                                }
                                                            </li>
                                                            </>
                                                        )
                                                    }
                                        
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- End MobileMenu --> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        bundleItems: state.cart.bundleItems,
        totalQuantity: state.cart.totalQuantity,
        total: state.cart.total
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuantity: (data) => {
            dispatch(addQuantity(data))
        },
        removeItem: (data) => {
            dispatch(removeItem(data))
        },
        subtractQuantity: (data) => {
            dispatch(subtractQuantity(data))
        },
        bundleRemoveItem: (data) => {
            dispatch(bundleRemoveItem(data))
        },
        emptyCart: (data) => {
            dispatch(emptyCart())
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HeaderTopComponent))
