import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FOOTER_SOCIAL_MEDIA_URL, FOOTER_LEFT_SECTION_URL,COPY_RIGHT_URL,CONTACT_INFO_URL,CONTACT_MESSAGES_URL,LOGO_URL,FOOTER_LOGO_URL } from '../../constants/api'
import axios from 'axios'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class ContactUsComponent extends Component {

        state={
            contact:[],
            name:"",
            email:"",
            description:"",
            order_id:"",
            priority:""

        }

        componentDidMount(){
            this.getContact()
        }

        getContact=() => {
            const fetchData = async () => {
                const result = await axios(
                    CONTACT_INFO_URL,
                );
                // alert(JSON.stringify(result))
                this.setState({contact: result.data.results});
            };
    
            fetchData();
        };
        handleSubmit = (evt) => {
            evt.preventDefault();
            const token = localStorage.getItem('token');
            if (token !== null && token !== undefined && token !== '') {
                const data = {
                    'name': this.state.name,
                    'email': this.state.email,
                    'priority': this.state.priority,
                    'order_id': this.state.order_id,
                    'description':this.state.description
                };
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
                    body: JSON.stringify(
                        data
                    )
                };
                fetch(CONTACT_MESSAGES_URL, requestOptions)
                    .then(res => res.json())
                    .then(result => {
                        alert(JSON.stringify(result))
                        if (result.email !== null) {
                            toast.success("Your reviewed has been successfully !!!")
                        } else {
                            toast.warn("Something wrong here.please try again!!!")
                        }
                    });
            }
        }

        onTextChange = (e) => {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    render() {
        const {contact,name,email,order_id,priority,description} = this.state
        return (
            <div>
                < main className="main" >
                    <div className="page-header" style={{ backgroundImage: `url(${"static/images/page-header.jpg"})` }}>
                        <h1 className="page-title">Get in touch</h1>
                    </div>
                    <div className="page-content mt-10 pt-4">
                        <section className="contact-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-xs-5 ls-m pt-3">
                                        <h2 className="font-weight-bold text-uppercase ls-m mb-2">Contact us</h2>
                                        <p>Looking for help? Fill the form and start a new adventure.</p>
                                        {contact.map(contact=>(
                                            <>
                                        <h4 className="mb-1 text-uppercase">Headquarters</h4>
                                        <p>{contact.address}</p>

                                        <h4 className="mb-1 text-uppercase">Phone</h4>
                                        <p><a href="tel:#">{contact.phone_no}</a></p>

                                        <h4 className="mb-1 text-uppercase">Support</h4>
                                        <p>
                                            <a href="#">{contact.email}</a><br />
                                            {/* <a href="#">help@your-domain.com</a><br />
                                            <a href="#">SALES</a> */}
                                        </p>
                                        </>
                                        ))}
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-xs-7">
                                        <form className="ml-lg-2 pt-8 pb-10 pl-4 pr-4 pl-lg-6 pr-lg-6 grey-section" action="#"
                                        onSubmit={this.handleSubmit}
                                        >
                                            <h3 className="ls-m mb-1">Let’s Connect</h3>
                                            <p className="text-grey">Your email addres will not be published. Required fields are
                                        marked *</p>
                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <input className="form-control" type="text" name="name" value={name} onChange={this.onTextChange} placeholder="Name *" required />
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <input className="form-control" type="email" name="email" value={email} onChange={this.onTextChange} placeholder="Email *" required />
                                                </div>

                                                <div className="col-md-6 mb-4">
                                                    <input className="form-control" type="text" name="order_id" value={order_id} onChange={this.onTextChange} placeholder="Order No. *" required />
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="custom-radio">
                                                                <input type="radio" id="order-cancel" name="priority" value="1" onChange={this.onTextChange}  className="custom-control-input" />
                                                                <label className="custom-control-label" for="order-cancel">I want to cancel an order</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="custom-radio">
                                                                <input type="radio" id="return-order" name="priority" value="2" onChange={this.onTextChange} className="custom-control-input" />
                                                                <label className="custom-control-label" for="return-order">I want to return an item</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="custom-radio">
                                                                <input type="radio" id="others" name="priority" value="3" onChange={this.onTextChange} className="custom-control-input" />
                                                                <label className="custom-control-label" for="others">Others</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-4">
                                                    <textarea className="form-control" name="description" value={description} onChange={this.onTextChange} required placeholder="Your Message *"></textarea>
                                                </div>
                                            </div>
                                            <button className="btn btn-md btn-primary mb-2">Send Message</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- End About Section--> */}

                        {/* <!-- Google Maps - Go to the bottom of the page to change settings and map location. --> */}
                        <div className="grey-section google-map" id="googlemaps" style={{ height: "386px" }} ></div>
                        {/* <!-- End Map Section --> */}
                    </div>
                </main >
            </div>
        )
    }
}
export default ContactUsComponent

