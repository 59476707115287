import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';



class OrderContainer extends Component {

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }





    render = () => {
        const { Layout,items,total,location } = this.props;
       // const { order } = this.props.match.params
        // const { error } = this.state;

        return (
            <div>
                <Layout 
                 items={items}
                 total={total}
                 order={location.state.order}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        total: state.cart.total
    }
}
const mapDispatchToProps = (dispatch) => {

    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderContainer)
