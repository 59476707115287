// noinspection DuplicatedCode

import React from 'react'
import {withRouter} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LOAD_USER_URL, BASE_URL} from './constants/api';
import {Component} from 'react';

class ForgotPassword extends Component {
    state = {
        email: '',
        login: false
    }

    componentDidMount() {
        this.loadUser()
    }

    onSubmit = e => {
        e.preventDefault();
        this.forgotPass(this.state.email);
        
    }

    loadUser = () => {
        var token = localStorage.getItem('token');
        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;
            fetch(LOAD_USER_URL, {"method": "GET", headers,})
                .then(res => res.json())
                .then(res => {
                    if (res.id !== undefined) {
                        this.setState({login: true})
                    } else {
                        console.log("Server Error!");
                        throw res;
                    }
                })
        }
    };

    forgotPass = (email) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email})
        };

        fetch(BASE_URL + "/auth/djoser/users/reset_password/", requestOptions)
            .then(res => res.json())
            toast.success('Please check your email for changing password')
            this.props.history.push('/login')
        
            // .then(user => {
            //     if (user.success) {
            //         localStorage.setItem('currentUser', JSON.stringify(user));
            //         localStorage.setItem('token', user.token);

            //         toast.success('Please check your email for changing password')
            //         this.props.history.push('/login')
            //     } else {
            //         toast.warning('Please try again!!');
            //     }
            // });
    }
    

      


    render() {
        return (
            <>
                <ToastContainer/>
                <div className={"container"}
                     style={{display: "flex", justifyContent: "center", position: "relative", alignItems: "center"}}>
                    <div className="login-popup" style={{width: "100%"}}>
                        <div className="form-box">
                            <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                                <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5"
                                    role="tablist">
                                    <li className="delimiter">Forgot Password</li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active">
                                        <form onSubmit={this.onSubmit}>
                                            <div className="form-group mb-3">
                                                <input type="email" className="form-control" id="singin-email"
                                                       name="email"
                                                       onChange={e => this.setState({email: e.target.value})}
                                                       placeholder="Email Address *" required/>
                                            </div>
                                            <button className="btn btn-dark btn-block btn-rounded text-uppercase"
                                                    type="submit">Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(ForgotPassword)
