import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addQuantity, subtractQuantity, removeItem, 
    emptyCart, bundleAddQuantity, 
    bundleRemoveItem, bundleSubtractQuantity, 
    addToCart,addItemToWishList
} from '../../actions/cartActions'



class CartContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { 
          isLoading: false,
        }
        this.addQuantityToCart=this.addQuantityToCart.bind(this)
        this.subtractQuantityFromCart=this.subtractQuantityFromCart.bind(this)
        this.removeQuantityFromCart=this.removeQuantityFromCart.bind(this)

        this.bundleAddQuantityToCart=this.bundleAddQuantityToCart.bind(this)
        this.bundleSubtractQuantityFromCart=this.bundleSubtractQuantityFromCart.bind(this)
        this.bundleRemoveQuantityFromCart=this.bundleRemoveQuantityFromCart.bind(this)

        this.emptyCart=this.emptyCart.bind(this)
     
        
      }
      
    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }

    async addQuantityToCart(data){
        // const { addToast } = useToasts()
         this.props.addQuantity(data)
          // addToast('Saved Successfully', { appearance: 'success' })
         
    }
       
    addItemToWishList=async(data)=>{
        this.props.addItemToWishList(data)
    }

    addToCart = async (data)=>{
        // const { addToast } = useToasts()
         this.props.addToCart(data)
          // addToast('Saved Successfully', { appearance: 'success' })
         
    }
       
    async subtractQuantityFromCart(data){
        this.props.subtractQuantity(data)
    }
       
    async emptyCart(){
        this.props.emptyCart()
    }
    async bundleAddQuantityToCart(data){
        this.props.bundleAddQuantity(data)
    }
    async bundleSubtractQuantityFromCart(data){
        this.props.bundleSubtractQuantity(data)
    }
    async bundleRemoveQuantityFromCart(data){
        this.props.bundleRemoveItem(data)
    }
       
    async removeQuantityFromCart(data){
        this.props.removeItem(data)
    }
       



    render = () => {
        const { Layout,items,bundleItems,total } = this.props;
        if(items.length<1){
            window.location.href='/'
        }
        // const { error } = this.state;

        return (
            <div>
                <Layout 

                   addQuantityToCart={this.addQuantityToCart}
                   subtractQuantityFromCart={this.subtractQuantityFromCart}
                   bundleAddQuantityToCart={this.bundleAddQuantityToCart}
                   bundleSubtractQuantityFromCart={this.bundleSubtractQuantityFromCart}
                   removeQuantityFromCart={this.removeQuantityFromCart}
                   bundleRemoveQuantityFromCart={this.bundleRemoveQuantityFromCart}
                   emptyCart={this.emptyCart}
                   addToCart={this.addToCart}
                   addItemToWishList={this.addItemToWishList}
                   items={items}
                   total={total}
                   bundleItems={bundleItems}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        bundleItems: state.cart.bundleItems,
        totalQuantity: state.cart.totalQuantity,
        total: state.cart.total
    }
}
const mapDispatchToProps = (dispatch) => {

    return {
        addQuantity: (data) => { dispatch(addQuantity(data)) },
        removeItem: (data) => { dispatch(removeItem(data)) },
        subtractQuantity: (data) => { dispatch(subtractQuantity(data)) },
        addToCart: (data) => { dispatch(addToCart(data)) },
        addItemToWishList: (data) => { dispatch(addItemToWishList(data)) },
        bundleRemoveItem: (data) => { dispatch(bundleRemoveItem(data)) },
        bundleAddQuantity: (data) => { dispatch(bundleAddQuantity(data)) },
        bundleSubtractQuantity: (data)=>{dispatch(bundleSubtractQuantity(data))},
        emptyCart: (data) => { dispatch(emptyCart()) },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartContainer)

