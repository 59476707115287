import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BASE_URL, BANNER_SETTING } from '../../constants/api'
import axios from 'axios';
import SwiperCore, { Pagination,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Pagination,Autoplay]);
const MidSeasonSaleComponent = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                BANNER_SETTING
            );
            setData(result.data.results)
        };
        fetchData()
    }, [])

    return (
        <main className="main main-fit-now-res">
            <div className="page-content">
                <div className="container">
                    <section className="banner-group">
                        <div className="row">
                            <Swiper slidesPerView={2} spaceBetween={20} loop={true} autoplay={true} className="mb-4">
                            {data.map((item,i) => (

                                <div className="col-md-6 mb-4 appear-animate-visible " key={i}>
                                    <div className="banner banner-fixed banner2 overlay-zoom">
                                    <SwiperSlide key={i} className="banner banner-left banner-fixed intro-slide1">
                                        <figure>
                                        {item.image!==null && item.image!=='' && item.image!==undefined ?( 
                                            <img src={item.image} width="580" height="240" alt="banner" />
                                        ):
                                        <img src='/static/images/no-image.png' width="580" height="240" alt="banner" />
                                        }    
                                        </figure>
                                    </SwiperSlide>
                                        
                                    </div>
                                </div>
                            ))}
                            {/* <div className="banner-content y-50 appear-animate-visible">
                                            <h4 className="banner-subtitle text-uppercase font-weight-bold">{item.title}</h4>
                                            <h3 className="banner-title ls-m mb-1 text-primary">Up to 30% Off</h3>
                                            <p className="font-weight-semi-bold ls-m mb-3 text-dark">Free Event</p>
                                            <a href="#" className="btn btn-dark btn-md">{item.button_text}</a>
                                        </div> */}
                            </Swiper>
                            {/* <div className="col-md-6 mb-4 appear-animate">
                                <div className="banner banner-fixed banner2 overlay-zoom">
                                    <figure>
                                        <img src="static/images/demos/demo11/banners/4.jpg" width="580" height="240" alt="banner" />
                                    </figure>
                                    <div className="banner-content y-50 appear-animate">
                                        <h4 className="banner-subtitle text-uppercase font-weight-normal text-white">New Arrivals</h4>
                                        <h3 className="banner-title ls-m mb-1 text-white font-weight-bold">Sportswear Collection</h3>
                                        <p className="font-weight-semi-bold ls-m mb-3 text-white">Sporting Suit, Shoes,&amp; Shirts</p>
                                        <a href="#" className="btn btn-primary btn-md">Shop now</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    )
}


export default MidSeasonSaleComponent
