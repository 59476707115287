import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {addToCart, bundleAddToCart, addItemToWishList} from '../../actions/cartActions'


class HomeContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
        this.addItemToCart = this.addItemToCart.bind(this);
        this.bundleaddItemToCart = this.bundleaddItemToCart.bind(this);
        this.addItemToWishList = this.addItemToWishList.bind(this);


        // this.addItemToCart=this.addItemToCart.bind(this)
    }

    static propTypes = {
        Layout: PropTypes.func.isRequired,
        addItemToCart: PropTypes.func,
        bundleaddItemToCart: PropTypes.func,
        addItemToWishList: PropTypes.func.isRequired,
    }


    addItemToCart(data) {
        //  alert("adk")
        this.props.addToCart(data)
        //  alert(JSON.stringify(data))
        //  console.log(data)
    }

    addItemToWishList(data) {
        //  alert("adk")
        this.props.addItemToWishList(data)
        //  alert(JSON.stringify(data))
        //  console.log(data)
    }

    bundleaddItemToCart(data) {
        this.props.bundleAddToCart(data)
        //  alert(JSON.stringify(data))
        //  console.log(data)
    }

    callbackFunction = (childData) => {
        //this.setState({message: childData})
    }

    

    componentDidMount() {
        // this.addItemToCart()
        this.timerHandle = setTimeout(() => this.setState({loading: true}), 3500);
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    render() {
        const {Layout} = this.props;
        const {isLoading} = this.state;
// alert(Layout)
        return (
            <div>
                {
                    isLoading ? (
                            <div id="preloder">
                                <div class="loader"></div>
                            </div>
                        ) :

                        <Layout
                            items
                            addItemToCart={this.addItemToCart}
                            bundleaddItemToCart={this.bundleaddItemToCart}
                            addItemToWishList={this.addItemToWishList}

                        />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        bundleItems: state.cart.bundleItems,
        wishlist: state.cart.wishlist,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (data) => {
            dispatch(addToCart(data))
        },
        bundleAddToCart: (data) => {
            dispatch(bundleAddToCart(data))
        },
        addItemToWishList: (data) => {
            dispatch(addItemToWishList(data))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContainer)

