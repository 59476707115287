import React, { useState } from 'react';
import {Redirect, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from './actions/auth';

import {strongRegex, required,matched} from './constants/Validate'

const ForgotPasswordConfirm = ({ match, reset_password_confirm }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        
        
        
        
    });

    const { new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const { uid,  token} = useParams();

    const onSubmit = e => {
        e.preventDefault();

        // const uid = match.params.uid;
        // const token = match.params.token;
        //
        // console.log(uid, token, 'test')

        reset_password_confirm(uid, token, new_password);
        setRequestSent(true);
    };

    if (requestSent) {
        return <Redirect to='/login' />
    }
    const centerScreen = {
        position: "fixed",
        top: "30%",
        left: "50%",
        /* bring your own prefixes */
        transform: "translate(-50%, -30%)",
    };
    return (
        <div className="red" style={centerScreen}>
            
            {/* <img src={logo} alt="gothi-logo" style={{width: '18rem', display:'block' }} className="mx-auto mb-3" /> */}
            
            <div className="card bg-gradient-light" style={{ width: '45rem', }}>
                <div className="card-header text-center">
                    <h5>Change Password</h5>
                </div>
                <div className="card-body">
                {/* <div className='container mt-5'> */}
                <form onSubmit={e => onSubmit(e)} style={{padding:'30px'}}>
                <div className='form-group mb-4'>
                <label >New Password</label>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='ex: example123'
                        name='new_password1'
                        // value={new_password}
                        onChange={e => onChange(e)}
                        minLength='8'
                        validate={[required]}
                        required 
                    />
                <small className="form-text text-muted">Your password must be 8 and contain letter & number(example: example123)</small>

                </div>
                <div className='form-group mb-4'>
                <label > Re-type New Password</label>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='ex: example123'
                        name='new_password'
                        // value={re_new_password}
                        onChange={e => onChange(e)}
                        minLength='8'
                        required 
                        validate={[required,matched("new_password1"),strongRegex]}
                    />
                <small className="form-text text-muted">Your password must be 8 and contain letter & number(example: example123)</small>

                </div>
                
                <button className='btn btn-primary'>Reset Password</button>
                </form>
            </div>
        </div>
    </div>
       
    );
};

export default connect(null, { reset_password_confirm })(ForgotPasswordConfirm);