// noinspection DuplicatedCode

import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {AppLoader} from '../../constants/HomeData'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
    NO_TOKEN_FEATURE_PRODUCT_URL,
    PRODUCT_URL,
    BASE_URL
} from "../../constants/api";
import {ProductSection,ProductSectionTwoForPopular} from "../product/ProductSection";

const NewArrivalComponent = (props) => {
    // const [products, setProducts] = useState([]);
    const [featured, setFeatured] = useState([]);
    const [isLoadig, setLoading] = useState(false);
    const [next, setNext] = useState("");

    // const fetchData = async (url) => {
    //     const result = await axios(url);
    //     setFeatured((products) => [...products, ...result.data.results]);
    // };
    const loadMore = async () => {
        if (next !== null) {
            fetchData(next).then(() => {
            })
        }
    }
    const fetchData = async (url) => {
        const result = await axios(url);
        setFeatured((products) => [
            ...products,
            ...(result.data.results ? result.data.results : []),
        ]);
        setLoading(true)
        setNext(result.data.next);
    }
    useEffect(() => {
        fetchData(PRODUCT_URL).then(() => {
        })
        
    }, [])
    
    // useEffect(() => {
    //     fetchData(BASE_URL+'/api/v1/no-token-product-list/').then(() => {
    //     });
    // }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const result = await axios(NO_TOKEN_FEATURE_PRODUCT_URL);
    //         console.log(result.data.results)
    //         setFeatured((products) => [
    //             ...products,
    //             ...(result.data.results ? result.data.results : []),
    //         ])
    //         setNext(result.data.next);

    //         setLoading(true)
    //     };

    //     fetchData().then(() => {
    //     });
    // }, []);

    const newArrivals = () => {
        document.getElementById('new-arrivals').classList.add('active');
        document.getElementById('featured').classList.remove('active');
    }

    const ourFeatured = () => {
        document.getElementById('featured').classList.add('active');
        document.getElementById('new-arrivals').classList.remove('active');
    }

    return (
        <main className="main mt-lg-4">
            <div className="page-content">
                <div className="container">
                    <section className="product-wrapper appear-animate-visible new-arrivals">
                        <Tabs className="tab tab-nav-simple tab-nav-left">
                            <TabList className="nav nav-tabs" role="tablist">
                                <Tab className="nav-item">
                                    <Link className="nav-link active" id="new-arrivals" to="#new-arrivals"
                                          onClick={newArrivals}>
                                        Our Product
                                    </Link>
                                </Tab>
                                {/* <Tab className="nav-item">
                                    <Link className="nav-link" id="featured" to="#featured" onClick={ourFeatured}>
                                        Our Featured
                                    </Link>
                                </Tab> */}
                            </TabList>

                            {/* <TabPanel className="tab-pane" id="new-arrivals">
                                <div className="row cols-xl-5 cols-lg-4 cols-md-2 cols-2 mb-5">
                                    {products.map((product, index) => (
                                        <ProductSectionTwoForPopular
                                            index={index}
                                            product={product}
                                            addToCart={() => props.addItemToCart(product)}
                                            addToWishlist={() => props.addItemToWishList(product)}
                                            showQuickModal={true}
                                        />
                                    ))}
                                </div>
                            </TabPanel> */}
                            {isLoadig ?
                            <TabPanel className="tab-pane" id="featured">
                                <div className="row cols-xl-5 cols-lg-4 cols-md-3 cols-2 mb-5">
                                    {featured.map((product, index) =>
                                        
                                        <ProductSection
                                            index={product.id}
                                            product={product}
                                            addToCart={() => props.addItemToCart(product)}
                                            addToWishlist={() => props.addItemToWishList(product)}
                                            showModal={true}
                                        />
                                         
                                    )}
                                </div>
                                <div className="row">
                                <div className="col-md-6 col-offset-3 ml-auto mr-auto">
                                    <button onClick={() => loadMore()}
                                            className="btn btn-lg btn-block btn-outline ml-auto mr-auto mt-lg-5 text-center text-white" style={{
                                        backgroundColor: "#99ee99"
                                    }}>Load
                                        More
                                    </button>
                                </div>
                            </div>
                            </TabPanel>
                            :<AppLoader/>
                            }
                        </Tabs>
                    </section>
                </div>
            </div>

        </main>
    );
};

export default NewArrivalComponent;
