import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { DISTRICT_URL } from '../../constants/api'
import {isValidURL} from '../../constants/HomeData'
import axios from 'axios';
import { withRouter} from 'react-router-dom';

class CartComponent  extends Component {
    constructor(props){
        super(props);
        this.state ={
            district:[],
            district_id:'',
            input: {},
            errors: {},
            zip_code:'',
            coupon_code:'',
            coupon_id:'',
            coupon_type:'',
            discount_amount:0,
            cross_sell_product:[]
        }
    }

    componentDidMount() {
        const {items}=this.props
        var cross_sell=[]
        for(var i=0;i<items.length;i++){

            if(items[i].cross_sell_product!==null && items[i].cross_sell_product!==undefined){
                for(var j=0;j<items[i].cross_sell_product.length;j++){  
                    if(cross_sell.length<6){
                    
                        let existed_item = cross_sell.find(item => items[i].cross_sell_product[j].id === item.id)
                        if(!existed_item){
                            cross_sell.push(items[i].cross_sell_product[j])
                        }
                    }
                } 
            }   
          
        }
        this.setState({cross_sell_product:cross_sell})
        this.fetchDistrict();
    };

    fetchDistrict = async () => {
        const result = await axios(
            DISTRICT_URL
        );
        // alert(JSON.stringify(result))
        this.setState({district:result.data.results});
    };

    onChangeText =(e) =>{
        // if(e.target.name){

        // } 
        this.setState({[e.target.name]:e.target.value})

    }

    proceedToCheck = () =>{
        let isValid = true;
        let errors={}
        // if (this.state.district_id === '') {

        //     isValid = false;

        //     errors["distrct_message"] = "Please slecet Disctrict.";

        // }else{
        //     errors["distrct_message"] = "";
        // }

        // if (this.state.zip_code === '') {

        //     isValid = false;

        //     errors["zip_code_messgae"] = "Please enter ZIP/Postal Code";

        // }else{
        //     errors["zip_code_messgae"] = "";
        // }

        // this.setState({
        //     errors: errors
        // });
       
        if(isValid){
            this.props.history.push('/checkout',{ 
                order: {
                    'zip_code':this.state.zip_code,
                    'district_id':this.state.district_id,
                    'coupon_code':this.state.coupon_code
                }
            })
        }
    }

    render(){
      const {district} = this.state
        const { items, total, bundleItems,order } = this.props
        var taxSum = 0.0
        if (items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                for (var j = 0; j < items[i].taxs.length; j++) {
                    taxSum += parseFloat(items[i].taxs[j].rate)
                }
            }
        }

        return (
            <div>
                <main className="main cart container">
                    <div className="page-header bg-dark"
                        style={{ backgroundImage: `url(${'static/images/shop/bgbanner.png'})` }}>
                        <h1 className="page-title">Shopping Cart</h1>
                        <ul className="breadcrumb">
                            <li><Link to={"/"}><i className="d-icon-home"/></Link></li>
                            <li>Cart</li>
                        </ul>
                    </div>
                    <div className="page-content pt-10 pb-10">
                        <div className="container mt-4 mb-4">
                            <div className="row gutter-lg">
                                <div className="col-lg-8">
                                    <h3 className="title title-simple text-left">Shopping cart</h3>
                                    <h6>Product List</h6>
                                    <table className="shop-table cart-table mt-2">
                                        <thead className="d-none">
                                            <tr>
                                                <th className="product-name"><span>Product</span></th>
                                                <th></th>
                                                <th className="product-price"><span>Price</span></th>
                                                <th className="product-quantity"><span>Amount</span></th>
                                                <th className="product-subtotal">Total</th>
                                                <th className="product-remove"/>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item, index) => (
                                                <tr key={index}>

                                                    <td className="product-thumbnail">
                                                        <a href={"/product-details?id="+item.id}>
                                                            <figure>
                                                                <img src={item.feature_image ? isValidURL(item.feature_image):''} width="100" height="100"
                                                                    alt="product" />
                                                            </figure>
                                                        </a>
                                                    </td>
                                                    <td className="product-details">
                                                        <a href={"/product-details?id="+item.id}>{item.product_name.slice(0, 30)}...</a>
                                                    </td>
                                                    <td className="product-name">
                                                        <span className="amount">  
                                                        ৳ {item.is_whole_sell && (item.quantity >=item.purchase_quantity) ?  item.whole_sell_price :Math.round(item.new_price)}
                                                        </span>
                                                    </td>
                                                    <td className="product-name">
                                                        <span className="product-name">{  (item.product_units !==undefined && item.product_units !==null && item.product_units !=='' ?item.product_units.name:'') }</span>
                                                    </td>
                                                    <td className="product-quantity">
                                                        <div className="input-group">
                                                            <button onClick={() => this.props.subtractQuantityFromCart(item)} className="quantity-minus d-icon-minus"></button>
                                                            <span className="quantity form-control cart-quantity">{item.quantity}</span>
                                                            <button onClick={() => this.props.addQuantityToCart(item)} className="quantity-plus d-icon-plus"></button>
                                                        </div>
                                                    </td>
                                                    <td className="product-subtotal">
                                                        <span className="amount">
                                                            
                                                            {item.is_whole_sell && (item.quantity >=item.purchase_quantity) ?  item.whole_sell_price* item.quantity :'৳ ' + item.new_price * item.quantity}
                                                            </span>
                                                    </td>
                                                    <td className="product-remove">
                                                        <div>
                                                            <a onClick={() => this.props.removeQuantityFromCart(item)} className="remove" title="Remove this product"><i
                                                                className="d-icon-times"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                            }

                                        </tbody>
                                    </table>
                                    {
                                        bundleItems.length > 0 ?

                                            <>
                                                <h6>Bundle Product List</h6>
                                                <table className="shop-table cart-table mt-2">
                                                    <thead className="d-none">
                                                        <tr>
                                                            <th className="product-name"><span>Product</span></th>
                                                            <th></th>
                                                            <th className="product-price"><span>Price</span></th>
                                                            <th className="product-quantity"><span>Amount</span></th>
                                                            <th className="product-subtotal">Total</th>
                                                            <th className="product-remove"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bundleItems.map((item, index) => (
                                                            <tr key={index}>

                                                                <td className="product-thumbnail">
                                                                    <a href={'bundle-product-details?id='+item.id}>
                                                                        <figure>
                                                                            <img src={ item.feature_image ? isValidURL(item.feature_image):''} width="100" height="100"
                                                                                alt="product" />
                                                                        </figure>
                                                                    </a>
                                                                </td>
                                                                <td className="product-name">
                                                                    <a href="product-simple.html">{item.title}</a>
                                                                </td>
                                                                <td className="product-name">
                                                                    <span className="amount">৳ {item.quantity *item.total_price}</span>
                                                                </td>
                                                                <td className="product-quantity">
                                                                    <div className="input-group">
                                                                        <button onClick={() => this.props.bundleSubtractQuantityFromCart(item)} className="quantity-minus d-icon-minus"></button>
                                                                        <span className="quantity form-control cart-quantity">{item.quantity}</span>
                                                                        <button onClick={() => this.props.bundleAddQuantityToCart(item)} className="quantity-plus d-icon-plus"></button>
                                                                    </div>
                                                                </td>
                                                                <td className="product-subtotal">
                                                                    <span className="amount">{item.total_price * item.quantity}</span>
                                                                </td>
                                                                <td className="product-remove">
                                                                    <div>
                                                                        <a onClick={() => this.props.bundleRemoveQuantityFromCart(item)} className="remove" title="Remove this product"><i
                                                                            className="d-icon-times"></i></a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>

                                            </>

                                            :
                                            null
                                    }
                                    
                                    <div className="cart-actions mb-6">
                                        <a href="/" className="btn btn-link btn-primary btn-reveal-right" style={{paddingRight: "12px"}}>Continue
									Shopping<i className="d-icon-arrow-right"></i></a>
                                        <button type="button" onClick={() => this.props.emptyCart()}  className="btn btn-link btn-primary btn-icon-right">Clear Cart<i
                                            className="d-icon-thrush"></i></button>
                                    </div>
                                </div>
                                <aside className="col-lg-4 sticky-sidebar-wrapper">
                                    <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}">
                                        <div className="summary mb-4">
                                            <h3 className="title title-simple text-left">Estimate Shipping and Tax</h3>
                                            {/* <table className="shipping">
                                                <tr className="shipping-row">
                                                    <td>
                                                        <div className="custom-radio">
                                                            <input type="radio" id="standard-delivery" name="shipping" className="custom-control-input" />
                                                            <label className="custom-control-label" for="standard-delivery">Standard Delivery</label>
                                                        </div>
                                                    </td>
                                                    <td>$0.00</td>
                                                </tr>
                                                <tr className="shipping-row">
                                                    <td>
                                                        <div className="custom-radio">
                                                            <input type="radio" id="fast-delivery" name="shipping" className="custom-control-input" />
                                                            <label className="custom-control-label" for="fast-delivery">Fast Delivery</label>
                                                        </div>
                                                    </td>
                                                    <td>$10.00</td>
                                                </tr>
                                                <tr className="shipping-row">
                                                    <td>
                                                        <div className="custom-radio">
                                                            Tax
                                                        </div>
                                                    </td>
                                                    <td>BDT {taxSum}</td>
                                                </tr>
                                            </table> */}
                                            {/* <div className="shipping-address">
                                                <label>District *</label>
                                                <div className="select-box">
                                                    <select name="district_id" onChange={this.onChangeText} className="form-control">
                                                        <option value="" selected="selected">Select Your District</option>
                                                        {district.map((item,index)=>(

                                                        <option key={index} value={item.id}>{item.name}</option>
                                                        ))}
                                                       
                                                    </select> 
                                                    <div className="text-danger">{this.state.errors.distrct_message}</div>
                                                </div>
                                                <label>ZIP/Postal Code *</label>
                                                <input type="text" className="form-control" onChange={this.onChangeText}  name="zip_code"
                                                    placeholder="Zip/Postal Code" />
                                                     <span className="text-danger" >{this.state.errors.zip_code_messgae}</span>
                                            </div> */}
                                            <div className="cart-total">
                                                <a href="#" className="btn btn-link btn-primary btn-underline btn-calc">Calculate
											shopping</a>
                                                <table>
                                                    <tr className="cart-subtotal">
                                                        <th>Subtotal:</th>
                                                        <td>৳ {total}</td>
                                                    </tr>
                                                   
                                                    <tr className="order-total">
                                                        <th>Total:</th>
                                                        <td>৳ {total + taxSum}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <button onClick={()=>this.proceedToCheck()} className="btn btn-primary btn-checkout">Go to checkout</button>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                    {this.state.cross_sell_product.length>0 ?
                    <div className="container mb-5">
                        <div className="row">
                            <section>
                                <h2 className="title title-link mb-4">Products You Might 
							<a href="#" className="btn btn-link btn-slide-right">Show more<i className="fa fa-chevron-right"></i></a>
                                </h2>

                                <div className="owl-carousel owl-theme owl-nav-full row row cols-xl-5 cols-lg-4 cols-md-3 cols-2"
                                    data-owl-options="{
									'items': 6,
									'margin': 20,
									'nav': false,
									'dots': false,
									'autoplay': true,
									'responsive': {
										'0': {
											'items': 2
										},
										'576': {
											'items': 3
										},
										'768': {
											'items': 4
										},
										'992': {
											'items': 5
										},
										'1200': {
											'items': 6
										}
									}
								}">
                                     {this.state.cross_sell_product.map((product, index) => (
                                    <div className="product shadow-media" key={index}>
                                   
                                 
                                        <figure className="product-media">
                                            <a href={"/product-details?id="+product.id}>
                                                <img src={isValidURL(product.feature_image)} alt="product" width="280"
                                                    height="315" />
                                            </a>
                                            {/* <div className="product-label-group">
                                                <label className="product-label label-new">new</label>
                                            </div> */}
                                            <div className="product-action-vertical">
                                                <a href="#"  onClick={() => this.props.addToCart(product)} className="btn-product-icon btn-cart" data-toggle="modal"
                                                    data-target="#addCartModal" title="Add to cart"><i
                                                        className="d-icon-bag"></i></a>
                                            </div>
                                            <div className="product-action">
                                                <a href="#" className="btn-product btn-quickview" title="Quick View">Quick
											View</a>
                                            </div>
                                        </figure>
                                        <div className="product-details">
                                            <a href="#"  onClick={() => this.props.addItemToWishList(product)} className="btn-wishlist" title="Add to wishlist"><i
                                                className="d-icon-heart"></i></a>
                                            {/* <div className="product-cat">
                                                <a href="shop-grid-3col.html">categories</a>
                                            </div> */}
                                            <h3 className="product-name">
                                                <a href={"/product-details?id="+product.id}>{product.product_name}</a>
                                            </h3>
                                            <div className="product-price">
                                                    {product.discount_type === 1 || product.discount_type === 2 ? (
                                                                <ins className="new-price">৳ {product.new_price}</ins>

                                                            ) :
                                                                <ins className="new-price">৳ {product.new_price}</ins>

                                                            }
                                                            {
                                                                product.discount_type === 1 &&   product.discount_type === 2? (
                                                                    <del
                                                                        className="old-price">৳ {product.regular_price}</del>
                                                                ) : null

                                                    }
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings-full">
                                                    <span className="ratings" style={{ width: "100%" }}></span>
                                                    <span className="tooltiptext tooltip-top"></span>
                                                </div>
                                                <a href="#" className="rating-reviews">( <span className="review-count">6</span>
											reviews )</a>
                                            </div>
                                        </div>
                                    </div>
                                     ))}
                                    
                                </div>
                            </section>
                        </div>
                    </div>
                    :null
                    }

                </main>
            </div>
        )
    }
}

export default withRouter(CartComponent)
