import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BASE_URL, FAQ_URL } from '../../constants/api'
import axios from 'axios';

const FaqComponent = (props) => {
    const [data, setData] = useState([]);
    

    // alert(JSON.stringify(data))

    useEffect(() => {
        fetchFaq(FAQ_URL)
    }, [])

    const fetchFaq = async (url) => {
        const result = await axios(
            url,
        );
        setData(data => [...data, ...result.data.results]);
        
    };


    return (
        <div>
            <main className="main">
            <div className="page-content mt-10 pt-7">
                <section className="about-section">
                    <div className="container">
                        <h1 className="title mb-lg-12 font-weight-bold" style={{fontSize: "25px"}}>FAQ</h1>
                        <div className="row mb-10">
                            <div className="col-md-12">
                            
                               
                                {data.map(privacy=>(
                                    
                                <p>{Object.keys(privacy).length>0 ? <div dangerouslySetInnerHTML={{__html: privacy.faq}} /> : ''}</p>
                                ))}  
                                
                           
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End About Section--> */}
            </div>
</main>
        </div>
    )
}

export default FaqComponent
