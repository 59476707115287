import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';

import configureStore from '../src/store/index';
import * as serviceWorker from './serviceWorker';
import Routes from './routes/index';

// Components
import './index.css';


// Load css


const { persistor, store } = configureStore();
// persistor.purge(); // Debug to clear persist

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <Routes />
      </Router>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
