import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {emptyCart} from '../../actions/cartActions'
import {ORDER_CREATE,PRE_ORDER_ADVANCE_PRECHANTAGE} from '../../constants/api'

class CheckoutContainer extends Component {
    static propTypes = {
        Layout: PropTypes.func.isRequired,
        emptyCart: PropTypes.func.isRequired,
    }

    placeOrder = (data) => {
        const {items, total, bundleItems} = this.props;
        const bundle_products = [];
        let total_order_quantity = 0;
        let total_due = 0;
        for (let i = 0; i < items.length; i++) {
            total_order_quantity += items[i].quantity
            // alert(items[i].is_pre_order)
            if (items[i].is_pre_order===true){
               
                let advance= parseFloat(items[i].new_price*items[i].quantity)*(PRE_ORDER_ADVANCE_PRECHANTAGE/100)            
                total_due +=(parseFloat(items[i].new_price*items[i].quantity))-advance
            }
              
               
            }

        for (let i = 0; i < bundleItems.length; i++) {
            bundle_products.push({
                'order_quantity': bundleItems[i].quantity,
                'id': bundleItems[i].id
            })
            total_order_quantity += bundleItems[i].quantity
        }
        const total_order_price = data.reward_points > 0 ? 0 : (total + parseFloat(data.shipping_charge)) - data.discount_amount;
        data["bundle_information"] = JSON.stringify(bundle_products);
        data["order_list"] = JSON.stringify(items);
        data["total_order_price"] = total_order_price;
        data["total_due"] = total_due;
        data["total_order_quantity"] = total_order_quantity;
        data['coupon_code']=localStorage.getItem("@coupon",'')
        data['coupon']=localStorage.getItem("@coupon_id",'')

        const token = localStorage.getItem('token');
        if (token !== null && token !== undefined && token !== '') {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': `Token ${token}`},
                body: JSON.stringify(data)
            };

            fetch(ORDER_CREATE, requestOptions)
                .then(res => res.json())
                .then(result => {
                    if (result.id) {
                        localStorage.removeItem('@coupon')
                        localStorage.removeItem('@coupon_id')
                        this.props.emptyCart()
                        
                        this.props.history.push('/order', {order: result.id})
                    }
                });
        } else {
            this.props.history.push('/login')
        }
    }


    render = () => {
        const {Layout, items, total, bundleItems, product_weight} = this.props;
        // if(items.length<1){
        //     window.location.href='/'
        // }
        return (
            <div>
                <Layout
                    placeOrder={this.placeOrder}
                    data={this.state}
                    items={items}
                    total={total}
                    product_weight={product_weight}
                    bundleItems={bundleItems}
                    onTextChange={this.onTextChange}
                    onShippingTextChange={this.onShippingTextChange}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        bundleItems: state.cart.bundleItems,
        total: state.cart.total,
        product_weight: state.cart.product_weight
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        emptyCart: () => {
            dispatch(emptyCart())
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutContainer)
