import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../UI/Footer';

import HeaderTopComponent from '../UI/HeaderTop';



const Template = ({ pageTitle, children }) => (
  <div>
    {/* <Header pageTitle={pageTitle} /> */}
    <HeaderTopComponent pageTitle={pageTitle} />
    { children}
    < Footer />
  </div>
);

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Template.defaultProps = {
  pageTitle: 'Holla Ecommerce',
};

export default Template;