import { 
    ADD_TO_CART,REMOVE_ITEM,SUB_QUANTITY,ADD_QUANTITY,EMPTY_ITEM,ADD_TO_WISHLIST,REMOVE_FROM_WISHLIST,
    BUNDLE_ADD_QUANTITY,BUNDLE_REMOVE_ITEM,BUNDLE_SUB_QUANTITY,BUNDLE_ADD_TO_CART,ADD_TO_CART_FROM_WISHLIST
    ,ONCHANGE_ADD_TO_CART
} from './action-types/cart-actions'

//add cart action
export const addToCart= (data)=>{
   // alert(JSON.stringify(data))
    return{
        type: ADD_TO_CART,
        payload:{
            data
        }
    }
}

export const onChangeaddToCart= (data)=>{
    // alert(JSON.stringify(data))
     return{
         type: ONCHANGE_ADD_TO_CART,
         payload:{
             data
         }
     }
 }
 

export const bundleAddToCart= (data)=>{
    // alert(JSON.stringify(data))
     return{
         type: BUNDLE_ADD_TO_CART,
         payload:{
             data
         }
     }
 }

 export const addItemToWishList= (data)=>{
    // alert(JSON.stringify(data))
     return{
         type: ADD_TO_WISHLIST,
         payload:{
             data
         }
     }
 }
 export const removeItemToWishList= (data)=>{
    // alert(JSON.stringify(data))
     return{
         type: REMOVE_FROM_WISHLIST,
         payload:{
             data
         }
     }
 }
 export const addToCartFromWishList= (data)=>{
    // alert(JSON.stringify(data))
     return{
         type: ADD_TO_CART_FROM_WISHLIST,
         payload:{
             data
         }
     }
 }
 
 export const bundleRemoveItem=(data)=>{
    return{
        type: BUNDLE_REMOVE_ITEM,
        payload:{
            data
        }
    }
}

export const bundleSubtractQuantity=(data)=>{
    return{
        type: BUNDLE_SUB_QUANTITY,
        payload:{
            data
        }
    }
}
//add qt action
export const bundleAddQuantity=(data)=>{
    return{
        type: BUNDLE_ADD_QUANTITY,
        payload:{
            data
        }
    }
}

//remove item action
export const removeItem=(data)=>{
    return{
        type: REMOVE_ITEM,
        payload:{
            data
        }
    }
}
//subtract qt action
export const subtractQuantity=(data)=>{
    return{
        type: SUB_QUANTITY,
        payload:{
            data
        }
    }
}
//add qt action
export const addQuantity=(data)=>{
    return{
        type: ADD_QUANTITY,
        payload:{
            data
        }
    }
}

export const emptyCart=()=>{
    return{
        type: EMPTY_ITEM,
    }
}