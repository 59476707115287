import React, {  useState, useEffect } from 'react'
import {  TERMS_CONDITION_URL } from '../../constants/api'
import axios from 'axios';

const TermsConditionComponent = (props) => {
    const [data, setData] = useState([]);
    

    // alert(JSON.stringify(data))

    useEffect(() => {
        fetchTermsCondition(TERMS_CONDITION_URL)
    }, [])

    const fetchTermsCondition = async (url) => {
        const result = await axios(
            url,
        );
        setData(data => [...data, ...result.data.results]);
        
    };


    return (
        <div>
            <main className="main">
            <div className="page-content mt-10 pt-7">
                <section className="about-section">
                    <div className="container">
                        <h1 className="title mb-lg-12 font-weight-bold" style={{fontSize: "25px"}}>Terms & Conditions</h1>
                        <div className="row mb-10">
                            <div className="col-md-12">
                                {/* <div className="p-8 my-4 bg-white rounded-lg shadow"> */}
                                {data.map(terms=>(
                                    
                                <p>{Object.keys(terms).length>0 ? <div dangerouslySetInnerHTML={{__html: terms.terms_condition}} /> : ''}</p>
                                ))}  
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End About Section--> */}
            </div>
</main>
        </div>
    )
}

export default TermsConditionComponent
