import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import {
    FOOTER_SOCIAL_MEDIA_URL,
    FOOTER_LEFT_SECTION_URL,
    COPY_RIGHT_URL,
    CONTACT_INFO_URL,
    FOOTER_LOGO_URL,
    FOOTER_PAYMENT_IMAGE_URL
} from '../../constants/api'
import axios from 'axios';

const Footer = () => {
    const [data, setData] = useState([]);
    const [footerLeft, setFooterLeft] = useState([]);
    const [copyText, setCopyText] = useState([])
    const [paymentImage, setPaymentImage] = useState([])
    const [contact, setContact] = useState([])
    const [logo, setLogo] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(
                FOOTER_SOCIAL_MEDIA_URL,
            );
            setData(result.data.results);
        };
        fetchData().then(() => {
        })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(FOOTER_LEFT_SECTION_URL);
            setFooterLeft(result.data.results);
        };

        fetchData().then(() => {})
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(COPY_RIGHT_URL);
            setCopyText(result.data.results);
        };
        fetchData().then(() => {
        })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(CONTACT_INFO_URL);
            setContact(result.data.results);
        };

        fetchData().then(() => {
        })
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(FOOTER_LOGO_URL);
            setLogo(result.data.results);
        };

        fetchData().then(() => {
        })
    }, []);


    useEffect(() => {
        // alert(JSON.stringify(FOOTER_PAYMENT_IMAGE_URL))
        const fetchData = async () => {
            const result = await axios(FOOTER_PAYMENT_IMAGE_URL);
       
            
            setPaymentImage(result.data.results);
        };

        fetchData().then(() => {
        })
    }, []);

    const regex = /(<([^>]+)>)/ig;
    const regex2 = /(&([a-z]+);)/gi;

    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="footer-middle">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget-about">
                                    {logo.map(logo => (
                                        <a href="" className="logo-footer" key={'logo-dfd-' + logo?.logo}>
                                            <img src={logo.logo} alt="logo-footer" width="163" height="50"/>
                                        </a>
                                    ))}
                                    {footerLeft.map(item => (
                                        <div className="widget-body" key={'dfd-dd-dfd' + item?.email}>
                                            <p>{item.description.replace(regex, '').replace(regex2, '')}</p>
                                            <a>{item.email}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget ml-lg-4">
                                    <h4 className="widget-title">My Account</h4>
                                    <ul className="widget-body">
                                        <li>
                                            <a href='/cart'>My Cart</a>
                                        </li>
                                        <li>
                                            <a href="/support">Support</a>
                                        </li>
                                        {/* <li>
                                            <a href="#">Order History</a>
                                        </li> */}
                                        <li>
                                            <a href="/wishlist">Wishlist</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget ml-lg-4">
                                    <h4 className="widget-title">Quick Links</h4>
                                    <ul className="widget-body">
                                        <li>
                                            <a href='/about-us'>About Us</a>
                                        </li>
                                        {/* <li>
                                            <a href="/blog">Blogs</a>
                                        </li> */}
                                        <li>
                                            <a href="/terms-condition">Terms & Condition</a>
                                        </li>
                                        <li>
                                            <a href="/shipping-policy">Shipping Policy</a>
                                        </li>
                                        <li>
                                            <a href="/privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="/return-refund-policy">Return & Refund Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget widget-contact ml-lg-4">
                                    <h4 className="widget-title">Contact us</h4>
                                    <ul className="widget-body">
                                        {contact.map((item, index) => (
                                            <>
                                                <li key={'sd' + index}>
                                                    <label>Address</label>
                                                    <a href="#!">{item.address}</a>
                                                </li>
                                                <li key={'ssdd' + index}>
                                                    <label>Phone</label>
                                                    <a href="#!">{item.phone_no}</a>
                                                </li>
                                                <li key={'sdfdfdd' + index}>
                                                    <label>Email</label>
                                                    <a href="#!">{item.email}</a>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-left">
                            {paymentImage.map(payimg => (
                            <figure className="payment">
                                
                                    <img src={payimg.image} alt="payment" width="220" height="40"/>
                                
                            </figure>
                            ))}
                        </div>
                        <div className="footer-center">
                            {copyText.map((cpytext, index) => (
                                <span key={'dfdfddfd' + index} style={{color:'white'}}>
                                    {cpytext.copy_right.replace(regex, '').replace(regex2, '')}
                                </span>
                            ))}
                        </div>
                        <div className="footer-right">
                                <div className="widget ml-lg-4">
                                    
                                    <ul className="widget-body">
                                        <li style={{color:'white'}}>Get Customer App</li>
                                        <li>
                                            <a href='https://play.google.com/store/apps/details?id=com.niraapod.mychoice' target="_blank"><img src="playstore.png" alt="" width="150" height="50"/></a>
                                        </li>
                                        
                                       
                                    </ul>
                                </div>
                        </div>
                        <div className="footer-right">
                                <div className="widget ml-lg-4">
                                    
                                    <ul className="widget-body">
                                        <li style={{color:'white'}}>Get Agent App</li>
                                        <li>
                                            <a href='https://play.google.com/store/apps/details?id=com.mychoice.mychoiceagent' target="_blank"><img src="playstore.png" alt="" width="150" height="50"/></a>
                                        </li>
                                        
                                       
                                    </ul>
                                </div>
                        </div>
                        {/* <div className="footer-right">
                                <p>Customer App</p>
                            <div className="social-links">
                                    <a href="https://play.google.com/store/apps/details?id=com.niraapod.mychoice" target="_blank" title="Customer app">
                                        <img src="playstore.png" alt="" width="150" height="50"/>
                                    </a>
                                
                            </div>
                        </div>
                        <div className="footer-right">
                                <p>Customer App</p>
                            <div className="social-links">
                                    <a href="https://play.google.com/store/apps/details?id=com.niraapod.mychoice" target="_blank" title="Customer app">
                                        <img src="playstore.png" alt="" width="150" height="50"/>
                                    </a>
                                
                            </div>
                        </div> */}
                        <div className="footer-right">
                            <div className="social-links">
                                {data.map((item, index) => (
                                    <a key={'xdfd' + index} href={item.social_url} target="_blank" title={item.title}>
                                        <img src={item.social_icon} alt="" width="30" height="30"/>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="sticky-footer sticky-content fix-bottom">
                <a href="/" className="sticky-link active">
                    <i className="d-icon-home"/>
                    <span>Home</span>
                </a>
                {/* <a href="/category" className="sticky-link">
                    <i className="d-icon-volume"/>
                    <span>Categories</span>
                </a> */}
                <a href="/wishlist" className="sticky-link">
                    <i className="d-icon-heart"/>
                    <span>Wishlist</span>
                </a>
                <Link to="/account" className="sticky-link">
                    <i className="d-icon-user"/>
                    <span>Account</span>
                </Link>
                <div className="dropdown cart-dropdown">
                    <a href="/cart" className="sticky-link cart-toggle">
                        <i className="d-icon-bag"/>
                        <span>Cart</span>
                    </a>
                    {/* <div className="dropdown-box">
                        <div className="product product-cart-header">
                            <span className="product-cart-counts">2 items</span>
                            <span><a href="/cart">View cart</a></span>
                        </div>
                        <div className="products scrollable">
                            <div className="product product-cart">
                                <div className="product-detail">
                                    <a href="product.html" className="product-name">Solid Pattern In Fashion Summer
                                        Dress</a>
                                    <div className="price-box">
                                        <span className="product-quantity">1</span>
                                        <span className="product-price">$129.00</span>
                                    </div>
                                </div>
                                <figure className="product-media">
                                    <a href="#">
                                        <img src="static/images/cart/product-1.jpg" alt="product" width="90"
                                             height="90"/>
                                    </a>
                                    <button className="btn btn-link btn-close">
                                        <i className="fas fa-times"/>
                                    </button>
                                </figure>
                            </div>
                            <div className="product product-cart">
                                <div className="product-detail">
                                    <a href="product.html" className="product-name">Mackintosh Poket Backpack</a>
                                    <div className="price-box">
                                        <span className="product-quantity">1</span>
                                        <span className="product-price">$98.00</span>
                                    </div>
                                </div>
                                <figure className="product-media">
                                    <a href="#">
                                        <img src="static/images/cart/product-2.jpg" alt="product" width="90"
                                             height="90"/>
                                    </a>
                                    <button className="btn btn-link btn-close">
                                        <i className="fas fa-times"/>
                                    </button>
                                </figure>
                            </div>
                        </div>
                        <div className="cart-total">
                            <label>Subtotal:</label>
                            <span className="price">$42.00</span>
                        </div>
                        <div className="cart-action">
                            <a href="checkout.html" className="btn btn-dark"><span>Checkout</span></a>
                        </div>
                    </div> */}
                </div>
            </div>
            <a id="scroll-top" href="#top" title="Top" role="button" className="scroll-top">
                <i className="d-icon-angle-up"/>
            </a>
        </div>
    )
}

export default Footer;

