import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { USER_CREATE_URL,USER_SHIPPING_ADDRESS_URL,USER_BILLING_ADDRESS_URL } from '../../constants/api'


class AccountContainer extends Component {
    // constructor(props){
    //     super(props)
    // }

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }

    saveUser = (data)=> {
       
       
        var token = localStorage.getItem('token', '');   
        if (token !==null && token!==undefined && token !==''){

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json','Authorization':`Token ${token}` },
                body:JSON.stringify(data)
            };
            let id = this.props.match.params.id
            console.log(id)
            fetch(USER_CREATE_URL+data.id+'/', requestOptions)
                .then(res => res.json())
                .then(result => {
                    console.log(result)
                    // alert(JSON.stringify(result))
                    if(result.id ){
                       
                        this.props.history.push('/account',{ user: result.id })
    
                    }else{
                        //this.props.history.push('/')
                    }
                    
            });
    
    

        }else{
            this.props.history.push('/login')
        }
       

    }


    saveUserShippingAddress =(data)=>{
        var token = localStorage.getItem('token', '');   
        if (token !==null && token!==undefined && token !==''){

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Authorization':`Token ${token}` },
                body:JSON.stringify(data)
            };
            fetch(USER_SHIPPING_ADDRESS_URL, requestOptions)
                .then(res => res.json())
                .then(result => {
                    console.log(result)
                    alert("Your Shipping Address Successfully Updated")
                    // alert(JSON.stringify(result))
                    // if(result.id ){
                       
                    //     this.props.history.push('/account',{ user: result.id })
    
                    // }else{
                    //     //this.props.history.push('/')
                    // }
                    
            });
        }
    }

    saveUserBillingAddress =(data)=>{
        var token = localStorage.getItem('token', '');   
        if (token !==null && token!==undefined && token !==''){

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Authorization':`Token ${token}` },
                body:JSON.stringify(data)
            };
            fetch(USER_BILLING_ADDRESS_URL, requestOptions)
                .then(res => res.json())
                .then(result => {
                    console.log(result)
                    alert("Your Billing Address Successfully Updated")
                    
                    // alert(JSON.stringify(result))
                    // if(result.id ){
                       
                    //     this.props.history.push('/account',{ user: result.id })
    
                    // }else{
                    //     //this.props.history.push('/')
                    // }
                    
            });
        }
    }



    render = () => {
        const { Layout } = this.props;

        // const { error } = this.state;

        return (
            <div>
                <Layout 
                    saveUser ={this.saveUser}
                    data = {this.state}
                    saveUserShippingAddress={this.saveUserShippingAddress}
                    saveUserBillingAddress={this.saveUserBillingAddress}

                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {

    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountContainer)

