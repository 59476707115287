export const RatingValueToPerchantage = (rating) => {
  
    if(rating>0){
       rating= parseFloat(rating)*20
    }
    return rating
}

export const RatingPerchantageToValue = (rating) => {
  
    if(rating>0){
       rating= parseFloat(rating)/20
    }
    return rating
}


export const  calculateCouponDiscount = (discountType,couponType,amount,total_amount)=>{
    var discount=0
   
    if(discountType!=='' &&  couponType!==''){

        if(discountType===1 && amount<=total_amount){
            discount= (parseFloat(total_amount)*(parseFloat(amount)/100))
            // alert(discount)
        }else{
            discount=amount
        }
      
    }
    return parseFloat(discount)


}