import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';



class BrandsContainer extends Component {

    static propTypes = {
        Layout: PropTypes.func.isRequired,
    }





    render = () => {
        const { Layout } = this.props;

        // const { error } = this.state;

        return (
            <div>
                <Layout />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = (dispatch) => {

    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandsContainer)

