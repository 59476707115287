import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



class Error404Component extends Component {
    render() {
        return (
            <div>
                <main className="main">
                    <div className="page-content">
                        <section
                            className="error-section d-flex flex-column justify-content-center align-items-center text-center pl-3 pr-3">
                            <h1 className="mb-2 ls-m">Error 404</h1>
                            <img src="static/images/subpages/404.png" alt="error 404" width="609" height="131" />
                            <h4 className="mt-7 mb-0 ls-m text-uppercase">Ooopps.! That page can’t be found.</h4>
                            <p className="text-grey font-primary ls-m">It looks like nothing was found at this location.</p>
                            <a href="/" className="btn btn-primary mb-4">Go home</a>
                        </section>
                    </div>
                </main>
            </div>
        )
    }
}
export default Error404Component

