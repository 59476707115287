import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SwiperCore, { Pagination,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { SLIDE_RIGHT_SIDE_SETTING, SLIDE_SETTING } from '../../constants/api';
// install Swiper modules
SwiperCore.use([Pagination,Autoplay]);


const OfferComponent = (props) => {
    const [data, setData] = useState([]);
    const [rightslide, setRightSlide] = useState([])
    const fetchDataRightSilde = async () => {
        const result = await axios(
            SLIDE_RIGHT_SIDE_SETTING,
        );
        setRightSlide(result.data.results)
    };
    const fetchData = async () => {
        const result = await axios(
            SLIDE_SETTING,
        );
        setData(result.data.results)
    };
    useEffect(() => {
        
        fetchData()
        fetchDataRightSilde()
    }, [])
    

    return (
        <div>
            <main className="main mt-lg-4">
                <div className="page-content">
                    <div className="container">
                        <section className="intro-section">
                            <div className="row">
                                <div className="col-md-8">
                                    {data.length>0 && data !==undefined && data !==null ?
                                    <Swiper slidesPerView={1}  pagination={{ clickable: true }} loop={true} autoplay={true} className="mb-4" >
                                        {data.map((item, i) => (
                                            <div key={i}>
                                            <SwiperSlide  className="banner banner-left banner-fixed intro-slide1">
                                                <figure className="banner-figure-main" key={i}>
                                                {item.image!==null && item.image!=='' && item.image!==undefined ?(  
                                                    <img src={item.image} alt="intro-banner" width="580" height="460" key={i}/>
                                                ): 
                                                    <img src='/static/images/no-image.png' alt="intro-banner" width="580" height="460" key={i}/>
                                                }
                                                </figure>
                                                <div className="banner-content w-100 y-50 pl-5" key={i}>
                                                    <div className="slide-animate" key={i}
                                                        data-animation-options="{'name': 'fadeInLeftShorter', 'duration': '1.6s'}">
                                                        <h4
                                                            className="banner-subtitle ls-l text-primary text-uppercase font-weight-bold">
                                                            {item.title !=='' && item.title !==null && item.title !==undefined? item.title :''}</h4>
                                                        <h2 className="banner-title ls-m mb-2 slide-animate" key={i}
                                                            data-animation-options="{'name': 'fadeInLeftShorter', 'duration': '1.4s'}">
                                                            {/* <span
                                                                className="text-uppercase font-weight-normal text-body">Comfortable
                                                            </span> */}
                                                        <strong className="text-uppercase font-weight-normal">{item.description !=='' && item.description !==null && item.description !==undefined? item.description :''}</strong>
                                                        </h2>
                                                        {/* <p className="text-dark">Free Shipping on Orders over $99</p> */}
                                                        {item.button_text !=='' && item.button_text !==null && item.button_text !==undefined && item.button_text !=='No Button'.toLowerCase() && item.button_text !=='NO BUTTON' ?
                                                            <a href={item.button_link} className="btn btn-solid btn-md md-2">{item.button_text !=='' && item.button_text !==null && item.button_text !==undefined? item.button_text:''}</a>
                                                            :null}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            </div>
                                        ))}
                                    </Swiper>
                                    :null}
                                </div>
                                <div className="col-md-4">
                                    {rightslide !==undefined && rightslide !==null?
                                    <div className="row">
                                        {/* <Swiper slidesPerView={1} loop={true}  > */}
                                        {rightslide.map((item, index) => (
                                            // <SwiperSlide key={i} className="col-md-12 col-xs-6">
                                            <div className="col-md-12 col-xs-6" key={index}>
                                                <div className="banner right-slider banner-fixed intro-banner1 mb-4">
                                                    <figure>
                                                        {item.image!==null && item.image!=='' && item.image!==undefined ?(
                                                                <img src={item.image} alt="banner" width="280" height="220" />
                                                            ):
                                                            <img src='/static/images/no-image.png' alt="banner" width="280" height="220" />
                                                        }
                                                    </figure>
                                                    <div className="banner-content y-50 appear-animate-visible">
                                                        <h4 className="banner-subtitle ls-s text-primary font-weight-normal"> {item.title !=='' && item.title !==null && item.title !==undefined ? item.title:''}</h4>
                                                        <h3 className="banner-title ls-s text-white"><span className="text-uppercase">{item.description !=='' && item.description !==null && item.description !==undefined? item.description :''} </span><br />
                                                            {/* <span className="font-weight-normal">Handbags</span> */}
                                                        </h3>
                                                        {item.button_text !=='' && item.button_text !==null && item.button_text !==undefined && item.button_text !=='No Button'.toLowerCase() && item.button_text !=='NO BUTTON' ?
                                                            <a href={item.button_link} className="btn btn-solid btn-sm">{item.button_text !=='' && item.button_text !==null && item.button_text !==undefined? item.button_text:''}</a>
                                                            :null}
                                                    </div>
                                                </div>
                                                </div>
                                            // </SwiperSlide>
                                        ))}
                                        {/* </Swiper> */}
                                    </div>
                                    :null}
                                </div>
                            </div>
                            <div className="service-list service-list-res owl-carousel owl-theme row cols-lg-3 cols-md-2 cols-1"
                                data-owl-options="{
                            'nav': false,
                            'dots': false,
                            'autoplay': true,
                            'margin': 2,
                            'responsive': {
                                '0': {
                                    'items': 1
                                },
                                '768': {
                                    'items': 2
                                },
                                '992': {
                                    'items': 4,
                                    'loop': false
                                }
                            }
                        }">
                                            {/* <div className="icon-box icon-box-side icon-box1">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-truck"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Free Delivery</h4>
                                                    <p>Free Delivery on orders over ৳25000.00</p>

                                                </div>
                                            </div> */}
                                            <div className="icon-box icon-box-side icon-box2">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-money"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Return Policy</h4>
                                                    <p>7 days return</p>
                                                </div>
                                            </div>
                                            <div className="icon-box icon-box-side icon-box3">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-service"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">24/7 support</h4>
                                                    <p>Support every time</p>
                                                </div>
                                            </div>
                                            {/* <div className="icon-box icon-box-side icon-box3">
                                                <span className="icon-box-icon">
                                                    <i className="d-icon-secure"/>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Payment Method</h4>
                                                    <p>COD, Card, bKash, Rocket, Nagad</p>
                                                </div>
                                            </div> */}
                                {/* <div className="icon-box icon-box-tiny appear-animation-visible">
                                    <div className="icon-box-content">
                                        <h4 className="icon-box-title font-weight-bold">
                                            <i className="icon-box-icon d-icon-layer"></i>Free Delivery
                                    </h4>
                                        <p>Free Delivery on orders over ৳25000.00</p>
                                    </div>
                                </div>
                                <div className="icon-box icon-box-side icon-box3">
                                <span className="icon-box-icon">
                                    <i className="d-icon-secure"/>
                                </span>
                                <div className="icon-box-content">
                                    <h4 className="icon-box-title">24/7 support</h4>
                                    <p>Support every time</p>
                                </div>
                            </div>
                                <div className="icon-box icon-box-tiny  appear-animate-visible" >
                                    <div className="icon-box-content">
                                        <h4 className="icon-box-title font-weight-bold">
                                            <i className="icon-box-icon d-icon-shoppingbag"></i>Return Policy
                                    </h4>
                                        <p> 7 days return</p>
                                    </div>
                                </div>
                                <div className="icon-box icon-box-tiny appear-animate-visible" >
                                    <div className="icon-box-content">
                                        <h4 className="icon-box-title font-weight-bold">
                                            <i className="icon-box-icon d-icon-card"></i>Payment Method
                                    </h4>
                                        <p> COD, Card, bKash, Rocket, Nagad</p>
                                    </div>
                                </div> */}
                            </div>
                        </section>

                    </div>
                </div>
            </main>
        </div>
    )
}


export default OfferComponent
