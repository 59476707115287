import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



class PageDesignComponent extends Component {
    render() {
        return (
            <div>
                <main className="main mt-lg-4 mb-lg-10">

                    <div className="container mt-lg-10 mb-lg-10">
                        <div className="row">
                            <div className="col-md-12 text-center mb-lg-10">
                                <h4 className="text-uppercase" style={{ color: "#828282" }}>Become a Successful Seller in 3 step</h4>
                                <h5 className="mt-1" style={{ color: "#828282" }}>Just like this, simply done!</h5>
                            </div>
                            <div className="col-md-12 text-center">
                                <img className="img-relative" src="static/images/bg-img.png" alt="" />
                                <button className="btn text-white bg-amaranth text-uppercase col-md-2 mr-auto ml-auto button-absulate">Start Selling</button>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-lg-10 mb-lg-10">
                        <div className="row mt-10 mb-10">
                            <div className="col-md-12 text-center">
                                <h4 className="text-uppercase" style={{ color: "#828282" }}>Featured and benifits</h4>
                            </div>

                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">Sell Across Bangladesh</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">No Registration Fee</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">Reach Millions of Customers</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">Fast & Reliable Shipping</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">Timely Payments</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mt-5">
                                <div className="card mt-3 border-0">
                                    <div className="card-header bg-white text-center border-0 ">
                                        <div className="row justify-content-center ">
                                            <div className="col">
                                                <a href=""> <span className="fa fa-phone text-white icon-middle"></span></a>
                                            </div>
                                        </div>
                                        <div className="row text-center name">
                                            <div className="col mt-2">
                                                <a href="" className="text-muted mt-2 font-weight-bold text-decoration-none">Ecommerce Training</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container mt-lg-10 mb-lg-10">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8 ml-auto mr-auto">
                                        <div className="seller-div">
                                            <img className="seller-img" src="https://picsum.photos/seed/picsum/200/300" alt="" />
                                            <div style={{ marginLeft: "15%" }}>
                                                <h4 className="mt-5 color-text-red" style={{ color: "red" }}>Junayed received Huge Market Exposure!</h4>
                                                <h5 className="mb-0 color-text-red">Latest trends</h5>
                                                <div className="underline-red"></div>
                                                <p className="mt-3 text-left">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid
                                                    assumenda, atque blanditiis culpa, eaque illo in ipsam labore laboriosam magnam minus
                                quaerat quia reiciendis tempora tenetur, ut velit voluptatum?</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-lg-10 mb-lg-10">
                        <div className="row mt-lg-5 mb-lg-10 margin-top">
                            <div className="col-md-12">
                                <div className="bg-amaranth text-white d-flex justify-content-center"
                                    style={{ height: "100%", maxWidth: "100%", borderRadius: "5px" }}>
                                    <span className="fa fa-phone mr-7" style={{ transform: "rotate(106deg)", fontSize: "113px", textShadow: "2px 3px 2px #f29997 2px 2px 2px #f29997" }}></span>
                                    <h4 className="text-white font-weight-bold mt-5 mr-5">Need More Help? Contact Us! <br /> Call(+88) 096XXXXXXX</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-10 mb-10">
                            <div className="col-md-12 text-center">
                                <h4 style={{ color: "#f29997" }}>Online Selling Website in Bangladesh</h4>
                            </div>
                            <div className="col-md-12 justify-content-center text-muted">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos eveniet explicabo molestiae omnis pariatur!
                                Dicta omnis, quos! Aspernatur, at, corporis cumque dolores fuga, id necessitatibus nostrum perspiciatis
                                tempore vitae voluptatem? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos eveniet explicabo
                                molestiae omnis pariatur! Dicta omnis, quos! Aspernatur, at, corporis cumque dolores fuga, id necessitatibus
                                nostrum perspiciatis tempore vitae voluptatem?
        </div>
                        </div>
                    </div>
                </main>
            </div >
        )
    }
}
export default PageDesignComponent
