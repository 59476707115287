import React from 'react';
import PropTypes from 'prop-types';
import Headers from '../UI/Headers';
import Footers from '../UI/Footers';



const OrderTemplate = ({ pageTitle, children }) => (
    <div>
        <Headers pageTitle={pageTitle} />
        {/* <HeaderTopComponent pageTitle={pageTitle} /> */}
        { children}
        < Footers />
    </div>
);

OrderTemplate.propTypes = {
    pageTitle: PropTypes.string,
    children: PropTypes.element.isRequired,
};

OrderTemplate.defaultProps = {
    pageTitle: 'Order',
};

export default OrderTemplate;