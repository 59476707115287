export default {
    meals: [
      { id: 1, title: '---' },
      { id: 2, title: '----- ---' },
    ],
    recipes: [
      {
        placeholder: true,
        id: 0,
        title: '---- --- -- ------',
        body: '---- --- -- ------ ---- --- -- ------ ---- --- -- ------ ---- --- -- ------',
        author: '---- ------ ',
        category: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/react-native-starter-app.appspot.com/o/image-1.jpg?alt=media&token=9f7c839b-2d40-4660-a2a0-bf6c2f64a2e5',
        ingredients: [
          '---- --- -- ------',
          '---- ------ --- --',
        ],
        method: [
          '---- --- -- ------',
          '---- ------ --- --',
        ],
      },
    ],
  };
  