import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes, { object } from 'prop-types';
import axios from 'axios';
import { NO_TOKEN_BRANDS,BASE_URL,PRODUCT_URL, GET_ALL_CATEGORY_URL,NO_TOKEN_CHILD_CATEGORY_URL } from '../../constants/api'
import {RatingValueToPerchantage} from "../../constants/customMethod";
import { AppLoader } from '../../constants/HomeData';

const BrandProductComponent = (props) => {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [next, setNext] = useState('');
    const [count, setCount] = useState(0);
    const [isLoadig, setLoading] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        var brand_id = query.get('brand__id')
        fetchData(PRODUCT_URL+"&brand="+brand_id)
        // const url = new URLSearchParams(props.query);
        // fetchData(CATEGORY_URL + "?"+url);
        // getCategory()
    }, []);

    const fetchData = async (url) => {
        // alert(url)
       const result = await axios(
           url,
       );
       setProducts([]);
       setProducts(result.data.results);
       setLoading(true)
       setNext(result.data.next);
       setCount(result.data.count)
//alert(result.data.count)
   };

   const loadMore = async () => {
    if (next !== null) {
        fetchData(next).then(() => {
        })
    }
}
   const getCategory = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(GET_ALL_CATEGORY_URL, requestOptions)
            .then(res => res.json())
            .then(category => {

                 
                setCategories(category)
                
            });
    }
    return (
        <div>
            <main className="main">
                <div className="page-content mb-10">
                    <div className="container">
                        <div className="row main-content-wrap gutter-lg">
                            <div className="col-md-12 mt-3 mb-2 d-flex">
                                <div className="w-100 bg-white" style={{ height: "130px", boxShadow: "0px 0px 2px 2px #E7E8E9", borderRadius: "10px", position: "relative" }}>
                                    <div className="ml-3">
                                        <img src="mychoice.png" alt="" height="80" width="80" style={{ position: "absolute", top: "50%", transform: "translate(0, -50%)", border: "2px solid #E7E8E9" }} />
                                        {/* <h5 className="text-uppercase" style={{ position: "absolute", top: "42%", transform: "translate(222%, -50%)" }}>Teer</h5> */}
                                        <h6 className="font-normal ml-1" style={{ position: "absolute", top: "50%", transform: "translate(60%, -50%)" }}>{props.state.count} products found</h6>
                                    </div>
                                </div>
                            </div>
                            {/* <aside className="col-lg-3 sidebar sidebar-fixed shop-sidebar sticky-sidebar-wrapper mt-3">
                                <div className="sidebar-overlay">
                                    <a className="sidebar-close" href="#"><i className="d-icon-times"></i></a>
                                </div>
                                <div className="sidebar-content">
                                    <div className="sticky-sidebar">
                                        <div className="widget widget-collapsible">
                                            <h3 className="widget-title">All Categories</h3>
                                            <ul className="widget-body filter-items search-ul">
                                             
                                            {categories.map((primary_category,index)=> (
                                                <> 
                                                <li className="with-ul" key={index}>
                                                    <a href={"/category?primary_categories__id="+primary_category.primary_category.id}>{primary_category.primary_category.name}</a>
                                                    {primary_category.secondary_category.length>0 ?
                                                    <ul>
                                                    {primary_category.secondary_category.map((secondary, secindex) => (

                                                        <li key={secindex}><a href="#">{secondary.name}</a></li>
                                                    ))}   
                                                    </ul>
                                                    :null
                                                    }
                                                </li>
                                                
                                                </>      
                                              ))}
                                           
                                           
                                             
                                            </ul>
                                        </div>
                                      
                                      
                                    </div>
                                </div>
                            </aside> */}
                            {isLoadig?
                            <div className="w-100 main-content">
                                {/* <nav className="toolbox sticky-toolbox sticky-content fix-top mt-10">
                                    <div className="toolbox-left">
                                        <a href="#"
                                            className="toolbox-item left-sidebar-toggle btn btn-sm btn-outline btn-primary d-lg-none">Filters<i
                                                className="d-icon-arrow-right"></i></a>
                                        <div className="toolbox-item toolbox-sort select-box">
                                            <label>Sort By :</label>
                                            <select name="orderby" className="form-control">
                                                <option value="default">Default</option>
                                                <option value="popularity" selected="selected">Most Popular</option>
                                                <option value="rating">Average rating</option>
                                                <option value="date">Latest</option>
                                                <option value="price-low">Sort forward price low</option>
                                                <option value="price-high">Sort forward price high</option>
                                                <option value="">Clear custom sort</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="toolbox-right">
                                        <div className="toolbox-item toolbox-show select-box">
                                            <label>Show :</label>
                                            <select name="count" className="form-control">
                                                <option value="12">12</option>
                                                <option value="24">24</option>
                                                <option value="36">36</option>
                                            </select>
                                        </div>
                                        <div className="toolbox-item toolbox-layout">
                                            <a href="#" className="d-icon-mode-list btn-layout"></a>
                                            <a href="#" className="d-icon-mode-grid btn-layout active"></a>
                                        </div>
                                    </div>
                                </nav> */}
                                <div className="row cols-2 cols-sm-3 product-wrapper">
                                {products.map((product, index) => (
                                    <div className="col-md-2">
                                        <div className="product-wrap">
                                            <div key={index} className="product shadow-media">
                                                <figure className="product-media">
                                                    <a href={"/product-details?id=" + product.id}>
                                                        <img src={product.feature_image} alt="product" width="280" height="315" />
                                                    </a>
                                                    <div className="product-label-group">
                                                    {/* {product.discount_type === 1 ? (
                                                            <label className="product-label label-sale">৳ {product.discount_price}% OFF</label>


                                                        ) :
                                                    <label className="product-label label-sale">৳ {product.discount_price} BDT OFF</label>
                                                }    */}
                                                    </div>
                                                    {/* <div className="product-action-vertical">
                                                        <a href="javascript:void(0);" onClick={() => props.addItemToCart(product)} className="btn-product-icon btn-cart" data-toggle="modal"
                                                            data-target="#addCartModal" title="Add to cart"><i
                                                                className="d-icon-bag"></i></a>
                                                    </div> */}
                                                    {/* <div className="product-action">
                                                        <a href="#" className="btn-product btn-quickview" title="Quick View">Quick
														View</a>
                                                    </div> */}
                                                </figure>
                                                <div className="product-details">
                                                    {/* <a href="javascript:void(0);" onClick={() => props.addItemToWishList(product)}   className="btn-wishlist" title="Add to wishlist"><i
                                                        className="d-icon-heart"></i></a> */}
                                                    <div className="product-cat">
                                                        {/* <a href="javascript:void(0);">categories</a> */}
                                                    </div>
                                                    <h3 className="product-name">
                                                    <a href={"/product-details?id=" + product.id}>{product.product_name}</a>
                                                    </h3>
                                                    <h3 className="product-name">
                                                    <a href={"/product-details?id=" + product.id}>SKU: {product.product_sku}</a>
                                                    </h3>
                                                    <div className="product-price">
                                                    {product.discount_type === 1 || product.discount_type === 2 ? (
                                                    <ins className="new-price">৳ {product.new_price}</ins>

                                                    ) :
                                                        <ins className="new-price">৳ {product.new_price}</ins>

                                                    }
                                                    {
                                                        product.discount_type === 1 || product.discount_type === 2 ? (
                                                            <del
                                                                className="old-price">৳ {product.regular_price}</del>
                                                        ) : null

                                                    }
                                                        
                                                    {/* {
                                                    product.discount_type === 1 || product.discount_type === 2 ? (
                                                        <ins className="new-price">BDT {product.new_price}</ins>

                                                            ) :
                                                                <ins className="new-price">BDT {product.new_price}</ins>

                                                            }
                                                            {
                                                                product.discount_type !== '' ? (
                                                                    <del
                                                                        className="old-price">BDT {product.regular_price}</del>
                                                                ) : null

                                                    } */}

                                                    </div>
                                                    <div className="ratings-container">
                                                        <div className="ratings-full">
                                                            <span className="ratings"
                                                                style={{width: RatingValueToPerchantage(product?.avg_rating) + "%",}}/>
                                                        </div>
                                                    </div>
                                                    {/* <div className="ratings-container">
                                                        <div className="ratings-full">
                                                            <span className="ratings" style={{ width: "100%" }}></span>
                                                            <span className="tooltiptext tooltip-top"></span>
                                                        </div>
                                                        <a href="product.html" className="rating-reviews">( 6 reviews )</a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-offset-3 ml-auto mr-auto">
                                        <button onClick={() => loadMore()}
                                                className="btn btn-lg btn-block btn-outline ml-auto mr-auto mt-lg-5 text-center text-white" style={{
                                            backgroundColor: "#99ee99"
                                        }}>Load
                                            More
                                        </button>
                                    </div>
                                </div>
                                {/* <nav className="toolbox toolbox-pagination">
                                    <p className="show-info">Showing <span>{props.state.total_showing} of {props.state.count}</span> Products</p>
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link page-link-prev" href="#" aria-label="Previous" tabindex="-1"
                                                aria-disabled="true">
                                                <i className="d-icon-arrow-left"></i>Prev
										</a>
                                        </li>
                                        <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item page-item-dots"><a className="page-link" href="#">6</a></li>
                                        <li className="page-item">
                                            <a className="page-link page-link-next" href="#" aria-label="Next">
                                                Next<i className="d-icon-arrow-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                            :<AppLoader />}
                        </div>
                    </div>
                </div>

              

            </main>
        </div>
    )
}

export default BrandProductComponent