import React, {Component} from 'react'
import {Link,withRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {LOAD_USER_URL, LOGIN_URL, REGISTER_URL,BASE_URL,DISTRICT_URL,NO_TOEKN_SELLER_URL,NO_TOKEN_AGENT_URL, NO_TOKEN_SELLERS} from './constants/api';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import axios from "axios";
import history from './utils/history'

const fbLogin =  (accesstoken) => {
  let res =  axios.post(
    BASE_URL+ "/api/auth/dj-rest-auth/facebook/",
    {
      'access_token': accesstoken,
    }
  );
  console.log(res);
  return res.status;
};


const googleLogin = (accesstoken) => {
  let res = axios.post(
    BASE_URL+ "/api/auth/dj-rest-auth/google/",
    {
      'access_token': accesstoken,
    }
  );
  console.log(res);
  return res.status;
};


class Login extends Component {
    state = {
        first_name: "",
        email: "",
        phone_no: "",
        password: "",
        login: false,
        new_password: '',
        conf_password: '',
        agree: false,
        agents:[],
        agent_id:'',
        shop_name:''
    };

    onSubmit = e => {
        e.preventDefault();
        this.login(this.state.phone_no, this.state.password);
    };

    responseFacebook = (response) => {
        let fbResponse  = fbLogin(response._token !==undefined?response._token.accessToken:'')
        console.log(fbResponse);
        console.log(response);
    }

    responseGoogle = (response) => {
        let googleResponse  = googleLogin(response._token !==undefined?response._token.accessToken:'')
  //   setTimeout(()=>{
  //   window.location.href="/"
  // },3000)
    console.log(googleResponse);
        console.log(response);
    }
    fetchData = async () => {
        const result = await axios(
            NO_TOKEN_AGENT_URL
        );
        this.setState({agents: result.data.results?result.data.results:result.data});
    };
    componentDidMount() {
        // alert(JSON.stringify(this.fetchData().then(()=>{})))
        this.loadUser()
        this.fetchData().then(()=>{})
    }

    loadUser = () => {
        const token = localStorage.getItem('token');

        let headers = {
            "Content-Type": "application/json",
        };

        if (token) {
            headers["Authorization"] = `Token ${token}`;
            fetch(LOAD_USER_URL, {"method": "GET", headers,})
                .then(res => res.json())
                .then(res => {
                    if (res.id !== undefined) {
                        this.setState({login: true})
                    } else {
                        throw res;
                    }
                })
        }
    };

    login = (phone_no, password) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({phone_no, password})
        };

        fetch(LOGIN_URL, requestOptions)
            .then(res => res.json())
            .then(user => {
                if (user.success) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                    this.setState({login: false})
                    this.props.history.push('/')
                    toast.success('Login Successful')
                } else {
                    toast.warning('Incorrect Username or Password !');
                }
            });
    }
    onTextChange = (e) => {
       
        this.setState({
            [e.target.name]: e.target.value
        })
        // if(e.target.name==='email'){
        //     this.validateEmail(e.target.value);
        //    }
           
        // if(e.target.name==='ship_email'){
        //     this.validateShipEmail(e.target.value);
        // }

       
    }

    onRegistration = () => {
        if (this.state.new_password === this.state.conf_password) {
            let data = {
                first_name: this.state.first_name,
                last_name:this.state.last_name,
                email: this.state.email,
                phone_no: this.state.phone_no,
                password: this.state.new_password,
                agent_id:this.state.agent_id,
                shop_name:this.state.shop_name
            };
            if (this.state.agree) {
                fetch(NO_TOEKN_SELLER_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        if (data.id) {
                            localStorage.setItem('currentUser', JSON.stringify(data.user));
                            // localStorage.setItem('token', data.token);
                            this.props.history.push('/')
                            toast.success('Registration has been done successfully')
                        } else if (data.error) {
                            toast.warning(data.error);
                        } else if (data.password) {
                            toast.warning(data.password[0])
                        } else {
                            toast.warning("Something Wrong.Please ty again !!")
                        }
                    })
                    .catch((error) => {
                        toast.warning('Error:', error);
                    });
            } else {
                toast.warning('Please Select Terms and Condition ');
            }
        } else {
            toast.warning('Password Did Not Match !!!');
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.onRegistration()
    }

    render() {
        const {agents} = this.state
        return (
            <>
                <ToastContainer/>
                <div className={"container"}
                     style={{display: "flex", justifyContent: "center", position: "relative", alignItems: "center"}}>
                    <div className="login-popup" style={{width: "100%"}}>
                            <div className="form-box">
                                <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                                    <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5"
                                        role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active border-no lh-1 ls-normal"
                                               href="#signin">Login</a>
                                        </li>
                                        <li className="delimiter">or</li>
                                        <li className="nav-item">
                                            <a className="nav-link border-no lh-1 ls-normal"
                                               href="#register">Register</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="signin">
                                            <form action="#" onSubmit={this.onSubmit}>
                                                <div className="form-group mb-3">
                                                    <input type="text"
                                                           onChange={e => this.setState({phone_no: e.target.value})}
                                                           className="form-control" id="singin-email"
                                                           name="singin-email"
                                                           placeholder="Phone *" required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password"
                                                           onChange={e => this.setState({password: e.target.value})}
                                                           className="form-control" id="singin-password"
                                                           name="singin-password" placeholder="Password *" required/>
                                                </div>
                                                <div className="form-footer">
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" className="custom-checkbox"
                                                               id="signin-remember" name="signin-remember"/>
                                                        <label className="form-control-label" htmlFor="signin-remember">Remember
                                                            me</label>
                                                    </div>
                                                    <Link to="/forgot-password" className="lost-link">Lost your
                                                        password?</Link>
                                                </div>
                                                <button className="btn btn-dark btn-block btn-rounded"
                                                        type="submit">Login
                                                </button>
                                            </form>
                                            {/* <div className="form-choice text-center">
                                                <label className="ls-m">or Login With</label>
                                                <div className="social-links">
                                                    
                                                    <GoogleLogin
                                                   
                                                        clientId="x1078683522502-59gr66cpmi992acemptg6kmp9nkjsuqd.apps.googleusercontent.com"
                                                        onLoginSuccess={this.responseGoogle}
                                                        onLoginFailure={this.responseGoogle}
                                                        
                                                    />
                                                    <FacebookLogin
                                                        
                                                        appId="950008912265657x"
                                                        onLoginSuccess={this.responseFacebook}
                                                        onLoginFailure={this.responseFacebook}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="tab-pane" id="register">
                                            <form action="#" onSubmit={this.handleSubmit}>
                                                <div className="form-group mb-3">
                                                    <input type="text" className="form-control"
                                                           onChange={e => this.setState({first_name: e.target.value})}
                                                           name="first_name" placeholder="First Name *" required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <input type="text" className="form-control"
                                                           onChange={e => this.setState({last_name: e.target.value})}
                                                           name="last_name" placeholder="Last Name *"/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="email"
                                                           onChange={e => this.setState({email: e.target.value})}
                                                           className="form-control" id="register-email" name="email"
                                                           placeholder="Your Email address "/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="number"
                                                           onChange={e => this.setState({phone_no: e.target.value})}
                                                           className="form-control" placeholder="Phone no *"
                                                           id="phone_no"
                                                           name="phone_no" required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password"
                                                           onChange={e => this.setState({new_password: e.target.value})}
                                                           className="form-control" id="register-password"
                                                           name="password"
                                                           placeholder="Password *"
                                                           required/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password"
                                                           onChange={e => this.setState({conf_password: e.target.value})}
                                                           className="form-control" id="register-password"
                                                           name="password"
                                                           placeholder=" Confirm Password *"
                                                           required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <input type="text" className="form-control"
                                                           onChange={e => this.setState({shop_name: e.target.value})}
                                                           name="shop_name" placeholder="Shop Name *" required/>
                                                </div>
                                                <div className="row">
                                                {/* <div className="col-xs-6"> */}
                                                <div className="form-group mb-3">

                                                    {/* <label>District *</label> */}
                                                    <select name='agent_id' onChange={this.onTextChange} className="form-control"
                                                            required>
                                                        <option value="">Select Your Agent</option>
                                                        {agents.map(item => (
                                                            <option key={item.id} selected={item.id === this.state.agent_id}
                                                                    value={item.id}>{item.agent_id}</option>
                                                        ))}
                                                    </select>
                                                    {/* <div className="text-danger">{this.state.errors.dist}</div> */}
                                                </div>
                                            {/* </div> */}
                                            </div>
                                                {/* <div className="form-group mb-3">
                                                    <input type="text" className="form-control"
                                                           onChange={e => this.setState({last_name: e.target.value})}
                                                           name="last_name" placeholder="Last Name *" required/>
                                                </div> */}

                                                <div className="form-footer">
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" className="custom-checkbox"
                                                               id="register-agree" name="register-agree"
                                                               onChange={e => this.setState({agree: e.target.value})}
                                                               required/>
                                                        <label className="form-control-label" htmlFor="register-agree">I
                                                            agree to the
                                                            privacy policy</label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-dark btn-block btn-rounded"
                                                        type="submit">Register
                                                </button>
                                            </form>
                                            {/* <div className="form-choice text-center">
                                            <label className="ls-m">or Register With</label>
                                            <div className="social-links">
                                                <a href="#" className="social-link social-google fab fa-google border-no"></a>
                                                <a href="#" className="social-link social-facebook fab fa-facebook-f border-no"></a>
                                                <a href="#" className="social-link social-twitter fab fa-twitter border-no"></a>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </>
        )
    }
}

export default withRouter(Login)
