import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {BASE_URL} from '../constants/api'
export const LatestData=[
    {
        "id":1,
        "name":"Flamboyant  Top",
        'tag':'',
        'currency':'BDT',
        'price':'3500',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":2,
        "name":"Flamboyant Red Top",
        'tag':'New',
        'currency':'BDT',
        'price':'3500',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":3,
        'tag':'',
        "name":"Flamboyant Pink Top",
        'currency':'BDT',
        'price':'4000',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":4,
        'tag':'',
        "name":"Flamboyant Yellow Top",
        'currency':'BDT',
        'price':'3500',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":5,
        'tag':'',
        "name":"Flamboyant Yellow Top",
        'currency':'BDT',
        'price':'3500',
        "image":"../assets/img/product/6.jpg"
    },
]

export const SliderData=[
    {
        "id":1,
        "name":"Silk Cloth",
        'currency':'BDT',
        'price':'290',
        'description':'orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus-pendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. ',
        "image":"../assets/img/product/Tasnia-Farin1.jpg"
    },
    {
        "id":2,
        "name":"Cotton T-shirt",
        'currency':'BDT',
        'price':'500',
        'description':'orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus-pendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. ',
        "image":"../assets/img/product/Salman-Khan.jpeg"
    },

]

export const TopSellingData=[
    {
        "id":1,
        "name":"Flamboyant Pink Top",
        'currency':'BDT',
        'price':'3500',
        'saleType':'',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":2,
        "name":"Black and White Stripes Dress",
        'currency':'BDT',
        'price':'500',
        'saleType':'On Sale',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":3,
        "name":"Flamboyant Pink Top",
        'currency':'BDT',
        'price':'3500',
        'saleType':'On Sale',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":4,
        "name":"Black and White Stripes Dress",
        'currency':'BDT',
        'price':'500',
        'saleType':'',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":5,
        "name":"Flamboyant Pink Top",
        'currency':'BDT',
        'price':'3500',
        'saleType':'On Sale',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":6,
        "name":"Black and White Stripes Dress",
        'currency':'BDT',
        'price':'500',
        'saleType':'',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":7,
        "name":"Flamboyant Pink Top",
        'currency':'BDT',
        'price':'3500',
        'saleType':'On Sale',
        "image":"../assets/img/product/6.jpg"
    },
    {
        "id":8,
        "name":"Black and White Stripes Dress",
        'currency':'BDT',
        'price':'500',
        'saleType':'',
        "image":"../assets/img/product/6.jpg"
    },

]

export var AppLoader = function() {
    return (
        <div className="text-center" style={{top:"50%"}} >
        <Loader
        type="Watch"
        color="#99EE99"
        height={100}
        width={100}
    
        />
        </div>
    );
  };


  export const  isValidURL=(url)=> {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if(regexp.test(url)){
        return url
    }else{
       return BASE_URL+url 
    } 
    //return (res !== null)
  };

  export const  Name = (name) =>{
    return <div>My name is: {name}</div>;
  }
  



  export const OrderStatus = [
    {
        'id': 1,
        'status': 'Pending'
    },
    {
        'id': 2,
        'status': 'Confirmed'
    },
    // {
    //     'id': 3,
    //     'status': 'In Warehouse'
    // },
    // {
    //     'id': 4,
    //     'status': 'On the way'
    // },
    {
        'id': 5,
        'status': 'Completed'
    },
    {
        'id': 6,
        'status': 'On Hold'
    },
    {
        'id': 7,
        'status': 'Refunded'
    },
    {
        'id': 8,
        'status': 'Returned'
    },
    {
        'id': 9,
        'status': 'Canceled'
    },
    {
        'id':10,
        'status':'Processing'
    }
]