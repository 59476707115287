import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addQuantity, subtractQuantity, removeItem, emptyCart, bundleAddQuantity, bundleRemoveItem, bundleSubtractQuantity } from '../../actions/cartActions'
import CategoryContainer from '../../containers/category/CategoryContainer';
import { BASE_URL, PRODUCT_URL,LOGIN_URL, NO_TOKEN_CHILD_CATEGORY_URL } from '../../constants/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Headers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            searchInput: '',
            found: 0,
            category: [],
            phone_no:'',
            password:''

        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    onPhoneLogin = () => {
        let data = {
            phone_no: this.state.phone_no,
            password: this.state.password

            // error: this.state.error

        };

        let url = LOGIN_URL
        fetch(url, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                // alert(JSON.stringify(data))

                if (data.phone_no.error) {

                    toast.warning('Already Login');
                } else {

                    toast.success("Login successfully");
                }
            })
            .catch((error) => {
                // alert(JSON.stringify(error.email))
                toast.warning('Error:', error);
            });
    }

    handleSubmit = (event) => {
        // alert(JSON.stringify(this.state))
        event.preventDefault();
        this.onPhoneLogin()
    }

    onchangeSearchText = (event) => {
        this.setState({
            searchInput: event.target.value
        })
        this.props.onchangeSearchText(this.state.searchInput)
    }
    componentDidMount() {
        this.getCategory()

        const { state } = this.props
        if (state !== undefined && state !== '' && state !== null) {
            this.setState({ searchInput: state.product.product_name })
        }

    }
    getCategory = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(NO_TOKEN_CHILD_CATEGORY_URL, requestOptions)
            .then(res => res.json())
            .then(category => {
                if (category.results.length > 0) {

                    var results = category.results
                    var praimarcategories = []
                    for (var i = 0; i < results.length; i++) {

                        var primary_category_name = results[i]["secondary-category"]["primary-category"]["name"]
                        var primary_category_id = results[i]["secondary-category"]["primary-category"]["id"]
                        var secondary_category_name = results[i]["secondary-category"]["name"]
                        var secondary_category_id = results[i]["secondary-category"]["id"]
                        var child_category_name = results[i]["name"]
                        var child_category_id = results[i]["id"]

                        // alert(primary_category_id)
                        var check = praimarcategories.findIndex(x => x.primary_category_id === primary_category_id);
                        if (check === -1) {

                            var secondary_arr = []
                            var chiild_arr = []

                            var child_obj = {
                                'child_category_name': child_category_name,
                                'child_category_id': child_category_id,
                            }
                            chiild_arr.push(child_obj)
                            var secondary_obj = {

                                'secondary_category_name': secondary_category_name,
                                'secondary_category_id': secondary_category_id,
                                'child_category': chiild_arr
                            }
                            secondary_arr.push(secondary_obj)

                            var primary_obj = {
                                'primary_category_name': primary_category_name,
                                'primary_category_id': primary_category_id,
                                'secondary_category': secondary_arr,
                            }

                            praimarcategories.push(primary_obj)

                        } else {

                            var secondary_obj = {
                                'secondary_category_name': secondary_category_name,
                                'secondary_category_id': secondary_category_id,
                            }

                            var primary = praimarcategories.find(x => x.primary_category_id === primary_category_id)
                            var secondary = primary.secondary_category;
                            var seccheck = secondary.findIndex(x => x.secondary_category_id === secondary_category_id);
                            if (seccheck === -1) {
                                var child = []
                                var child_obj = {
                                    'child_category_name': child_category_name,
                                    'child_category_id': child_category_id,
                                }
                                child.push(child_obj)
                                var secondary_obj = {

                                    'secondary_category_name': secondary_category_name,
                                    'secondary_category_id': secondary_category_id,
                                    'child_category': child
                                }

                                secondary.push(secondary_obj)
                                praimarcategories[check].secondary_category = secondary

                            } else {
                                var child = secondary.find(x => x.child_category).child_category;

                                var child_obj = {
                                    'child_category_name': child_category_name,
                                    'child_category_id': child_category_id,
                                }
                                child.push(child_obj)
                                praimarcategories[check].secondary_category.child_category = child
                            }

                        }

                        //  console.log(check);
                    }

                    console.log(praimarcategories)
                    this.setState({ category: praimarcategories })

                }


                // }else{
                //     alert("Incorrect Username or Password !")
                // }

                // JSON(user)
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('currentUser', JSON.stringify(user));
                // currentUserSubject.next(user);

                // return user;
            });
    }

    render() {
        const { pageTitle, totalQuantity, items, total, bundleItems } = this.props;
        const { searchInput, category } = this.state;
        let addedItems = totalQuantity > 0 ?
            (
                <>

                    <i className="minicart-icon">
                        <span className="cart-count">{totalQuantity}</span>
                    </i>
                </>) :

            (null)


        return (

            <div>
                 <ToastContainer />
                <header className="header">
                    <div className="header-top">
                        <div className="container">
                            <div className="header-left">
                                <p className="welcome-msg">Welcome to shopper message or remove it!</p>
                            </div>
                            <div className="header-right">
                                {/* <!-- End DropDown Menu --> */}
                                <div className="dropdown dropdown-expanded d-lg-show">
                                    <a href="#dropdown">Links</a>
                                    <ul className="dropdown-box">
                                        <li><a href="about-us.html">About</a></li>
                                        <li><a href="/blog">Blog</a></li>
                                        <li><a href="#">FAQ</a></li>
                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </div>
                                {/* <!-- End DropDownExpanded Menu --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- End HeaderTop --> */}
                    <div className="header-middle sticky-header fix-top sticky-content">
                        <div className="container" style={{ paddingTop: "12px", paddingBottom: "12px" }}>
                            <div className="header-left">
                                <a href="#" className="mobile-menu-toggle">
                                    <i className="d-icon-bars2"></i>
                                </a>
                                <a href="/" className="logo d-lg-block d-none mr-4">
                                    <img src="static/images/demos/demo11/logo.png" alt="logo" width="163" height="39" />
                                </a>
                            </div>
                            <div className="header-center">
                                <a href="/" className="logo d-lg-none d-block">
                                    <img src="static/images/demos/demo11/logo.png" alt="logo" width="163" height="39" />
                                </a>
                                {/* <!-- End Logo --> */}
                                <div className="header-search hs-expanded">
                                    <form action="#" method="get" className="input-wrapper">
                                        <div className="select-box">
                                            <select id="category" name="category">
                                                <option value="">All Categories</option>
                                                {category.map((cate, index) => (

                                                    <option value="4">{cate.primary_category_name}</option>
                                                ))}
                                                {/* <option value="12">- Women</option>
                                                <option value="13">- Men</option>
                                                <option value="66">- Jewellery</option>
                                                <option value="67">- Kids Fashion</option>
                                                <option value="5">Electronics</option>
                                                <option value="21">- Smart TVs</option>
                                                <option value="22">- Cameras</option>
                                                <option value="63">- Games</option>
                                                <option value="7">Home &amp; Garden</option>
                                                <option value="11">Motors</option>
                                                <option value="31">- Cars and Trucks</option>
                                                <option value="32">- Motorcycles &amp; Powersports</option>
                                                <option value="33">- Parts &amp; Accessories</option>
                                                <option value="34">- Boats</option>
                                                <option value="57">- Auto Tools &amp; Supplies</option> */}
                                            </select>
                                        </div>
                                        <input type="text" className="form-control" value={searchInput} onChange={this.onchangeSearchText} name="search" id="search"
                                            placeholder="Search your keyword..." required="" />
                                        <button className="btn btn-sm btn-search" type="submit"><i
                                            className="d-icon-search"></i></button>
                                    </form>
                                </div>
                                {/* <!-- End Header Search --> */}
                            </div>
                            <div className="header-right">


                                

                            <div className="dropdown cart-dropdown">
                                <a className="cart-toggle login" href="/login">
                                    <i className="d-icon-user"></i>
                                    <span>Login</span>
                                </a>
                                {/* <!-- End Cart Toggle --> */}
                                <div className="dropdown-box">
                                    <div className="product product-cart-header">
                                        <span className="product-cart-counts">Login</span>
                                    </div>
                                    <div className="products scrollable">
                                        <div className="row">
                                        <form onSubmit={this.handleSubmit} action="#" method="get"
                                                className="input-wrapper input-wrapper-round input-wrapper-inline ml-lg-auto appear-animate"
                                                data-animation-options="{'name': 'fadeInRightShorter'}">
                                                
                                            <div className="col-md-12 pt-2">
                                                <label className="font-weight-normal" style={{ fontSize: "16px" }}>Phone Number</label>
                                                <input type="text" value={this.state.phone_no} onChange={this.handleChange} id="phone_no" className="form-control mt-1" name="phone_no" placeholder="017******" />
                                            </div>
                                            <div className="col-md-12 pt-2">
                                                <label className="font-weight-normal" style={{ fontSize: "16px" }}>Password</label>
                                                <input type="password" value={this.state.password} onChange={this.handleChange} id="password" className="form-control mt-1" placeholder="******" />
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <button type="submit" className="btn btn-dark w-100 mt-1">Login</button>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <p className="d-flex justify-content-center font-weight-normal">Don't have an account? <Link className="mt-0 pt-0 color-text-red" to="/login">Sign up</Link></p>
                                                <p className="d-flex justify-content-center font-weight-normal" style={{ marginTop: "-15px" }}><a className="mt-0 pt-0 color-text-red" href="">Forgot Password</a></p>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Dropdown Box --> */}
                            </div>
                            {/* <!-- End Login --> */}












                                
                                <span className="divider"></span>
                                <a className="topwishlist" href="/wishlist">
                                    <i className="d-icon-heart-full"></i>
                                    <span>Wishlist</span>
                                </a>
                                <span className="divider"></span>
                                <div className="dropdown cart-dropdown">
                                    <Link to="/cart" className="cart-toggle">
                                        <span className="cart-label">
                                            <span className="cart-name">My Cart</span>
                                            <span className="cart-price">{total}</span>
                                        </span>
                                        {addedItems}
                                    </Link>
                                    {/* <!-- End Cart Toggle --> */}
                                    <div className="dropdown-box">
                                        <div className="product product-cart-header">
                                            <span className="product-cart-counts">{totalQuantity} items</span>
                                            <span><a href="/cart">View cart</a></span>
                                        </div>
                                        <div className="products scrollable">


                                            {/* <!-- End of Cart Product --> */}
                                            {items.map((item, index) => (
                                                <div className="product product-cart" key={index}>
                                                    <div className="product-detail">
                                                        <a href="product.html" className="product-name">{item.product_name}</a>
                                                        <div className="price-box">
                                                            <span className="product-quantity">{item.quantity}</span>
                                                            <span className="product-price">{item.quantity * item.regular_price}</span>
                                                        </div>
                                                    </div>
                                                    <figure className="product-media">
                                                        <a href="#">
                                                            <img src={BASE_URL + item.feature_image} alt="product" width="90"
                                                                height="90" />
                                                        </a>
                                                        <button className="btn btn-link btn-close" onClick={() => this.props.removeItem(item)}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </figure>
                                                </div>
                                            ))
                                            }
                                            <>
                                                {
                                                    bundleItems.length > 0 ? <h6>Bundle Product</h6> : null
                                                }
                                            </>
                                            {bundleItems.map((item, index) => (
                                                <div className="product product-cart" key={index}>
                                                    <div className="product-detail">
                                                        <a href="product.html" className="product-name">{item.product_name}</a>
                                                        <div className="price-box">
                                                            <span className="product-quantity">{item.quantity}</span>
                                                            <span className="product-price">{item.quantity * item.total_price}</span>
                                                        </div>
                                                    </div>
                                                    <figure className="product-media">
                                                        <a href="#">
                                                            <img src={BASE_URL + item.feature_image} alt="product" width="90"
                                                                height="90" />
                                                        </a>
                                                        <button className="btn btn-link btn-close" onClick={() => this.props.bundleRemoveItem(item)}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </figure>
                                                </div>
                                            ))
                                            }
                                            {/* <!-- End of Cart Product --> */}
                                        </div>
                                        {/* <!-- End of Products  --> */}
                                        <div className="cart-total">
                                            <label>Subtotal:</label>
                                            <span className="price">BDT {total}</span>
                                        </div>
                                        {/* <!-- End of Cart Total --> */}
                                        <div className="cart-action">
                                            <a href="/checkout" className="btn btn-dark"><span>Checkout</span></a>
                                        </div>
                                        {/* <!-- End of Cart Action --> */}
                                    </div>
                                    {/* <!-- End Dropdown Box --> */}
                                </div>

                                <div className="header-search hs-toggle mobile-search">
                                    <a href="#" className="search-toggle">
                                        <i className="d-icon-search"></i>
                                    </a>
                                    <form action="#" className="input-wrapper">
                                        <input type="text" className="form-control" name="search" autoComplete="off"
                                            placeholder="Search your keyword..." required />
                                        <button className="btn btn-search" type="submit">
                                            <i className="d-icon-search"></i>
                                        </button>
                                    </form>
                                </div>
                                {/* <!-- End of Header Search --> */}
                            </div>
                        </div>

                    </div>

                    <div className="header-bottom sticky-header fix-top sticky-content has-dropdown">
                        <div className="container">
                            <div className="inner-wrap">
                                <div className="header-left">

                                    <nav className="main-nav">
                                        <ul className="menu">
                                            <li className="active">
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <Link to="/all-shop">All Shops</Link>
                                            </li>
                                            <li>
                                                <Link to="/all-brand">All Brands</Link>
                                            </li>
                                            <li>
                                                <Link href="/campaign-page">Campaigns</Link>
                                            </li>
                                            <li>
                                                <Link to="/on-sale">On Sale</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="header-right">
                                    <ul className="menu">
                                        <li>
                                            <a href="#">Seller Zone</a>
                                        </li>
                                        <li>
                                            <a href="#">Support</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* <!-- End Header -->< */}
                <div className="mobile-menu-wrapper">
                    <div className="mobile-menu-overlay">
                    </div>
                    {/* <!-- End Overlay --> */}
                    <a className="mobile-menu-close" href="javascript:void(0)"><i className="d-icon-times"></i></a>
                    {/* <!-- End CloseButton --> */}
                    <div className="mobile-menu-container scrollable">
                        <form action="#" className="input-wrapper mb-6">
                            <input type="text" className="form-control" name="search" autoComplete="off"
                                placeholder="Search your keyword..." required />
                            <button className="btn btn-search" type="submit">
                                <i className="d-icon-search"></i>
                            </button>
                        </form>
                        {/* <!-- End Search Form --> */}
                        <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#menu">Menu</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Categories</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane active" id="menu">
                                    <ul className="mobile-menu mmenu-anim">
                                        <li className="active">
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="/category">Categories</a>
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        Variations 1
                                        </a>
                                                    <ul>
                                                        <li><a href="shop-banner-sidebar.html">Banner With Sidebar</a></li>
                                                        <li><a href="shop-boxed-banner.html">Boxed Banner</a></li>
                                                        <li><a href="shop-infinite-scroll.html">Infinite Ajaxscroll</a></li>
                                                        <li><a href="shop-horizontal-filter.html">Horizontal Filter</a></li>
                                                        <li><a href="shop-navigation-filter.html">Navigation Filter<span
                                                            className="tip tip-hot">Hot</span></a></li>

                                                        <li><a href="shop-off-canvas.html">Off-Canvas Filter</a></li>
                                                        <li><a href="shop-right-sidebar.html">Right Toggle Sidebar</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Variations 2
                                        </a>
                                                    <ul>

                                                        <li><a href="shop-grid-3cols.html">3 Columns Mode<span
                                                            className="tip tip-new">New</span></a></li>
                                                        <li><a href="shop-grid-4cols.html">4 Columns Mode</a></li>
                                                        <li><a href="shop-grid-5cols.html">5 Columns Mode</a></li>
                                                        <li><a href="shop-grid-6cols.html">6 Columns Mode</a></li>
                                                        <li><a href="shop-grid-7cols.html">7 Columns Mode</a></li>
                                                        <li><a href="shop-grid-8cols.html">8 Columns Mode</a></li>
                                                        <li><a href="shop-list.html">List Mode</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="demo11-product.html">Products</a>
                                            <ul>
                                                <li>
                                                    <a href="#">Product Pages</a>
                                                    <ul>
                                                        <li><a href="product-simple.html">Simple Product</a></li>
                                                        <li><a href="product.html">Variable Product</a></li>
                                                        <li><a href="product-sale.html">Sale Product</a></li>
                                                        <li><a href="product-featured.html">Featured &amp; On Sale</a></li>

                                                        <li><a href="product-left-sidebar.html">With Left Sidebar</a></li>
                                                        <li><a href="product-right-sidebar.html">With Right Sidebar</a></li>
                                                        <li><a href="product-sticky-cart.html">Add Cart Sticky<span
                                                            className="tip tip-hot">Hot</span></a></li>
                                                        <li><a href="product-tabinside.html">Tab Inside</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">Product Layouts</a>
                                                    <ul>
                                                        <li><a href="product-grid.html">Grid Images<span
                                                            className="tip tip-new">New</span></a></li>
                                                        <li><a href="product-masonry.html">Masonry</a></li>
                                                        <li><a href="product-gallery.html">Gallery Type</a></li>
                                                        <li><a href="product-full.html">Full Width Layout</a></li>
                                                        <li><a href="product-sticky.html">Sticky Info</a></li>
                                                        <li><a href="product-sticky-both.html">Left &amp; Right Sticky</a></li>
                                                        <li><a href="product-horizontal.html">Horizontal Thumb</a></li>

                                                        <li><a href="#">Build Your Own</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">Pages</a>
                                            <ul>
                                                <li><a href="about-us.html">About</a></li>
                                                <li><a href="contact-us.html">Contact Us</a></li>
                                                <li><a href="/login">Login</a></li>
                                                <li><a href="#">FAQs</a></li>
                                                <li><a href="error-404.html">Error 404</a></li>
                                                <li><a href="/coming-soon">Coming Soon</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">Blog</a>
                                            <ul>
                                                <li><a href="blog-classNameic.html">classNameic</a></li>
                                                <li><a href="blog-listing.html">Listing</a></li>
                                                <li>
                                                    <a href="#">Grid</a>
                                                    <ul>
                                                        <li><a href="blog-grid-2col.html">Grid 2 columns</a></li>
                                                        <li><a href="blog-grid-3col.html">Grid 3 columns</a></li>
                                                        <li><a href="blog-grid-4col.html">Grid 4 columns</a></li>
                                                        <li><a href="blog-grid-sidebar.html">Grid sidebar</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">Masonry</a>
                                                    <ul>
                                                        <li><a href="blog-masonry-2col.html">Masonry 2 columns</a></li>
                                                        <li><a href="blog-masonry-3col.html">Masonry 3 columns</a></li>
                                                        <li><a href="blog-masonry-4col.html">Masonry 4 columns</a></li>
                                                        <li><a href="blog-masonry-sidebar.html">Masonry sidebar</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">Mask</a>
                                                    <ul>
                                                        <li><a href="blog-mask-grid.html">Blog mask grid</a></li>
                                                        <li><a href="blog-mask-masonry.html">Blog mask masonry</a></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="post-single.html">Single Post</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">ELements</a>
                                            <ul>
                                                <li><a href="element-products.html">Products</a></li>
                                                <li><a href="element-typography.html">Typography</a></li>
                                                <li><a href="element-titles.html">Titles</a></li>
                                                <li><a href="element-categories.html">Product Category</a></li>
                                                <li><a href="element-buttons.html">Buttons</a></li>
                                                <li><a href="element-accordions.html">Accordions</a></li>
                                                <li><a href="element-alerts.html">Alert &amp; Notification</a></li>
                                                <li><a href="element-tabs.html">Tabs</a></li>
                                                <li><a href="element-testimonials.html">Testimonials</a></li>
                                                <li><a href="element-blog-posts.html">Blog Posts</a></li>
                                                <li><a href="element-instagrams.html">Instagrams</a></li>
                                                <li><a href="element-cta.html">Call to Action</a></li>
                                                <li><a href="element-icon-boxes.html">Icon Boxes</a></li>
                                                <li><a href="element-icons.html">Icons</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Buy Donald!</a></li>
                                    </ul>
                                </div>
                                <div className="tab-pane" id="categories">
                                    <ul className="mobile-menu mmenu-anim">
                                        <li><a href="demo11-shop.html" className="menu-title">Browse Our Categories</a></li>
                                        <li>
                                            <a href="demo11-shop.html"><i className="d-icon-camera1"></i>Electronics</a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html"><i className="d-icon-officebag"></i>Backpacks &amp;
                                    Fashion bags</a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html"><i className="d-icon-gamepad1"></i>Gaming &amp;
                                    Accessories
                                </a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html">
                                                <i className="d-icon-t-shirt1"></i>Travel &amp; Clothing</a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html"><i className="d-icon-desktop"></i>Computer
                                </a>
                                        </li>
                                        <li>
                                            <a href="/"><i className="d-icon-cook"></i>Home &amp; Kitchen</a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html">
                                                <i className="d-icon-card"></i>Backpacks &amp; Fashion bags
                                </a>
                                        </li>
                                        <li>
                                            <a href="demo11-shop.html">
                                                <i className="d-icon-card"></i>Daily Deals
                                </a>
                                        </li>
                                        <li>
                                            <a href="demo14-shop.html">
                                                <i className="d-icon-basketball1"></i>Sporting Goods
                                </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End MobileMenu --> */}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.cart.addedItems,
        bundleItems: state.cart.bundleItems,
        totalQuantity: state.cart.totalQuantity,
        total: state.cart.total
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuantity: (data) => { dispatch(addQuantity(data)) },
        removeItem: (data) => { dispatch(removeItem(data)) },
        subtractQuantity: (data) => { dispatch(subtractQuantity(data)) },
        bundleRemoveItem: (data) => { dispatch(bundleRemoveItem(data)) },
        // removeItem: (data)=>{dispatch(removeItem(data))},
        // subtractQuantity: (data)=>{dispatch(subtractQuantity(data))},
        emptyCart: (data) => { dispatch(emptyCart()) },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Headers)


