import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import {isValidURL} from '../../constants/HomeData'

class WishListComponent extends Component {
    render() {
        const {wishlist} = this.props
        return (
            <div>
                <main className="main container">
                    <div className="page-header bg-dark"
                         style={{
                             backgroundImage: `url('static/images/shop/page-header-back.jpg')`,
                         }}>
                        <h1 className="page-title">Wishlist</h1>
                        <ul className="breadcrumb">
                            <li><Link to={"/"}><i className="d-icon-home"/></Link></li>
                            <li>Wishlist</li>
                        </ul>
                    </div>
                    <div className="page-content pt-10 pb-10">
                        <div className="container">
                            {
                                wishlist.length > 0
                                    ? <table className="table shop-table wishlist-table mt-2 mb-4">
                                        <thead>
                                        <tr>
                                            <th className="product-name" style={{width: "20%"}}><span>Product</span></th>
                                            <th className="product-name" style={{width: "40%"}}><span>Product Name</span>
                                            </th>
                                            <th className="product-price" style={{width: "10%"}}><span>Price</span></th>
                                            <th className="product-stock-status" style={{width: "10%"}}>
                                                <span>Stock status</span></th>
                                            <th className="product-add-to-cart" style={{width: "10%"}}/>
                                            <th className="product-remove" style={{width: "10%"}}/>
                                        </tr>
                                        </thead>
                                        <tbody className="wishlist-items-wrapper">
                                        {
                                            wishlist.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="product-thumbnail" style={{textAlign: "center"}}>
                                                        <Link to={'product-details?id=' + item?.id}>
                                                            <img src={isValidURL(item?.feature_image)} width="100"
                                                                 height="100" alt="product"/>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <a href={'product-details?id=' + item?.id}>{item?.product_name}</a>
                                                    </td>
                                                    <td className="product-name"
                                                        style={{color: '#ed711b', fontWeight: '800', textAlign: "center"}}>
                                                        <span className="amount">৳ {Math.round(item?.new_price)}</span>
                                                    </td>
                                                    <td className="product-stock-status" style={{textAlign: "center"}}>
                                                        <span
                                                            className="wishlist-in-stock">{item?.stock_quantity > 0 ? 'In Stock' : 'Out of Stock'} </span>
                                                    </td>
                                                    <td className="product-add-to-cart" style={{textAlign: "center"}}>
                                                        <Link onClick={() => this.props.addToCartFromWishList(item)}
                                                              className={item.stock_quantity > 0 ? 'btn-product btn-cart' : 'btn-product btn-cart btn-disabled'}><span>Add to Cart</span></Link>
                                                    </td>
                                                    <td className="product-remove" style={{textAlign: "center"}}>
                                                        <Link onClick={() => this.props.removeItemToWishList(item)}
                                                              className="remove" title="Remove this product">
                                                            <i className="fas fa-trash-alt"/>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    : <p className="text-center">
                                        <i className="d-icon-heart-full"/> No Product in Wishlist
                                    </p>
                            }
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default WishListComponent

