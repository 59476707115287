import {RatingValueToPerchantage} from "../../constants/customMethod";
import React from "react";
import {Modal} from "../../constants/Modal";
import {isValidURL} from "../../constants/HomeData"

export const ProductSection = ({index, product, addToCart, addToWishlist, showQuickModal,categoryPage = false, onSale= false, featured= false}) => {
    const [showModal, updateShowModal] = React.useState(false)
    const [modalData, setModalData] = React.useState("")

    const toggleModal = () => {
        updateShowModal(false);
    }

    return (
        <>
        
            <div className={`${categoryPage && !featured ? "col-lg-3" : featured ? "" : "col-lg-2"} ${featured ? "" : "col-md-3 mb-3"}`} key={index}>
                {/* row cols-xl-5 cols-lg-4 cols-md-2 cols-2 mb-5 */}
                <div className="product product-classNameic" key={index}>
                    <figure className="product-media">
                        <a href={"/product-details?id=" + product?.id}>
                            {product?.feature_image !== null &&
                            product?.feature_image !== "" &&
                            product?.feature_image !== undefined ? (
                                <img src={isValidURL(product?.feature_image)} alt="product"
                                     style={{maxWidth:'250px',maxHeight:'218px',width: "100%", height: "100%"}}/>
                            ) : (
                                <img src="/static/images/no-image.png" alt="product"
                                     style={{width: "100%", height: "100%",}}/>
                            )}
                        </a>
                    </figure>
                    <div className="product-details">
                        <h3 className="product-name">
                            <a href={"/product-details?id=" + product?.id}>
                                {product?.product_name}
                            </a>
                        </h3>
                        <h3 className="product-name">
                            <a href={"/product-details?id=" + product?.id}>
                                SKU: {product?.product_sku}
                            </a>
                        </h3>
                        <div className="product-price" >
                        {Object.keys(product).length > 0 ? (
                                                    <>
                                                        {
                                                            product?.discount_type === 1 || product?.discount_type === 2 ? (
                                                                    <ins className="new-price">৳ {product?.new_price}</ins>

                                                                ) :
                                                                <ins className="new-price">৳ {product?.new_price}</ins>

                                                        }
                                                        {
                                                            product?.discount_type === 1 || product?.discount_type === 2 ? (
                                                                <del
                                                                    className="old-price">৳ {product?.regular_price}</del>
                                                            ) : null

                                                        }
                                                    </>

                                                ) : null

                                                }
                                                {/* <h3 className="product-name"> */}
              
                                 { '/'+ product.product_units !==null && product.product_units !==undefined? product.product_units.name:""}

                        {/* </h3> */}
                        </div>
                        
{/*                         
                        {product?.is_price_range ===true && product?.price_range !==null ? 
                                            
                                            <>
                                                        {product.price_range.map((rang,i)=>
                                                            <span key={i}>
                                                            <span className="product-sku">({rang.minimum}</span>-<span className="product-brand">{rang.maximum})</span> {' '}{  product.product_units !==null && product.product_units !=undefined? product.product_units.name:""}<span> ৳{rang.price}</span><br/>
                                                            </span>
                                                    
                                                        )}
                                                </>       

                                                        :null} */}
                        
                            
                        
                        <div className="ratings-container">
                            <div className="ratings-full">
                            <span className="ratings"
                                  style={{width: RatingValueToPerchantage(product?.avg_rating) + "%",}}/>
                            </div>
                        </div>
                        <div className="product-action">
                            {
                                addToCart &&
                                <a href="javascript:void(0);" onClick={addToCart} title="Add to cart" data-target="#addCartModal"
                                   data-toggle="modal"
                                    className={product?.stock_quantity > 0 || product?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}
                               
                                   >
                                    { product?.stock_quantity > 0 || product?.is_pre_order === true ?
                                   
                                         <>
                                             {product.stock_quantity > 0 ?
                                                 "add to cart" :
                                                 "pre order"
                                             }
                                         </> :
                                         "out of stock"
                                    }
                                </a>
                            }
                            {
                                addToWishlist &&
                                <a href="#" onClick={addToWishlist} className="btn-product-icon btn-wishlist"
                                   title="Add to wishlist"><i className="d-icon-heart"/></a>
                            }
                            {
                                showQuickModal && <a style={{cursor: "pointer"}} onClick={() => {
                                    setModalData(product)
                                    updateShowModal(true)
                                }} className="btn-product-icon btn-quickview" title="Quick View">
                                    <i className="d-icon-search"/>
                                </a>
                            }
                        </div>
                    </div>

                </div>
            </div>
        
            <Modal
                canShow={showModal}
                updateModalState={toggleModal}
                data={modalData}
                style={customStyles}
                addItemToCart={addToCart}
                addItemToWishList={addToWishlist}
                showOldPrice={false}
            />
        </>

    );
}

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "red",
    },
    content: {
        position: "relative",
        top: "40px",
        left: "40px",
        right: "40px",
        bottom: "40px",
        border: "1px solid #ccc",
        background: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
    },
}









export const ProductSectionTwoForPopular = ({index, product, addToCart, addToWishlist, showQuickModal,categoryPage = false, onSale= false, featured= false}) => {
    const [showModal, updateShowModal] = React.useState(false)
    const [modalData, setModalData] = React.useState("")

    const toggleModal = () => {
        updateShowModal(false);
    }

    return (
        <>
        
            <div className={`${categoryPage && !featured ? "col-lg-3" : featured ? "" : "col-lg-2"} ${featured ? "" : "col-md-3 mb-3"}`} key={index}>
                {/* row cols-xl-5 cols-lg-4 cols-md-2 cols-2 mb-5 */}
                <div className="product product-classNameic">
                    <figure className="product-media">
                        <a href={"/product-details?id=" + product?.id}>
                            {product?.feature_image !== null &&
                            product?.feature_image !== "" &&
                            product?.feature_image !== undefined ? (
                                <img src={isValidURL(product?.feature_image)} alt="product"
                                     style={{maxWidth:'250px',maxHeight:'218px',width: "100%", height: "100%"}}/>
                            ) : (
                                <img src="/static/images/no-image.png" alt="product"
                                     style={{width: "100%", height: "100%",}}/>
                            )}
                        </a>
                    </figure>
                    <div className="product-details">
                        <h3 className="product-name">
                            <a href={"/product-details?id=" + product?.id}>
                                {product?.product_name}
                            </a>
                        </h3>
                        <h3 className="product-name">
              
                            {product.unit_amount !==null && product.unit_amount !==undefined && product.unit_amount>0?product.unit_amount:""  }{' '}{  product.product_units !==null && product.product_units !==undefined? product.product_units.name:""}
              
                        </h3>
                        {product?.is_price_range ===true && product?.price_range !==null ? 
                                            
                                <>
                                {product.price_range.map((rang,index)=>
                                    <span key={index}>
                                    <span className="product-sku" >({rang.minimum}</span>-<span className="product-brand">{rang.maximum})</span> {' '}{  product.product_units !==null && product.product_units !==undefined? product.product_units.name:""}<span> ৳{rang.price}</span><br/>
                                    </span>
                            
                                )}
                                </>       

                        :null}
                        <div className="product-price">
                        {Object.keys(product).length > 0 ? (
                                                    <>
                                                        {
                                                            product?.discount_type === 1 || product?.discount_type === 2 ? (
                                                                    <ins className="new-price">৳ {product?.new_price}</ins>

                                                                ) :
                                                                <ins className="new-price">৳ {product?.new_price}</ins>

                                                        }
                                                        {
                                                            product?.discount_type === 1 || product?.discount_type === 2 ? (
                                                                <del
                                                                    className="old-price">৳ {product?.regular_price}</del>
                                                            ) : null

                                                        }
                                                    </>

                                                ) : null

                                                }
                        </div>
                        <div className="ratings-container">
                            <div className="ratings-full">
                            <span className="ratings"
                                  style={{width: RatingValueToPerchantage(product?.avg_rating) + "%",}}/>
                            </div>
                        </div>
                        <div className="product-action">
                            {
                                addToCart &&
                                <a href="javascript:void(0);" onClick={addToCart} title="Add to cart" data-target="#addCartModal"
                                   data-toggle="modal"
                                    className={product?.stock_quantity > 0 || product?.is_pre_order === true ? "btn-product btn-cart" : "btn-product btn-cart btn-disabled"}
                               
                                   >
                                    { product?.stock_quantity > 0 || product?.is_pre_order === true ?
                                   
                                         <>
                                             {product.stock_quantity > 0 ?
                                                 "add to cart" :
                                                 "pre order"
                                             }
                                         </> :
                                         "out of stock"
                                    }
                                </a>
                            }
                            {
                                addToWishlist &&
                                <a href="#" onClick={addToWishlist} className="btn-product-icon btn-wishlist"
                                   title="Add to wishlist"><i className="d-icon-heart"/></a>
                            }
                            {
                                showQuickModal && <a style={{cursor: "pointer"}} onClick={() => {
                                    setModalData(product)
                                    updateShowModal(true)
                                }} className="btn-product-icon btn-quickview" title="Quick View">
                                    <i className="d-icon-search"/>
                                </a>
                            }
                        </div>
                    </div>

                </div>
            </div>
        
            <Modal
                canShow={showModal}
                updateModalState={toggleModal}
                data={modalData}
                style={customStyles}
                addItemToCart={addToCart}
                addItemToWishList={addToWishlist}
                showOldPrice={false}
            />
        </>

    );
}


